/**
 * Holds the tab components and displays the currently active one.
 * All tabs are rendered to the tree (but not displayed) even when not active as we need the geocoder tab ref to stay alive for its portal.
 */

import React, { FC, useMemo } from "react";
import { mdiInformationOutline, mdiLayersTriple, mdiSearchWeb } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";

import { setActiveTab } from "store/report/reportActions";

import LayersTab from "./Tabs/LayersTab/LayersTab";
import InfoTab from "./Tabs/InfoTab/InfoTab";
import GeocoderTab from "./Tabs/GeocoderTab/GeocoderTab";
import TabbedMenu from "../../../../../_Library/Menus/TabbedMenu";
import Icon from "@mdi/react";
import { Flex, Loader } from "@mantine/core";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { TabType } from "store/report/reportTypes";

interface TabContainerProps {
    setDragAndDropState: (active: boolean) => void;
    toggleDownloadModal: () => void;
    isLayersConfigLoading: boolean;
}

const MapMenu: FC<TabContainerProps> = ({
    setDragAndDropState,
    toggleDownloadModal,
    isLayersConfigLoading,
}: TabContainerProps) => {
    let activeTab = useSelector(
        (state) => getStoreAtNamespaceKey(state, "report").menuConfig.activeTab,
    );
    let menuHidden = useSelector(
        (state) =>
            getStoreAtNamespaceKey(state, "report").menuConfig.menuHidden,
    );

    const dispatch = useDispatch();

    const tabs = useMemo(
        () => ({
            Geocoder: {
                name: "Location Search",
                iconComponent: <Icon path={mdiSearchWeb} />,
                component: () => {
                    return <GeocoderTab />;
                },
            },
            Layer: {
                name: "Layers",
                iconComponent: <Icon path={mdiLayersTriple} />,
                component: () => {
                    return isLayersConfigLoading ? (
                        <Flex align="center" justify="center">
                            <Loader />
                        </Flex>
                    ) : (
                        <LayersTab
                            setDragAndDropState={setDragAndDropState}
                            toggleDownloadModal={toggleDownloadModal}
                        />
                    );
                },
            },
            Info: {
                name: "Layer Info",
                iconComponent: <Icon path={mdiInformationOutline} />,
                component: () => {
                    return <InfoTab />;
                },
            },
        }),
        [isLayersConfigLoading, setDragAndDropState, toggleDownloadModal],
    );

    return (
        <TabbedMenu
            domPositions={"MTB"}
            tabs={tabs}
            open={!menuHidden}
            activeTab={activeTab}
            setActiveTab={(tab: TabType) => dispatch(setActiveTab(tab))}
        />
    );
};

export default MapMenu;
