import React from "react";
import startCase from "lodash/startCase";
import { MRT_ColumnDef, MRT_Header, type MRT_Cell, MRT_Row } from "mantine-react-table";
import { formatValue } from "store/insights/utils";
import ReactTooltip from "react-tooltip";
import classes from "./MantineTable.module.css"

const allFilterModes = ["between", "betweenInclusive", "contains", "empty", "endsWith", "equals", "fuzzy", "greaterThan", "greaterThanOrEqualTo", "lessThan", "lessThanOrEqualTo", "notEquals", "startsWith"]
const layerAssessmentColumnNames =  ["Exposure Layer Assessment", "Claims Layer Assessment"]

const CustomFilterFunction = (row: MRT_Row, id: string, filterValue: string[] | number[]) => {
    //Filter array is [""] when empty - so if this is the case, show all results.
    if (filterValue[0] === "") {
        return true
    } else {
        return filterValue.includes(row.getValue(id))
    }
}

export const extractColumnNames = (
    data: Record<string, string|number>[],
    columns: string[],
    format: boolean = false
): MRT_ColumnDef[] => {
    if (columns.length !== 0) {
        return Array.from(columns)
            .map((key) => ({
                accessorKey: key,
                header: startCase(key),
                filterFn: layerAssessmentColumnNames.includes(key) ? 
                    (row: any, id: string, filterValue: string[]| number[]) => CustomFilterFunction(row, id, filterValue)
                    : "contains",
                columnFilterModeOptions: layerAssessmentColumnNames.includes(key) ? ["arrIncludesSome"] : allFilterModes,
                enableColumnFilter: !layerAssessmentColumnNames.includes(key),
                // @ts-ignore: MRT has no proper type for renderedCellValue
                Cell: ({ renderedCellValue }: { cell : MRT_Cell}) => {
                    return format ? formatValue(renderedCellValue, key) : renderedCellValue
                },
                accessorFn: (row: Record<string, string|number>) => (
                    row[key!] ?? ""
                ),
                Header: ({ column }: MRT_Header) => (
                    <span
                        className={classes.ColumnHeader}
                        data-tip={
                            layerAssessmentColumnNames.includes(column.columnDef.header) ? 
                            `You can filter by ${column.columnDef.header} using the interactive bar charts above!` : ""
                        }
                        data-for={column.id}
                        onMouseEnter = {() => {
                            if (layerAssessmentColumnNames.includes(column.columnDef.header)) {
                                const BarCharts = document.getElementById("barCharts")
                                BarCharts!.style.cssText = `
                                    border: 0.2rem solid var(--highlight-color); 
                                    border-radius: var(--border-radius-sm);
                                `
                            }
                        }}
                        onMouseLeave={() => {
                            if (layerAssessmentColumnNames.includes(column.columnDef.header)) {
                                const BarCharts = document.getElementById("barCharts")
                                BarCharts!.style.cssText = `
                                    border: 0.2rem solid transparent; 
                                    border-radius: var(--border-radius-sm);
                                `
                            }
                        }}
                    >
                        {column.columnDef.header}
                        <ReactTooltip 
                            id={column.id} 
                            place={"top"} 
                            effect={"solid"} 
                            className={classes.TextWrapTooltip} />
                    </span>
                )
            }))
            .filter(
                (column) =>
                    !["feature_id", "MIS_ContractID"].includes(
                        column.accessorKey,
                    ),
            );
    }
    return Object.keys(data[0]).map((key) => ({
        accessorKey: key,
        header: startCase(key),
        filterFn: "contains",
    }));
};
