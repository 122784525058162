import { ActionIcon, Button, CopyButton, Text, Tooltip } from "@mantine/core";
import React from "react";
import Icon from "@mdi/react";
import { mdiContentCopy } from "@mdi/js";
import { useDispatch } from "react-redux";
import { setAlert } from "store/system/systemActions";

type Props = {
    value: string;
    alertMessage: string;
    tooltipPosition?: "top" | "right" | "bottom" | "left";
    innerText?: string;
    buttonClassName?: string;
    onCopy?: () => void;
};

const CopyAlertButton = ({ value, alertMessage, tooltipPosition, innerText, buttonClassName, onCopy }: Props) => {
    const dispatch = useDispatch();
    return (
        <CopyButton value={value} timeout={10000}>
            {({ copy }) => (
                <div data-tip="Copy to clipboard">
                    <Tooltip
                        label={"Copy to clipboard"}
                        position={tooltipPosition}
                    >
                        <Button
                            size="md"
                            classNames={{ root: buttonClassName }}
                            variant="outline"
                            onClick={() => {
                                copy();
                                if (onCopy) onCopy();
                                dispatch(setAlert({message: alertMessage}));
                            }}
                        >
                            <ActionIcon size="md">
                                <Icon
                                    color="var(--text-color)"
                                    path={mdiContentCopy}
                                />
                            </ActionIcon>
                            {innerText && <Text fw={500} size="md" c="var(--text-color)" px="sm">{innerText}</Text>}
                        </Button>
                    </Tooltip>
                </div>
            )}
        </CopyButton>
    );
};

export default CopyAlertButton;
