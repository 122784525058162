type Metadata = {
    as_at_date?: string;
    exposure_revision?: string;
    claims_revision?: string;
};

export const buildExportName = (
    metadata: Metadata,
    eventName: string,
): string => {
    /**
     * Build the name of the file to be downloaded
     * from the metadata
     * The format is:
     * {eventName}_{as_at_date}_E{exposure_revision}C{claims_revision}
     */
    let { as_at_date, exposure_revision, claims_revision } = metadata;
    const as_at_date_component = as_at_date ? `_${as_at_date}_` : "";
    const exposure_component = exposure_revision || 0;
    const claims_component = claims_revision || 0;
    let downloadName = `${eventName.replace(
        / /g,
        "_",
    )}${as_at_date_component}E${exposure_component}C${claims_component}`;
    return downloadName.replace(/\./g, "_");
};
