import { Button, Flex, Modal, Stack } from "@mantine/core";
import { useOktaAuth } from "@okta/okta-react";
import { IconInfoCircle } from "@tabler/icons-react";
import React, { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createShareImageLink } from "store/service/serviceActions";
import MapGL, { Layer, MapRef, Source, ViewportProps } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { RootState } from "store/store";
import classes from "./ImageShareModal.module.css";
import cx from "classnames";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

interface ShareImageModalProps {
    closeModal: () => void;
    closeContextMenu: () => void;
    opened: boolean;
}

const blankMapStyle = {
    version: 8,
    name: "Blank",
    sprite: "mapbox://sprites/mapbox/streets-v{{ref.$version}}",
    glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    sources: {},
    layers: [],
};

const ImageShareModal: FC<ShareImageModalProps> = (
    props: ShareImageModalProps,
) => {
    const auth = useOktaAuth();
    const dispatch = useDispatch();
    const params = useParams<{ eventId: string }>();
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const [previewBlob, setPreviewBlob] = useState<Blob | null>(null);

    const previewMapRef = useRef<MapRef>(null);

    const sources = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").layersConfig.sources,
    );
    const initialViewport = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "report").viewport,
    );

    const [viewport, setViewport] = useState(initialViewport);

    const [loading, setLoading] = useState(true);

    // After first render make sure to update the viewport and reset loading state if location has changed.
    useEffect(() => {
        setLoading(true);
        if (props.opened) {
            setTimeout(() => {
                setLoading(false);
            }, 3000);
            setViewport(initialViewport);
        }
    }, [
        initialViewport,
        initialViewport.latitude,
        initialViewport.longitude,
        props.opened,
    ]);

    const onSubmit = () => {
        let map = previewMapRef.current?.getMap();

        map.once("render", function () {
            map.getCanvas().toBlob((blob: Blob) => {
                setPreviewBlob(blob);
            });
        });

        /* trigger render */
        map.setBearing(map.getBearing());
    };

    useEffect(() => {
        if (auth.authState?.accessToken && previewBlob) {
            dispatch(
                createShareImageLink(
                    auth.authState?.accessToken.accessToken,
                    params.eventId,
                    previewMapRef.current?.getMap().getCenter(),
                    previewBlob,
                ),
            );
        }
    }, [auth.authState?.accessToken, dispatch, params.eventId, previewBlob]);

    return (
        <Modal
            title="Image preview"
            onClose={props.closeModal}
            opened={props.opened}
            size={"90vh"}
            className={cx({
                [classes.Modal]: !loading,
                [classes.Loading]: loading,
            })}
            classNames={{ root: classes.ModalRoot, body: classes.ModalBody }}
        >
            <Stack h={"100%"}>
                <MapGL
                    key={"preview"}
                    ref={previewMapRef}
                    mapboxApiAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
                    width={"100%"}
                    height={"100%"}
                    latitude={viewport.latitude}
                    longitude={viewport.longitude}
                    zoom={viewport.zoom}
                    dragRotate={false}
                    style={{ width: "100%", height: "100%" }}
                    onViewportChange={(newViewport: ViewportProps) =>
                        setViewport(newViewport)
                    }
                    mapStyle={blankMapStyle}
                    reuseMaps={true}
                >
                    {Object.values(sources)
                        .filter((source) => {
                            return (
                                source.type === "raster" &&
                                source.layout.visibility === "visible"
                            );
                        })
                        .map((source) => {
                            return (
                                <Source
                                    key={source.source}
                                    {...source}
                                    maxzoom={19}
                                >
                                    <Layer
                                        id={source.source}
                                        type={source.layerType}
                                        source={source.source}
                                        paint={source.paint}
                                        layout={source.layout}
                                    />
                                </Source>
                            );
                        })}
                </MapGL>

                <Flex justify="space-between" align="center" gap={"md"}>
                    <Flex align="center" gap={"md"}>
                        <IconInfoCircle size={"1.5rem"} />
                        Your shareable image link will open in a new tab when
                        ready
                    </Flex>
                    <Flex align="center" gap={"md"} justify={"flex-end"}>
                        <Button
                            onClick={() => {
                                props.closeModal();
                                trackUserEventWithCurrentEvent({
                                    name: "image_preview_cancelled",
                                });
                            }}
                            variant="cancel"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="confirm"
                            onClick={() => {
                                onSubmit();
                                trackUserEventWithCurrentEvent({
                                    name: "image_preview_captured",
                                });
                            }}
                        >
                            Capture
                        </Button>
                    </Flex>
                </Flex>
            </Stack>
        </Modal>
    );
};

export default ImageShareModal;
