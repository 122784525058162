import React, { ReactNode, useState, useCallback, useImperativeHandle, forwardRef, useContext } from "react";
import { useDispatch } from "react-redux";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronRight, mdiDotsHorizontal } from "@mdi/js";
import {
    ConfigMenuGroup,
    ConfigMenuLayer,
} from "../../../../../../../../../store/system/systemTypes";
import { ActionIcon, Collapse, Popover } from "@mantine/core";
import GroupContextMenu from "../GroupContextMenu/GroupContextMenu";
import classes from "../LayersTab.module.css";
import { DownloadsContext } from "components/Pages/Report/Report";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { setGroupVisibility, setHighlightedLayer } from "store/report/reportActions";

interface GroupListItemProps {
    group: ConfigMenuGroup;
    renderChildren: (children: (ConfigMenuGroup | ConfigMenuLayer)[]) => ReactNode;
    initiallyExpanded: boolean;
    downloadsAvailable?: boolean;
}

export interface GroupListItemHandle {
    openGroup: () => void;
    closeGroup: () => void;
}

const GroupListItem = forwardRef<GroupListItemHandle, GroupListItemProps>(({
    group,
    renderChildren,
    initiallyExpanded,
    downloadsAvailable = false,
}, ref) => {
    const dispatch = useDispatch();
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const { downloadModalOpen } = useContext(DownloadsContext);
    const [isOpen, setIsOpen] = useState(initiallyExpanded);

    const openGroup = useCallback(() => {
        setIsOpen(true);
    }, []);

    const closeGroup = useCallback(() => {
        setIsOpen(false);
    }, []);

    useImperativeHandle(ref, () => ({
        openGroup,
        closeGroup
    }));

    const toggleGroupOpen = useCallback(() => {
        dispatch(setHighlightedLayer(null));
        setIsOpen((prevIsOpen) => {
            const newIsOpen = !prevIsOpen;
            return newIsOpen;
        });
        trackUserEventWithCurrentEvent({
            name: isOpen ? "group_collapse_clicked" : "group_expand_clicked",
            payload: {
                group_id: group.id,
                group_name: group.groupName
            },
        })
    }, [dispatch, group.groupName, group.id, isOpen, trackUserEventWithCurrentEvent]);

    const setGroupVisibilityFn = useCallback((visibility: "visible" | "none") => {
        dispatch(setGroupVisibility({
            groupId: group.id,
            visibility,
        }));
        trackUserEventWithCurrentEvent({
            name: "group_visibility_toggled",
            payload: {
                group_id: group.id,
                group_name: group.groupName
            },
        })
    }, [dispatch, group.groupName, group.id, trackUserEventWithCurrentEvent]);

    return (
        <div
            id={`touridLayerGroup${group.groupName}`}
            key={group.groupName}
            className={classes.Group}
        >
            <div className={classes.GroupHeader} onClick={toggleGroupOpen}>
                <div className={classes.GroupName}>
                    <div
                        id={`touridLayerGroupOpen${group.groupName}`}
                        className={classes.GroupToggle}
                    >
                        <Icon path={isOpen ? mdiChevronDown : mdiChevronRight} />
                    </div>
                    <span className={classes.GroupLabel}>{group.groupName}</span>
                </div>
                
                <div className={classes.LayerMenu} onClick={(e) => e.stopPropagation()}>
                    {!group.isInsights && (
                        <Popover position="top-end" classNames={{ dropdown: classes.ContextMenu }} disabled={downloadModalOpen}>
                            <Popover.Target>
                                <ActionIcon variant="subtle">
                                    <Icon path={mdiDotsHorizontal} />     
                                </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <GroupContextMenu
                                    group={group}
                                    onShowAll={() => setGroupVisibilityFn("visible")}
                                    onHideAll={() => setGroupVisibilityFn("none")}
                                    downloadsAvailable={downloadsAvailable}
                                />
                            </Popover.Dropdown>
                        </Popover>
                    )}
                </div>
            </div>
            <div className={classes.GroupBody}>
                <Collapse in={isOpen}>
                    {renderChildren(group.children)}
                </Collapse>
            </div>
        </div>
    );
});

export default GroupListItem;
