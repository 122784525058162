export const isURL = (str: string): boolean => {
    // eslint-disable-next-line -- complains about unnecessary escape chars "\+". Not willing to remove.
    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
        str,
    );
};

export function replaceSearchParams(
    currentSearchStr: string,
    newValues: { [key: string]: string },
) {
    let sp = new URLSearchParams(currentSearchStr);
    Object.entries(newValues).forEach((entry) => {
        sp.set(entry[0], entry[1]);
    });
    return sp.toString();
}
