import React, { FC } from "react";
import classes from "./ErrorList.module.css";

export interface ErrorPackage {
    message: string;
    level: "message" | "error" | "warning";
    id: number | string;
}

interface ErrorListProps {
    errors: ErrorPackage[];
}

const renderErrors = (errors: ErrorPackage[]) => {
    return errors.map((error) => {
        let className =
            error.level[0].toUpperCase() + error.level.slice(1).toLowerCase();
        return (
            <p key={error.id} className={classes[className]}>
                {error.message}
            </p>
        );
    });
};

export const ErrorList: FC<ErrorListProps> = ({ errors }: ErrorListProps) => {
    return <div className={classes.ErrorList}>{renderErrors(errors)}</div>;
};
