export const mdiMIS_agriculture =
    "M11.21,12.41,11,12a10.88,10.88,0,0,0-5.2-4.23l-.54,1.3a9.53,9.53,0,0,1,4.56,3.69,3.3,3.3,0,0,1,.18.32A10.26,10.26,0,0,1,1.47,9.3,8.92,8.92,0,0,1,.22,7.57L0,7.21l.26-.34a4.62,4.62,0,0,1,2-1.45,7.37,7.37,0,0,1,6.46.63,6,6,0,0,1,3.15,3.84A3.37,3.37,0,0,1,11.21,12.41ZM23.79,6.87c-1.57-1.88-4.87-2.78-8.47-.8a6,6,0,0,0-3.14,3.82,3.37,3.37,0,0,0,.61,2.52L13,12a10.88,10.88,0,0,1,5.2-4.23l.54,1.3a9.46,9.46,0,0,0-4.54,3.67l-.2.34A10.26,10.26,0,0,0,22.53,9.3a11.73,11.73,0,0,0,1.3-1.73L24,7.21ZM14,13.07a7.68,7.68,0,0,1-1.25-.66A10.17,10.17,0,0,0,12,14.15a10.53,10.53,0,0,0-.79-1.74,7.68,7.68,0,0,1-1.25.66,11.06,11.06,0,0,1,1.34,5.4v.58a6.15,6.15,0,0,1,1.4,0v-.58A11.14,11.14,0,0,1,14,13.07Z";
export const mdiMIS_drought =
    "M9.87,7.27v3.64L7.72,12,5.3,11.42l-1-4.15h1c.09,0,.19,0,.29,0h.3l.59,0H9.87Zm-7,0H0v6L3.89,11.6Zm7.78,4.84-2,1,.53,3.63h5.5l.47-3Zm13.38,0V7.27H17.9l.92,4Zm-7.49,1.58L16,16.74h8V13.5l-5.63-.92ZM4.78,12.74,0,14.82v1.92H7.69l-.51-3.45Zm11-.29,1.65-.95-1-4.23H11.27v3.58Z";
export const mdiMIS_earthquake =
    "M12,18.32a.65.65,0,0,1-.6-.43L8.37,9,6.89,12.26a.62.62,0,0,1-.57.37H.63a.63.63,0,0,1,0-1.26H5.91L7.87,7a.63.63,0,0,1,.84-.31A.58.58,0,0,1,9,7.06l2.9,8.47L14.5,6.15a.65.65,0,0,1,.59-.47.62.62,0,0,1,.62.44l1.72,5.25h5.94a.63.63,0,0,1,0,1.26H17a.63.63,0,0,1-.6-.43L15.16,8.51l-2.55,9.34a.63.63,0,0,1-.59.47Z";
export const mdiMIS_explosion =
    "M23.84,13.33l-4.25,4.2,2.67,1.67h-5l-1.42-.89,2.81-2.76-3.34.24V13l-2,1.34-.93-4.84L10.4,13.92,7.31,11.77l.93,4.14-2.64.57,1.91,1.67L6.17,19.2H1.45l2.52-2L1.16,14.78a.52.52,0,0,1,.23-.9l4-.85L3.88,6.56A.52.52,0,0,1,4.68,6L9.38,9.3l3-6.73a.52.52,0,0,1,1,.11l1.44,7.45,3-2a.52.52,0,0,1,.8.43V12.8l4.84-.35A.51.51,0,0,1,23.84,13.33ZM.65,21.74H23.14a.65.65,0,0,0,.65-.65v-.26a.65.65,0,0,0-.65-.65H.65a.66.66,0,0,0-.65.65v.26A.65.65,0,0,0,.65,21.74Z";
export const mdiMIS_flood =
    "M.13,4.52a.93.93,0,1,1,1.62-.93,3.32,3.32,0,0,0,5.75,0,.94.94,0,0,1,.81-.47h0a1,1,0,0,1,.82.47A3.33,3.33,0,0,0,12,5.25a3.36,3.36,0,0,0,2.88-1.66.92.92,0,0,1,.81-.47.94.94,0,0,1,.81.47,3.32,3.32,0,0,0,5.75,0,.93.93,0,1,1,1.62.93,5.19,5.19,0,0,1-4.5,2.6,5.23,5.23,0,0,1-3.68-1.53A5.26,5.26,0,0,1,12,7.12,5.22,5.22,0,0,1,8.31,5.58,5.18,5.18,0,0,1,.13,4.52Zm23.4,5.6a.94.94,0,0,0-1.28.35,3.32,3.32,0,0,1-5.75,0,.94.94,0,0,0-1.63,0,3.32,3.32,0,0,1-5.75,0A.92.92,0,0,0,8.31,10h0a.94.94,0,0,0-.81.47,3.32,3.32,0,0,1-5.75,0,.93.93,0,1,0-1.62.93,5.19,5.19,0,0,0,8.18,1.07,5.21,5.21,0,0,0,7.38,0,5.19,5.19,0,0,0,8.18-1.07A.94.94,0,0,0,23.53,10.12Zm0,6.88a.94.94,0,0,0-1.28.35,3.32,3.32,0,0,1-5.75,0,.94.94,0,0,0-1.63,0,3.32,3.32,0,0,1-5.75,0,.92.92,0,0,0-.81-.47h0a.94.94,0,0,0-.81.47,3.32,3.32,0,0,1-5.75,0A.94.94,0,0,0,.47,17a.94.94,0,0,0-.34,1.29,5.2,5.2,0,0,0,8.18,1.06,5.21,5.21,0,0,0,7.38,0,5.2,5.2,0,0,0,8.18-1.06A.94.94,0,0,0,23.53,17Z";
export const mdiMIS_freeze =
    "M22.39,18a.81.81,0,0,1-.71.41.87.87,0,0,1-.41-.11l-2.43-1.41,0,2.46a.82.82,0,0,1-.82.81h0a.82.82,0,0,1-.81-.83l0-3.38-4.39-2.53v5.06l2.94,1.67a.82.82,0,0,1-.4,1.53.87.87,0,0,1-.41-.11l-2.13-1.2v2.81a.82.82,0,0,1-1.64,0V20.37l-2.13,1.2a.87.87,0,0,1-.41.11.82.82,0,0,1-.4-1.53l2.94-1.67V13.42L6.79,16l0,3.38a.82.82,0,0,1-.81.83H6a.82.82,0,0,1-.81-.81l0-2.46L2.73,18.3a.87.87,0,0,1-.41.11.82.82,0,0,1-.41-1.53l2.43-1.41L2.23,14.23a.82.82,0,0,1,.83-1.41L6,14.53,10.36,12,6,9.47,3.06,11.18a.78.78,0,0,1-.41.12.84.84,0,0,1-.71-.41.82.82,0,0,1,.29-1.12L4.35,8.53,1.91,7.12A.82.82,0,0,1,2.73,5.7L5.16,7.11l0-2.46A.82.82,0,0,1,6,3.84H6a.82.82,0,0,1,.81.83l0,3.38,4.39,2.53V5.52L8.24,3.85a.82.82,0,1,1,.81-1.42l2.13,1.2V.82a.82.82,0,0,1,1.64,0V3.63L15,2.43a.82.82,0,1,1,.81,1.42L12.82,5.52v5.06l4.39-2.53,0-3.38A.82.82,0,0,1,18,3.84a.82.82,0,0,1,.82.81l0,2.46L21.27,5.7a.82.82,0,1,1,.82,1.42L19.65,8.53l2.12,1.24a.82.82,0,0,1,.29,1.12.84.84,0,0,1-.71.41.78.78,0,0,1-.41-.12L18,9.47,13.64,12,18,14.53l2.91-1.71a.82.82,0,1,1,.83,1.41l-2.12,1.24,2.44,1.41A.82.82,0,0,1,22.39,18Z";
export const mdiMIS_hurricane =
    "M13,20.66a6,6,0,0,1-1.42,2.05A.73.73,0,0,0,12.2,24,11.64,11.64,0,0,0,20.42,9.74,8.73,8.73,0,0,0,11,3.34a6.29,6.29,0,0,1,1.42-2.06.72.72,0,0,0,.18-.89A.74.74,0,0,0,11.8,0,11.64,11.64,0,0,0,3.58,14.26,8.73,8.73,0,0,0,13,20.66ZM10.87,7.79a4.36,4.36,0,1,1-3.08,5.34A4.36,4.36,0,0,1,10.87,7.79Z";
export const mdiMIS_industrial =
    "M8.48,2.12V3.56h-7V2.12a.62.62,0,0,1,.61-.61H7.86A.62.62,0,0,1,8.48,2.12Zm14,5.78v14a.62.62,0,0,1-.61.61H2.12a.62.62,0,0,1-.61-.61V4.79h7v6.14l6.08-3.56a.61.61,0,0,1,.61,0,.59.59,0,0,1,.31.53v3l6.08-3.56a.62.62,0,0,1,.62,0A.61.61,0,0,1,22.49,7.9ZM10.93,15.71a.61.61,0,0,0-.61-.62H7.86a.61.61,0,0,0-.61.62v2.46a.61.61,0,0,0,.61.61h2.46a.61.61,0,0,0,.61-.61Zm7,0a.61.61,0,0,0-.61-.62H14.87a.62.62,0,0,0-.62.62v2.46a.61.61,0,0,0,.62.61h2.46a.61.61,0,0,0,.61-.61Z";
export const mdiMIS_riot =
    "M9.33,10.61h4v1.33h-4ZM6,8H16.63V22.64H6Zm2,5.3h6.64v-4H8ZM11.32,2.65A2.65,2.65,0,0,0,8.67,5.31V6.63H14V5.31A2.66,2.66,0,0,0,11.32,2.65Zm-6.63,8H2v-4H7.34V5.31H0V20H4.69ZM3.36,9.29H4.69V8H3.36ZM15.3,6.63h5.3v4H18V20h4.69V5.31H15.3ZM18,9.29h1.33V8H18Zm2-6.64a2.65,2.65,0,0,0-5.3,0v.46a3.85,3.85,0,0,1,.41.87h4.89ZM5.35,0A2.65,2.65,0,0,0,2.7,2.65V4H7.58A4.37,4.37,0,0,1,8,3.11V2.65A2.65,2.65,0,0,0,5.35,0Z";
export const mdiMIS_sinkhole =
    "M22.45,17.46h1.9A12.17,12.17,0,0,0,15,5.61c.26,0,.51,0,.77-.05A10.3,10.3,0,0,1,23,8.44l1.3-1.38a12.54,12.54,0,0,0-4-2.56,11.74,11.74,0,0,0-9,.13,12.69,12.69,0,0,0-1.47.75,10.24,10.24,0,0,1,7.7-3.48V0A12.22,12.22,0,0,0,5.64,9.21c0-.22,0-.44,0-.67A10.26,10.26,0,0,1,8.45,1.38L7.07.07A12.1,12.1,0,0,0,5.4,14.61,10.25,10.25,0,0,1,1.9,6.9H0A12.19,12.19,0,0,0,9.21,18.71q-.37,0-.75,0h0a10.25,10.25,0,0,1-7-2.81L.11,17.32a12.14,12.14,0,0,0,8.33,3.32h0A12,12,0,0,0,14.61,19a10.25,10.25,0,0,1-7.71,3.5v1.9a12.16,12.16,0,0,0,11.81-9.21q0,.33,0,.66A10.19,10.19,0,0,1,15.89,23l1.38,1.31A12.1,12.1,0,0,0,19,9.74,10.25,10.25,0,0,1,22.45,17.46Zm-10.27-.29a5,5,0,1,1,5-5A5,5,0,0,1,12.18,17.17Z";
export const mdiMIS_storm =
    "M24,9.43a3.44,3.44,0,0,1-3.43,3.43H.92a.86.86,0,0,1,0-1.72H20.57a1.72,1.72,0,1,0-1.71-1.71.86.86,0,0,1-1.72,0,3.43,3.43,0,0,1,6.86,0ZM12,2.57A3.44,3.44,0,0,0,8.57,6a.86.86,0,1,0,1.72,0A1.71,1.71,0,1,1,12,7.71H.86a.86.86,0,1,0,0,1.72H12a3.43,3.43,0,0,0,0-6.86Zm0,12H.86a.86.86,0,1,0,0,1.72H12A1.71,1.71,0,1,1,10.29,18a.86.86,0,0,0-1.72,0A3.43,3.43,0,1,0,12,14.57Z";
export const mdiMIS_tobacco =
    "M21.36,19.35a13.24,13.24,0,0,1-6.77,5.31,23.21,23.21,0,0,1-4,1.26l4.2-7.37,3.85-1-.4-1.46-2.37.63,2.5-4.33-1.32-.76-1,1.7-.64-2.37L14,11.32l1,3.84-2,3.41-.64-2.38-1.46.39,1,3.85L9.29,25.16A23.21,23.21,0,0,1,8.3,21a13.16,13.16,0,0,1,1.22-8.52,13.21,13.21,0,0,1,6.77-5.32A20.59,20.59,0,0,1,20.65,6l.59-.08.22.55a20.68,20.68,0,0,1,1.12,4.39A13.16,13.16,0,0,1,21.36,19.35ZM2.61,9,3,7.54l2.37.64-1-1.7,1.32-.76,2.5,4.33.64-2.38,1.46.39L9.94,9.48a13.28,13.28,0,0,1,1.63-1.42A16.44,16.44,0,0,1,13.4,6.88l-.14-.25A13.21,13.21,0,0,0,6.49,1.31,21.77,21.77,0,0,0,2.13.08L1.54,0,1.32.55A20.89,20.89,0,0,0,.2,4.94a13.15,13.15,0,0,0,1.22,8.52,12.24,12.24,0,0,0,5.17,4.59c0-.52,0-1,.1-1.54A12.37,12.37,0,0,1,7,14.64l-1.39-.37L6,12.8l1.47.39c.1-.25.21-.51.33-.75L6.46,10Z";
export const mdiMIS_volcano =
    "M23.89,19.83a1.08,1.08,0,0,1-1,1.55H1.08a1.08,1.08,0,0,1-1-1.55L4,11.53a1.08,1.08,0,0,1,1.19-.59A2.58,2.58,0,0,0,5.8,11a2.89,2.89,0,0,0,2-.83A1.43,1.43,0,0,1,8.9,9.69a1.41,1.41,0,0,1,1.07.48,2.9,2.9,0,0,0,4.06,0,1.43,1.43,0,0,1,1.07-.48,1.41,1.41,0,0,1,1.07.48,2.91,2.91,0,0,0,2,.83,2.58,2.58,0,0,0,.58-.06,1.08,1.08,0,0,1,1.19.59ZM15.07,2.62H8.93a1.07,1.07,0,0,0-1,.62L5.08,9.32a1.28,1.28,0,0,0,.72.2A1.43,1.43,0,0,0,6.87,9a2.9,2.9,0,0,1,4.06,0A1.43,1.43,0,0,0,12,9.52,1.41,1.41,0,0,0,13.07,9a2.9,2.9,0,0,1,4.06,0,1.43,1.43,0,0,0,1.07.48,1.28,1.28,0,0,0,.72-.2L16,3.24A1.07,1.07,0,0,0,15.07,2.62Z";
export const mdiMIS_butterfly =
    "M 6.85,17.50 C 6.85,17.50 10.09,14.25 10.09,14.25 10.09,14.25 10.09,20.78 10.09,20.78 10.09,20.78 6.86,24.00 6.86,24.00 6.86,24.00 6.85,17.50 6.85,17.50 Z M 10.48,20.67 C 10.48,20.67 10.48,14.10 10.48,14.10 10.48,14.10 17.05,20.67 17.05,20.67 17.05,20.67 10.48,20.67 10.48,20.67 Z M 10.81,13.86 C 10.81,13.86 24.00,13.86 24.00,13.86 24.00,13.86 17.40,20.44 17.40,20.44 17.40,20.44 10.81,13.86 10.81,13.86 Z M 3.53,6.59 C 3.53,6.59 10.13,0.00 10.13,0.00 10.13,0.00 10.13,13.17 10.13,13.17 10.13,13.17 3.53,6.59 3.53,6.59 Z M 0.00,17.17 C 0.00,17.17 3.24,13.94 3.24,13.94 3.24,13.94 6.47,17.17 6.47,17.17 6.47,17.17 0.00,17.17 0.00,17.17 Z M 3.31,6.91 C 3.31,6.91 6.55,10.14 6.55,10.14 6.55,10.14 3.31,13.37 3.31,13.37 3.31,13.37 3.31,6.91 3.31,6.91 Z M 6.81,10.41 C 6.81,10.41 10.10,13.69 10.10,13.69 10.10,13.69 6.81,16.97 6.81,16.97 6.81,16.97 3.53,13.69 3.53,13.69 3.53,13.69 6.81,10.41 6.81,10.41 Z";

export const mdiMIS_legendPoint =
    "M24,15.27l-4.67,4.59-4.66-4.59,1.63-1.63,3,1.36,3-1.36Zm-9.74,2.25a7.72,7.72,0,0,1-3.64.9,7.81,7.81,0,1,1,7.8-7.8,8.29,8.29,0,0,1-.14,1.5h2.85A10.64,10.64,0,1,0,15.66,20Z";
export const mdiMIS_legendLine =
    "M13.78,19.86,0,5.82,2.75,4.14l9,9.9c-.24.17-.17-.28,0,0l5.23,5.82ZM24,15.53l-4.41,4.33-4.4-4.33L16.73,14l2.86,1.28L22.46,14Z";
export const mdiMIS_legendPoly =
    "M15.15,20.7H0V0H20.7V11.31h-3V3H3V17.73H13.44ZM24,15l-4.73,4.65L14.56,15l1.65-1.65,3.07,1.37,3.07-1.37Z";

export const mdiMIS_mapView =
    "M18.75,0A5.26,5.26,0,0,0,13.5,5.25c0,2.69,4.21,7.47,4.69,8a.75.75,0,0,0,1.12,0c.48-.53,4.69-5.31,4.69-8A5.26,5.26,0,0,0,18.75,0Zm0,7.5A2.25,2.25,0,1,1,21,5.25,2.25,2.25,0,0,1,18.75,7.5ZM7.5,6V21.36L1,24A.92.92,0,0,1,.75,24a.72.72,0,0,1-.42-.13A.74.74,0,0,1,0,23.25V9.51a.75.75,0,0,1,.47-.7ZM24,9.47v11a.75.75,0,0,1-.47.7L16.5,24V13.59c.23.27.42.49.58.66a2.23,2.23,0,0,0,3.34,0A31.87,31.87,0,0,0,24,9.47Zm-9,2.24V23.76l-6-2.4V6l3.47,1.39A19.62,19.62,0,0,0,15,11.71Z";
export const mdiMIS_legend =
    "M9.78,9.78H0V0H9.78Zm34-4.89h0A4.89,4.89,0,0,0,38.87,0H17.78a4.89,4.89,0,0,0-4.89,4.89h0a4.89,4.89,0,0,0,4.89,4.89H38.87A4.89,4.89,0,0,0,43.76,4.89Zm0,12.48h0a4.89,4.89,0,0,0-4.89-4.89H17.78a4.89,4.89,0,0,0-4.89,4.89h0a4.88,4.88,0,0,0,4.89,4.88H38.87A4.88,4.88,0,0,0,43.76,17.37Zm0,12.47h0A4.89,4.89,0,0,0,38.87,25H17.78a4.89,4.89,0,0,0-4.89,4.89h0a4.89,4.89,0,0,0,4.89,4.89H38.87A4.89,4.89,0,0,0,43.76,29.84Zm-34-12.21h0a4.89,4.89,0,0,0-4.89-4.89h0A4.89,4.89,0,0,0,0,17.63H0a4.89,4.89,0,0,0,4.89,4.89h0A4.89,4.89,0,0,0,9.78,17.63Zm-4.89,8.7L0,34.73h9.7Z";

export const mdiArrowUpDownRightBold =
    "M10.5 3C14.64 3 18 6.36 18 10.5V13H22L16 20L10 13H14V10.5C14 8.57 12.43 7 10.5 7S7 8.57 7 10.5V18H3V10.5C3 6.36 6.36 3 10.5 3Z";

export const mdiPublishOff =
    "M20.8 22.7L15 16.9V20H9V14H5L8.6 10.4L1.1 3L2.4 1.7L22.1 21.4L20.8 22.7M19 6V4H7.2L9.2 6H19M17.2 14H19L12 7L11.1 7.9L17.2 14Z";
