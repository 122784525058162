import React, { useEffect, useMemo, useState } from "react";
import classes from "./FeedbackModal.module.css";

import Modal from "../../../_Library/Modal/Modal";
import Button from "../../../_Library/Button/Button";

import { Rating, Textarea } from "@mantine/core";

import { getCssVar } from "utils/CSSHelpers";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { setAlert } from "store/system/systemActions";
import { FeedbackDetailsSchema, feedbackCreate } from "crud/accessCRUD";
import { useOktaAuth } from "@okta/okta-react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { SystemActionTypes } from "store/system/systemTypes";
import cx from "classnames";

interface DispatchProps {
    setAlert: typeof setAlert;
}
interface FeedbackModalProps {
    closeModal: () => void;
}

type AllProps = DispatchProps & FeedbackModalProps;

const MAX_CHARS = 500;

const BUTTON_SIZE = {
    height: "3.5rem",
    width: "15rem",
};
const FeedbackModal: React.FC<AllProps> = ({ closeModal, setAlert }) => {
    const [rating, setRating] = useState<number>(5);
    const [message, setMessage] = useState<string>("");
    const [feedBackSuccess, setFeedBackSuccess] = useState<boolean>(false);
    const [textAreaError, setTextAreaError] = useState<string>("");
    const [labelMessage, setLabelMessage] = useState<string>("");
    const { trackUserEvent } = useAnalytics();

    useEffect(() => {
        if (message.length >= MAX_CHARS) {
            setLabelMessage("");
            setTextAreaError(
                `${message.length}/${MAX_CHARS} maximum characters`,
            );
        } else {
            setLabelMessage(
                `${message.length}/${MAX_CHARS} maximum characters`,
            );
            setTextAreaError("");
        }
    }, [message]);

    const updateMessage = (messageText: string) => {
        if (messageText.length <= MAX_CHARS) {
            setMessage(messageText);
        }
    };

    const { oktaAuth } = useOktaAuth();
    const accessToken = oktaAuth.getAccessToken();
    const token = useMemo(() => {
        return accessToken;
    }, [accessToken]);

    const createFeedback = async (data: FeedbackDetailsSchema) => {
        let response = await feedbackCreate(token!, data);
        if (response.success) {
            setFeedBackSuccess(true);
            trackUserEvent({
                name: "feedback_modal_submitted",
                payload: {
                    rating: rating,
                    details: message,
                },
            });
        } else {
            setAlert({
                message: "Unable to complete feedback, please try again ",
                type: "Error",
            });
        }
    };

    const closeModalWithAnalytics = () => {
        trackUserEvent({
            name: "feedback_modal_cancelled",
            payload: {
                rating: rating,
                details: message,
            },
        });
        setMessage("");
        setRating(5);
        setFeedBackSuccess(false);
        closeModal();
    };

    return (
        <div className={classes.BringToFront}>
            {feedBackSuccess ? (
                <Modal
                    width={40}
                    height={20}
                    closeModal={closeModal}
                    containerClassName={cx(classes.minHeight)}
                    contentClassName={cx(classes.PrimaryBackgroundColor)}
                >
                    <div className={classes.CentredModal}>
                        <Rating
                            value={rating}
                            readOnly={true}
                            color={getCssVar("--highlight-color")}
                            size="xl"
                        />
                        <div className={classes.LargeText}>Thank you!</div>
                        <div className={classes.SmallText}>
                            Your feedback helps us to improve GEO
                        </div>
                    </div>
                </Modal>
            ) : (
                <Modal
                    width={40}
                    height={50}
                    closeModal={closeModalWithAnalytics}
                    disableOverlayClose={true}
                    containerClassName={cx(classes.minHeight)}
                    contentClassName={cx(
                        classes.PrimaryBackgroundColor,
                        classes.disableScroll,
                    )}
                >
                    <div className={classes.Title}>
                        Share your feedback with us
                    </div>
                    <div className={classes.SmallText}>
                        How satisfied are you with GEO?
                    </div>
                    <Rating
                        value={rating}
                        onChange={setRating}
                        color={getCssVar("--highlight-color")}
                        size="xl"
                    />
                    <div className={classes.TextAreaDescription}>
                        What can we do to make things better?{" "}
                        <span className={classes.SecondaryText}>
                            (Optional)
                        </span>
                    </div>
                    <Textarea
                        value={message}
                        onChange={(event) =>
                            updateMessage(event.currentTarget.value)
                        }
                        error={textAreaError}
                        errorProps={{
                            className: classes.TextAreaError,
                        }}
                        className={classes.TextAreaFeedback}
                        inputWrapperOrder={["input", "label", "error"]}
                        label={labelMessage}
                        labelProps={{
                            className: classes.TextAreaLabel,
                        }}
                    ></Textarea>
                    <div className={classes.BottomRow}>
                        <div className={classes.ButtonContainer}>
                            <Button
                                type="positive"
                                size={BUTTON_SIZE}
                                onClick={() => {
                                    createFeedback({
                                        rating: rating,
                                        details: message,
                                        type: "generic",
                                    });
                                }}
                            >
                                Submit
                            </Button>
                            <Button
                                type="negative"
                                size={BUTTON_SIZE}
                                onClick={closeModalWithAnalytics}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Modal>
            )}
        </div>
    );
};

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(FeedbackModal);
