/**
 * Generates a legend icon from the layer paint specified in the system. For raster returns mdi image icon.
 * This follows the mapbox style spec.
 * Also used to generates an icon from the map feature OnClick which presents the paint in a {r: , g: , b: } format.
 */

import React from "react";
import * as MB from "mapbox-gl";

import Line from "./Line";

interface OwnProps {
    paint: MB.LinePaint;
}

const LineIcon: React.FC<OwnProps> = (props: OwnProps) => {
    let width = props.paint?.["line-width"] ? props.paint["line-width"] : 1;

    let borderType: "solid" | "dotted" = "solid";
    if (props.paint["line-dasharray"]) {
        borderType = "dotted";
    }

    return (
        <Line color={"var(--text-color-lo-cont"} borderStyle={borderType} width={width as number} />
    );
};

export default LineIcon;
