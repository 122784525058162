import React, { CSSProperties } from "react";
import Icon from "@mdi/react";
import cx from "classnames";
import { connect } from "react-redux";

import { Link } from "react-router-dom";
import { eventTypeToIconPathSwitch } from "components/Pages/Report/DashboardComponents/Summary/Summary";
import { EventType, EventTypes } from "../../../../../store/system/systemTypes";
import { RootState } from "../../../../../store/store";

import { EventSchema } from "../../../../../crud/eventsCRUD";
import { withOktaAuth } from "@okta/okta-react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { fetchAllEventData } from "../../../../../store/service/serviceActions";
import { ThunkDispatch } from "redux-thunk";
import { ServiceActionTypes } from "../../../../../store/service/serviceTypes";
import { bindActionCreators } from "redux";
import { getStoreAtNamespaceKey } from "../../../../../store/storeSelectors";
import { ErrorPackage } from "../../../../_Library/ErrorList/ErrorList";
import Moment from "moment";
import { extendMoment } from "moment-range";

import "react-datepicker/dist/react-datepicker.css";
import classes from "./EventsListModal.module.css";
import Button from "../../../../_Library/Button/Button";

import { Input, Select } from "@mantine/core";
import { mdiClose, mdiFilterOutline, mdiMagnify } from "@mdi/js";
import { capitalize } from "lodash";
import { DatePickerInput, DateValue } from "@mantine/dates";
import ReactTooltip from "react-tooltip";
import withAnalytics, {
    withAnalyticsProps,
} from "components/_Library/HOC/withAnalytics";

const Hide: CSSProperties = {
    display: "none",
};
const Show: CSSProperties = {
    display: "block",
};
interface OwnProps {
    initialSearchTerm: string;
    onEventSelect: () => void;
}
interface StateProps {
    eventData: EventSchema[];
}
interface DispatchProps {
    fetchEventData: typeof fetchAllEventData;
}
type Extentions = StateProps &
    DispatchProps &
    IOktaContext &
    withAnalyticsProps;

type EventsListModalProps = OwnProps & Extentions;

interface EventsListModalState {
    advancedSearchToggle: boolean;
    searchTerm: string;
    eventType: EventType | "all";
    start: DateValue;
    end: DateValue;
    errors: ErrorPackage[];
}

class EventsListModal extends React.Component<
    EventsListModalProps,
    EventsListModalState
> {
    state: EventsListModalState = {
        advancedSearchToggle: false,
        searchTerm: "",
        eventType: "all",
        start: null,
        end: null,
        errors: [],
    };

    componentDidMount() {
        if (!this.props.eventData.length) {
            let token = this.props.oktaAuth.getAccessToken();
            if (token) {
                this.props.fetchEventData(token);
            }
        }
        this.setState({
            searchTerm: this.props.initialSearchTerm,
        });
    }

    validateFields = (startDate: Date, endDate: Date): ErrorPackage[] => {
        let errors: ErrorPackage[] = [];
        if (startDate > endDate) {
            errors = [
                {
                    message:
                        "The 'From' date, should not be after the 'To' date.",
                    level: "error",
                    id: startDate.toString(),
                },
            ];
        }
        return errors;
    };

    onDateChange = () => {
        this.setState({
            errors: this.validateFields(this.state.start!, this.state.end!),
        });
    };

    onStartDateChange = (date: Date) => {
        this.setState({ start: date });
        this.onDateChange();
    };

    onEndDateChange = (date: Date) => {
        this.setState({ end: date });
        this.onDateChange();
    };

    displayEvents = () => {
        let eventData = this.props.eventData.filter((event) => {
            let filterDateRange = null;
            let inRange = true;

            const moment = extendMoment(Moment as any); // Current release has wrong type definition,so put 'any' casting to avoid errors.https://github.com/rotaready/moment-range/issues/263
            const startDate = event.start * 1000;
            const endDate = event.end ? event.end * 1000 : null;
            filterDateRange = moment.range(
                moment(startDate!),
                moment(endDate!).add(1, "d"),
            );

            if (this.state.start && !this.state.end) {
                inRange = moment
                    //@ts-ignore
                    .range(moment(this.state.start), false)
                    .overlaps(filterDateRange);
            } else if (!this.state.start && this.state.end) {
                inRange = moment
                    .range(moment(0), moment(this.state.end))
                    .overlaps(filterDateRange);
            } else if (this.state.start && this.state.end) {
                inRange = moment
                    .range(moment(this.state.start), moment(this.state.end))
                    .overlaps(filterDateRange);
            }

            return (
                (this.state.eventType?.includes("all")
                    ? true
                    : this.state.eventType?.includes(event.type)) &&
                event.name
                    .toLowerCase()
                    .includes(this.state.searchTerm!.toLowerCase()) &&
                inRange
            );
        });

        return eventData.map((event) => {
            return (
                <div className={classes.EventContainer}>
                    <div className={classes.EventDetails}>
                        <Icon
                            path={eventTypeToIconPathSwitch[event.type].path}
                            size={1.25}
                        />
                        <p>{event.name}</p>
                    </div>

                    <div className={classes.EventButton}>
                        {event.has_report ? (
                            <div
                                onClick={() => {
                                    this.props.analytics.trackUserEvent({
                                        name: "view_report_clicked",
                                        payload: {
                                            source: "event_list",
                                            event_id: event.id,
                                            event_name: event.name,
                                        },
                                    });
                                    this.props.onEventSelect();
                                }}
                            >
                                <Link to={`/report/${event.id}`}>
                                    <Button size={{ width: "100%" }}>
                                        View Report
                                    </Button>
                                </Link>
                            </div>
                        ) : (
                            <Button size={{ width: "100%" }} disabled>
                                Report Pending
                            </Button>
                        )}
                    </div>
                </div>
            );
        });
    };

    eventTypeOptions = () => {
        return [...EventTypes].map((type) => ({
            value: type,
            label: capitalize(type),
        }));
    };

    isFiltered = () => {
        return (
            this.state.eventType !== "all" ||
            this.state.searchTerm !== "" ||
            this.state.start ||
            this.state.end
        );
    };

    render() {
        const isFiltered = this.isFiltered();

        return (
            <div className={classes.Container}>
                <div
                    className={cx(classes.DropdownContainer, {
                        [classes.DropdownContainerExpanded]:
                            this.state.advancedSearchToggle,
                    })}
                >
                    <div className={classes.AdvancedHeading}>
                        <Input
                            icon={<Icon path={mdiMagnify} size={1} />}
                            rightSection={
                                <div className={classes.InputRightSection}>
                                    {isFiltered && (
                                        <div
                                            className={classes.InputIcon}
                                            onClick={() => {
                                                this.setState({
                                                    searchTerm: "",
                                                    eventType: "all",
                                                    start: null,
                                                    end: null,
                                                });
                                            }}
                                            data-for={"RemoveTooltip"}
                                            data-tip={"Remove Filters"}
                                        >
                                            <Icon path={mdiClose} size={1} />

                                            <ReactTooltip
                                                id={"RemoveTooltip"}
                                                place={"top"}
                                                effect={"float"}
                                            ></ReactTooltip>
                                        </div>
                                    )}
                                    <div
                                        className={classes.InputIcon}
                                        onClick={() => {
                                            this.setState({
                                                advancedSearchToggle:
                                                    !this.state
                                                        .advancedSearchToggle,
                                            });
                                        }}
                                        data-for={"FilterTooltip"}
                                        data-tip={"Additional Filters"}
                                    >
                                        <Icon
                                            path={mdiFilterOutline}
                                            size={1}
                                        />

                                        <ReactTooltip
                                            id={"FilterTooltip"}
                                            place={"top"}
                                            effect={"float"}
                                        ></ReactTooltip>
                                    </div>
                                </div>
                            }
                            placeholder="Event Search"
                            onChange={(e) => {
                                this.setState({
                                    searchTerm: e.currentTarget.value,
                                });
                            }}
                            radius={"lg"}
                            className={classes.SearchInput}
                            styles={{
                                input: {
                                    "&::placeholder": {
                                        color: "var(--text-secondary)",
                                    },
                                    height: "3.5rem",
                                },
                            }}
                            value={this.state.searchTerm}
                        />
                    </div>
                    <div
                        className={classes.AdvancedContent}
                        style={this.state.advancedSearchToggle ? Show : Hide}
                    >
                        <Select
                            label={"Event Type"}
                            data={[
                                { value: "all", label: "All" },
                                ...this.eventTypeOptions(),
                            ]}
                            value={this.state.eventType}
                            onChange={(value) => {
                                this.setState({
                                    eventType: value as EventType,
                                });
                            }}
                            searchable
                            nothingFound="No event types found."
                            maxDropdownHeight={300}
                            styles={{
                                input: {
                                    height: "3.5rem",
                                },
                            }}
                        />
                        <div className={classes.DateInputs}>
                            <DatePickerInput
                                clearable
                                label={"From"}
                                value={this.state.start}
                                onChange={this.onStartDateChange}
                                styles={{
                                    input: {
                                        height: "3.5rem",
                                    },
                                }}
                            />
                            <DatePickerInput
                                clearable
                                label={"To"}
                                value={this.state.end}
                                className={classes.DatePickerInput}
                                onChange={this.onEndDateChange}
                                styles={{
                                    input: {
                                        height: "3.5rem",
                                    },
                                }}
                            />
                        </div>
                        <div className={classes.AdvancedButtons}>
                            <Button
                                size={{
                                    height: "2rem",
                                }}
                                disabled={!isFiltered}
                                onClick={() => {
                                    this.setState({
                                        searchTerm: "",
                                        eventType: "all",
                                        start: null,
                                        end: null,
                                    });
                                }}
                            >
                                Clear filters
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={classes.DisplayedEvents}>
                    {this.displayEvents()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    eventData: getStoreAtNamespaceKey(state, "service").eventData,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, ServiceActionTypes>,
) => ({
    fetchEventData: bindActionCreators(fetchAllEventData, dispatch),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withOktaAuth(withAnalytics(EventsListModal)));
