import React from "react";

import classes from "./TermsOfService.module.css";

import {
    GEOUserWithPortfolios,
    MisgisUserClaims,
} from "../../../../types/auth";
import { RootState } from "../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import { connect } from "react-redux";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";
import { setUser } from "../../../../store/user/userActions";
import { withOktaAuth } from "@okta/okta-react";
import { setAlert } from "../../../../store/system/systemActions";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createUpdatePreferences } from "crud/accessCRUD";
import { isUnsuccessfulAPIResponse } from "utils/TypeGuards";
import { ThunkDispatch } from "redux-thunk";
import { SystemActionTypes } from "store/system/systemTypes";
import { UserActionTypes } from "store/user/userTypes";
import { bindActionCreators } from "redux";
import Button from "components/_Library/Button/Button";
import ReactTooltip from "react-tooltip";

interface OwnProps {}

interface StateProps {
    user: MisgisUserClaims | null;
}

interface DispatchProps {
    setUser: typeof setUser;
    setAlert: typeof setAlert;
}

type Extensions = IOktaContext &
    RouteComponentProps &
    StateProps &
    DispatchProps;
type TermsOfServiceProps = OwnProps & Extensions;

interface TermsOfServiceState {
    readCheck: boolean;
    contactCheck: boolean;
}

class TermsOfService extends React.Component<
    TermsOfServiceProps,
    TermsOfServiceState
> {
    state: TermsOfServiceState = {
        readCheck: false,
        contactCheck: false,
    };

    updatedUserTos = async () => {
        if (!this.state.readCheck || !this.state.contactCheck) {
            this.props.setAlert({
                message: "Please accept both conditions to continue",
                type: "Error",
            });
            return;
        }

        const token = await this.props.oktaAuth.getAccessToken();
        const prefResponse = await createUpdatePreferences(token!, {
            tos: true,
        });
        if (!isUnsuccessfulAPIResponse(prefResponse)) {
            this.props.setUser({
                ...(this.props.user! as GEOUserWithPortfolios),
                tos: true,
            });
        }
    };

    render() {
        if (
            this.props.authState?.isAuthenticated &&
            this.props.user &&
            !this.props.user?.tos
        ) {
            return (
                <div className={classes.Container}>
                    <div className={classes.Terms}>
                        <h1>Terms and Conditions of Use</h1>
                        <p>
                            Welcome to this web portal Global Events Observer
                            GEO (<b>"Portal"</b>) operated by McKenzie
                            Intelligence Services Ltd. (<b>"MIS"</b>,{" "}
                            <b>"we"</b>, <b>"us"</b>). PLEASE READ THESE TERMS
                            AND CONDITIONS CAREFULLY BEFORE USING THE PORTAL. By
                            using, accessing or downloading materials from the
                            Portal, you agree to follow the terms and provisions
                            as outlined in these terms of use (
                            <b>“Terms of Use”</b>), which apply to all visits to
                            the Portal, both now and in the future. MIS may at
                            any time revise and update these Terms of Use.{" "}
                        </p>
                        <h2>Restrictions</h2>
                        <p>
                            In order to use, access or download materials from
                            the Portal you must be authorised by your
                            organisation and by accepting these Terms of Use, or
                            by using the Portal, you represent and warrant to us
                            that you are an authorised user. We make the Portal
                            available to you on a non-exclusive,
                            non-transferable, non-sublicensable basis for as
                            long as you are an authorised user.{" "}
                        </p>
                        <p>
                            Each authorised user of the Service (<b>“User”</b>)
                            will be provided with a single user account on the
                            Portal. You must keep your username and password
                            secure at all times and ensure that they are not
                            disclosed to any person.
                        </p>
                        <p>
                            You must change your password on a regular basis and
                            inform us immediately if you become aware or suspect
                            that the username and/or password for your account
                            is known by any unauthorised person so that we can
                            take appropriate action to prevent the misuse of
                            such information. Any misuse of the Portal or
                            unauthorised access will be reported to your
                            organisation.
                        </p>
                        <p>
                            You are able to view, download and copy information
                            and materials from the Portal solely for
                            professional and commercial use for the purpose of
                            using the Portal to carry on the business of your
                            organisation under these Terms of Use. You may also
                            use such material in connection with the support of
                            MIS's products. As a condition of use, you agree not
                            to modify or revise any of the material in any
                            manner except as permitted by these Terms of Use,
                            and to retain all copyright and other proprietary
                            notices as contained in the original materials on
                            any copies of the materials.
                        </p>
                        <p>
                            You acknowledge and agree that as soon as you are no
                            longer an authorised user, or our arrangement to
                            provide the Portal to your organisation expires or
                            terminates for any reason, your right to use the
                            Portal, including any content therein and any
                            related information, material and/or imagery will
                            immediately terminate, however you may retain any
                            material required solely:
                        </p>
                        <p>
                            <b>(i)</b> for legal and regulatory purposes to the
                            extent required by applicable law; and/or{" "}
                        </p>
                        <p>
                            <b>(ii)</b> for business use by your organisation in
                            respect of business which arose during the duration
                            of your time as an authorised user.
                        </p>
                        <h2>Confidentiality</h2>
                        <p>
                            We and you shall both keep confidential any
                            information of a confidential nature disclosed or
                            made available to it by or on behalf of the other.
                            Nothing in this clause shall apply to any
                            information which is (or becomes) available to the
                            public other than by breach of these Terms of Use or
                            where the party receiving the information already
                            possesses it or obtains it from a third party in
                            circumstances in which the disclosing and receiving
                            parties are free to disclose it.
                        </p>
                        <h2>General Data Protection Regulation (“GDPR”)</h2>
                        <p>
                            MIS has a lawful basis under these Terms of Use to
                            process the personal data of all Users in order to
                            enable access to the Portal. Personal data held on
                            each User is minimal and includes name, employer,
                            user name and email address. All data is processed
                            by employees of MIS and is stored centrally on a
                            secure server, accessed only by MIS employees.
                        </p>
                        <p>
                            No personal data or any other data that may identify
                            an individual as a user of the service is shared
                            with any third party. Any data held is not used for
                            marketing purposes. All Users retain all rights
                            commensurate with the GDPR in relation to personal
                            data processed and stored by MIS.
                        </p>
                        <p>
                            Where Users agree to receive marketing from MIS,
                            Users will be contacted by MIS by way of electronic
                            mail for the purpose of informing each User on data
                            sets and regarding relevant events, opportunities,
                            service enhancements, updates and maintenance
                            schedules to the service.
                        </p>
                        <p>
                            MIS shall comply with all laws and regulations
                            relating to the processing of personal data to which
                            MIS is subject from time to time, including without
                            limitation the GDPR, the Data Protection Act 2018
                            (and regulations made thereunder) and the Privacy
                            and Electronic Communications Regulations 2003 (SI
                            2003/2426) as amended.
                        </p>
                        <p>
                            The personal data of any User will be processed in
                            accordance with the MIS Privacy notice. (
                            <b>See below</b>)
                        </p>
                        <h2>Ownership of Information and Materials</h2>
                        <p>
                            The Portal, information and any materials
                            (including, but not limited to, reports, vector
                            data, maps but excluding imagery) available on or
                            from the Portal are the copyrighted works of MIS and
                            shall continue to be owned by MIS and any
                            unauthorised use of the Portal, information or
                            materials may violate copyright and other laws
                            and/or these Terms of Use. Users with a valid
                            account are licensed to use the information
                            contained within the Portal in accordance with these
                            Terms of Use but the information remains the
                            property of MIS. The information may be used and
                            shared in its original format or included as part of
                            User's own product(s) for the purpose of the
                            business of your organisation.
                        </p>
                        <p>
                            All imagery used in the Portal remains the property
                            of the imagery provider and is bound by separate
                            terms and conditions under the original End User
                            License Agreement with the imagery provider and MIS.
                            Whole images or parts of an image may be included in
                            any subsequent documents or materials and/or shared
                            with third parties provided they are for use within,
                            or in connection with the business of, your
                            organisation.
                        </p>
                        <p>
                            You acknowledge that you have no rights in, or to,
                            the Portal and/or any information and/or materials
                            available on or from the Portal other than the right
                            to use them in accordance with these Terms of Use.
                        </p>
                        <h2>Trademark Information</h2>
                        <p>
                            All imagery brands, trademarks and names are the
                            property of the respective owners. Use of any
                            imagery product in Users' own product(s) pursuant to
                            these Terms of Use must include the respective brand
                            and copyright notice in line with the End User
                            License Agreement pertaining to that particular
                            image. Except as expressly specified in these Terms
                            of Use, nothing contained herein shall be construed
                            as conferring by implication, estoppel or otherwise
                            any licence or right under any patent, trademark,
                            copyright or any proprietary rights of MIS or any
                            third party.
                        </p>
                        <h2>Links to Other Websites</h2>
                        <p>
                            As a convenience and to make the Portal truly
                            service orientated, we may include links to external
                            websites. These sites are owned and operated by
                            third parties. MIS makes no representation and is
                            not responsible for the availability of, or content
                            located on or through, these third party sites. A
                            third party link from the Portal is not an
                            indication that MIS endorses the third party or its
                            site, or has any affiliation with or between MIS and
                            the third party hosting site.
                        </p>
                        <h2>Feedback</h2>
                        <p>
                            All comments, feedback, information or materials
                            submitted to MIS through or in association with the
                            Portal shall be considered non-confidential and the
                            property of MIS.
                        </p>
                        <h2>Termination of Use</h2>
                        <p>
                            MIS may, in its sole discretion, terminate or
                            suspend access to an individual User to all or part
                            of the Portal in the event that the User commits a
                            breach of these Terms of Use. In the event that such
                            access is terminated or suspended, the restrictions
                            regarding materials appearing on the site and the
                            representations and warranties and limitation of
                            liabilities set forth in these Terms of Use shall
                            survive any such termination.
                        </p>
                        <h2>Other terms</h2>
                        <p>
                            These Terms of Use and any dispute or claim arising
                            out of or in connection with them or their subject
                            matter or formation (including any non-contractual
                            disputes or claims) shall be governed by and
                            construed in accordance with the laws of England and
                            Wales.
                        </p>
                        <p>
                            The courts of England and Wales shall have exclusive
                            jurisdiction to settle any dispute or claim arising
                            out of or in connection with these terms of use and
                            their subject matter or formation (including any
                            non-contractual disputes or claims).
                        </p>
                    </div>
                    <div className={classes.Form}>
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.readCheck}
                                onChange={(e) => {
                                    this.setState({
                                        readCheck: e.currentTarget.checked,
                                    });
                                }}
                            />
                            I agree to the Terms and Conditions of Use and
                            confirm that I acknowledge and have read the terms
                            of the Privacy Notice.
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                checked={this.state.contactCheck}
                                onChange={(e) => {
                                    this.setState({
                                        contactCheck: e.currentTarget.checked,
                                    });
                                }}
                            />
                            I agree for MIS to contact me regarding its products
                            and services, including but not limited to
                            notifications from GEO.
                        </label>
                        <div className={classes.ButtonContainer}>
                            <Button
                                onClick={async () => {
                                    await this.props.oktaAuth.revokeAccessToken();
                                    await this.props.oktaAuth.closeSession();
                                    this.props.history.push("/login");
                                    this.props.setUser(null);
                                }}
                                type={"negative"}
                            >
                                Logout
                            </Button>
                            <div
                                className={classes.SubmitButton}
                                data-for={"tos-tooltip"}
                                data-tip={
                                    !this.state.readCheck ||
                                    !this.state.contactCheck
                                        ? "Please agree to the terms and conditions"
                                        : ""
                                }
                            >
                                <Button
                                    onClick={this.updatedUserTos}
                                    type={"positive"}
                                    disabled={
                                        !this.state.readCheck ||
                                        !this.state.contactCheck
                                    }
                                >
                                    Submit
                                </Button>
                            </div>

                            <ReactTooltip
                                id={"tos-tooltip"}
                                place={"top"}
                                effect={"solid"}
                            />
                        </div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state: RootState) => ({
    user: getStoreAtNamespaceKey(state, "user").user,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, UserActionTypes | SystemActionTypes>,
) => {
    return {
        setUser: bindActionCreators(setUser, dispatch),
        setAlert: bindActionCreators(setAlert, dispatch),
    };
};

export default withRouter(
    withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(TermsOfService)),
);
