import React, { useEffect, useState, useCallback, CSSProperties } from "react";
import { mdiPanHorizontal } from "@mdi/js";
import { useDispatch, useSelector } from "react-redux";
import MapGL, { MapEvent, ViewportProps } from "react-map-gl";
import Icon from "@mdi/react";

import { RootState } from "../../../../../../../store/store";
import { MapProps } from "../MapContainer/MapContainer";
import { clamp } from "../../../../../../../utils/Maths";
import cx from "classnames";
import classes from "./MegaMap.module.css";
import "mapbox-gl/dist/mapbox-gl.css";
import ControlWrapper from "components/Pages/Report/DashboardComponents/Map/Mapping/Controls/ControlWrapper/ControlWrapper";
import { getStoreAtNamespaceKey } from "../../../../../../../store/storeSelectors";
import { getLayers } from "../../../../../../../utils/Layers";
import MapIcons from "assets/images/MapIcons";
import { CapitaliseWord } from "utils/String";
import { setViewport } from "store/report/reportActions";
import { useHistory, useLocation } from "react-router-dom";
import { MapType } from "store/report/reportTypes";

type MegaMapProps = MapProps;

const MegaMap: React.FC<MegaMapProps> = (props) => {
    const viewport = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "report").viewport,
    );
    const insightsViewOn = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").insightsViewOn,
    );
    const clusterToggle = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").clusterToggle,
    );
    const assessmentType = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").assessmentType,
    );
    const locationData = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").locationData,
    );

    const menuHidden = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "report").menuConfig.menuHidden,
    );

    const isPreview = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "report").isPreview,
    );

    const [sliderPos, setSliderPos] = useState<number>(
        props.mapType === "single" ? 0 : window.innerWidth / 2,
    );
    const [activeMapType, setActiveMapType] = useState<MapType>("single");
    const [mapContainerWidth, setMapContainerWidth] = useState<number>(
        window.innerWidth,
    );
    const [leftMapStyle, setLeftMapStyle] = useState<CSSProperties>({});
    const [rightMapStyle, setRightMapStyle] = useState<CSSProperties>({
        transition: "margin-left 1s",
    });

    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const interpolateSliderPos = useCallback(
        (
            finalValue: number,
            completionCallback?: () => void,
            duration?: number,
        ) => {
            const startValue = sliderPos;
            let timeElapsed = 0;

            if (!duration) {
                duration = 500;
            }

            const animate = () => {
                timeElapsed += 16;
                const progress = Math.min(timeElapsed / duration!, 1);
                const newValue =
                    startValue + (finalValue - startValue) * progress;
                setSliderPos(newValue);

                if (progress >= 1) {
                    if (completionCallback) {
                        completionCallback();
                    }
                    setSliderPos(finalValue);
                    clearInterval(intervalId);
                }
            };

            const intervalId = setInterval(animate, 16);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [sliderPos],
    );
    
    useEffect(() => {
        if (activeMapType === "dual") {
            setRightMapStyle({
                ...rightMapStyle,
                transition: !menuHidden
                    ? "margin-left 0.5s"
                    : "margin-left 1.1s",
                marginLeft: !menuHidden ? (window.innerWidth - 350) / 2 : "50%",
            });
        } else if (activeMapType === "compare" && !menuHidden) {
            const currentSliderPosRatio = sliderPos / window.innerWidth;
            const newSliderPos =
                currentSliderPosRatio * (window.innerWidth - 350);

            interpolateSliderPos(newSliderPos);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuHidden]);

    const animateToMapType = (mapType: MapType) => {
        const handleToCompare = () => {
            setActiveMapType("compare");
            setRightMapStyle({
                ...rightMapStyle,
                marginLeft: 0,
            });
            setLeftMapStyle({
                ...leftMapStyle,
                width: mapContainerWidth,
            });
        };

        const handleToSingle = (newLeftMapStyle: CSSProperties) => {
            interpolateSliderPos(0, () => {
                setActiveMapType("single");
            });
            setRightMapStyle({
                ...rightMapStyle,
                marginLeft: 0,
            });
            setLeftMapStyle({
                ...newLeftMapStyle,
            });
        };

        switch (activeMapType) {
            case "single":
                if (mapType === "compare") {
                    handleToCompare();
                    interpolateSliderPos(mapContainerWidth / 2);
                } else if (mapType === "dual") {
                    setRightMapStyle({
                        ...rightMapStyle,
                        marginLeft: !menuHidden ? mapContainerWidth / 2 : "50%",
                    });
                    setLeftMapStyle({
                        ...leftMapStyle,
                        transition: "clip-path 1s",
                        clipPath: `polygon(0 0, 100% 0, 100% 100%, 0 100%)`,
                    });
                    setActiveMapType("dual");
                }
                break;
            case "dual":
                if (mapType === "single") {
                    handleToSingle({
                        ...leftMapStyle,
                        transition: "clip-path 1s",
                        clipPath: `polygon(0 0, 0px 0, 0px 100%, 0 100%)`,
                    });
                } else if (mapType === "compare") {
                    handleToCompare();
                    setSliderPos(mapContainerWidth);
                }
                break;
            case "compare":
                if (mapType === "single") {
                    handleToSingle({
                        ...leftMapStyle,
                        width: mapContainerWidth,
                    });
                } else if (mapType === "dual") {
                    setRightMapStyle({
                        ...rightMapStyle,
                        marginLeft: mapContainerWidth / 2,
                    });
                    setActiveMapType("dual");
                    interpolateSliderPos(mapContainerWidth / 2);
                }
                break;
        }
    };

    useEffect(() => {
        animateToMapType(props.mapType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.mapType]);

    useEffect(() => {
        const loadMapImages = () => {
            let leftMap = props.leftMapRef.current?.getMap();
            let rightMap = props.rightMapRef.current?.getMap();
            for (let [imageName, image] of Object.entries(MapIcons)) {
                rightMap.loadImage(image, (err: Error, image: any) => {
                    if (err) throw err;
                    if (leftMap && !leftMap.hasImage(imageName)) {
                        leftMap.addImage(imageName, image);
                    }
                    if (!rightMap.hasImage(imageName)) {
                        rightMap.addImage(imageName, image);
                    }
                });
            }
        };
        loadMapImages();
    }, [props.leftMapRef, props.rightMapRef]);

    const preventGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "none";
    };

    const restoreGlobalMouseEvents = () => {
        document.body.style.pointerEvents = "auto";
    };

    const updateURL = useCallback(
        (viewport: ViewportProps) => {
            if (isPreview) {
                return;
            }

            const { longitude, latitude, zoom } = viewport;
            const pathSegments = location.pathname.split("/");
            const reportIndex = pathSegments.indexOf(pathSegments[1]);

            const newPathname = `/${pathSegments[1]}/${
                pathSegments[reportIndex + 1]
            }/${[longitude, latitude, zoom]
                .map((number) => number?.toFixed(5))
                .join("/")}`;

            if (newPathname !== location.pathname) {
                history.push({
                    pathname: newPathname,
                    search: location.search,
                });
            }
        },
        [history, isPreview, location.pathname, location.search],
    );

    const onMouseMove = useCallback((event: MouseEvent) => {
        const node = document.getElementsByClassName("overlays")[0];
        const rect = node.getBoundingClientRect();
        const offset = clamp(
            0,
            rect.right - rect.left,
            event.clientX - rect.left,
        );
        setSliderPos(offset);
    }, []);

    const onMouseUp = useCallback(() => {
        restoreGlobalMouseEvents();
        document.removeEventListener("mouseup", onMouseUp);
        document.removeEventListener("mousemove", onMouseMove);
    }, [onMouseMove]);

    const onMapResize = useCallback(({ width }: { width: number }) => {
        setMapContainerWidth(props.mapType === "dual" ? width * 2 : width);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeInvisibleLayersFromInteractiveIds = useCallback(
        (visibleLayers: React.ReactElement[]) => {
            let interactiveIds: string[] = [];
            let menuIndex = props.layersConfig!.menuIndex;
            let layersIndexResult = getLayers(menuIndex);
            visibleLayers.forEach((layer: React.ReactElement) => {
                layersIndexResult.forEach((layerFromIndex) => {
                    if (layerFromIndex.layerSource === layer.key) {
                        interactiveIds.push(layerFromIndex.layerName);
                    }
                });
            });
            return interactiveIds;
        },
        [props.layersConfig],
    );

    const onMouseDown = useCallback(() => {
        preventGlobalMouseEvents();
        document.addEventListener("mouseup", onMouseUp);
        document.addEventListener("mousemove", onMouseMove);
    }, [onMouseMove, onMouseUp]);

    useEffect(() => {
        setLeftMapStyle({
            clipPath: `polygon(0 0, ${sliderPos}px 0, ${sliderPos}px 100%, 0 100%)`,
            position: "absolute",
            top: 0,
            left: 0,
        });
    }, [sliderPos]);

    const leftMapViewport = {
        ...viewport,
        transitionDuration: 0,
        width: mapContainerWidth,
    };

    let assessmentLayerName = `${CapitaliseWord(assessmentType)} Assessment`;
    let interactiveInsightsRight: string[] = [];
    let interactiveInsightsLeft: string[] = [];

    if (locationData && ["both", "right"].includes(insightsViewOn)) {
        interactiveInsightsRight.push(assessmentLayerName);
        if (clusterToggle) {
            interactiveInsightsRight.push("insightsClusterCircle");
        }
    }

    if (locationData && ["both", "left"].includes(insightsViewOn)) {
        interactiveInsightsLeft.push(assessmentLayerName);
        if (clusterToggle) {
            interactiveInsightsLeft.push("insightsClusterCircle");
        }
    }

    let rightLayers: React.ReactElement[] | null = null;
    let leftLayers: React.ReactElement[] | null = null;

    let interactiveLayersRight: string[] = [];
    let interactiveLayersLeft: string[] = [];

    if (props.layers && props.layersConfig) {
        rightLayers = props.layers.filter((elem) =>
            ["both", "right"].includes(
                // @ts-ignore: checked in if above
                props.layersConfig.sources[elem.key!].viewOn,
            ),
        );

        leftLayers = props.layers.filter((elem) =>
            ["both", "left"].includes(
                // @ts-ignore: checked in if above
                props.layersConfig.sources[elem.key!].viewOn,
            ),
        );

        interactiveLayersRight =
            removeInvisibleLayersFromInteractiveIds(rightLayers);
        interactiveLayersLeft =
            removeInvisibleLayersFromInteractiveIds(leftLayers);
    }

    const rightMap = props.rightMapRef.current?.getMap();
    const leftMap = props.leftMapRef.current?.getMap();

    return (
        <React.Fragment>
            <div className={cx(classes.MegaMap, props.mapType)}>
                <MapGL
                    style={leftMapStyle}
                    reuseMaps
                    clickRadius={2}
                    className={classes.leftMap}
                    // @ts-ignore
                    width="100%"
                    height="100%"
                    ref={props.leftMapRef}
                    mapboxApiAccessToken={props.mapboxToken}
                    interactiveLayerIds={interactiveLayersLeft
                        .concat(props.additionalInteractiveLayerIds)
                        .concat(interactiveInsightsLeft)}
                    {...leftMapViewport}
                    mapStyle={`mapbox://styles/${props.basemaps[0]}`}
                    onViewportChange={(viewport: ViewportProps) => {
                        dispatch(setViewport(viewport));
                    }}
                    onHover={(e: MapEvent) =>
                        props.handleMapOnHover(e, leftMap)
                    }
                    onClick={props.handleMapOnClick}
                    getCursor={props.handleGetCursor}
                    onWheel={() => updateURL(viewport)}
                    onDblClick={() => updateURL(viewport)}
                    onMouseUp={() => updateURL(viewport)}
                    onContextMenu={(event: MapEvent) => {
                        props.onContextMenu(props.leftMapRef, event);
                    }}
                    dragRotate={false}
                    attributionControl={false}
                >
                    {leftLayers}
                    {props.additionalLayers}
                    {props.insightsDonutMarker}
                    {["both", "left"].includes(insightsViewOn) &&
                        props.insightLayers}
                    {props.mapSearchMarker}
                    {props.popup}
                    {props.contextMenu}

                    <ControlWrapper position="top-left">
                        {props.controls.tl}
                    </ControlWrapper>
                    <ControlWrapper position="bottom-left">
                        {props.controls.bl}
                    </ControlWrapper>
                    {props.mapType !== "dual" && (
                        <>
                            <ControlWrapper position="bottom-right">
                                {props.controls.br}
                            </ControlWrapper>
                            <ControlWrapper position="top-right">
                                {props.controls.tr}
                            </ControlWrapper>
                        </>
                    )}
                </MapGL>
                <MapGL
                    reuseMaps
                    clickRadius={2}
                    className={classes.RightMap}
                    style={rightMapStyle}
                    // @ts-ignore
                    width="100%"
                    height="100%"
                    ref={props.rightMapRef}
                    mapboxApiAccessToken={props.mapboxToken}
                    interactiveLayerIds={interactiveLayersRight
                        .concat(props.additionalInteractiveLayerIds)
                        .concat(interactiveInsightsRight)}
                    {...viewport}
                    mapStyle={`mapbox://styles/${props.basemaps[1]}`}
                    onViewportChange={(viewport: ViewportProps) => {
                        dispatch(setViewport(viewport));
                    }}
                    onHover={(e: MapEvent) =>
                        props.handleMapOnHover(e, rightMap)
                    }
                    onClick={props.handleMapOnClick}
                    getCursor={props.handleGetCursor}
                    onWheel={() => updateURL(viewport)}
                    onDblClick={() => updateURL(viewport)}
                    onMouseUp={() => updateURL(viewport)}
                    onContextMenu={(event: MapEvent) => {
                        props.onContextMenu(props.rightMapRef, event);
                    }}
                    dragRotate={false}
                    onResize={onMapResize}
                >
                    {rightLayers}
                    {props.additionalLayers}
                    {["both", "right"].includes(insightsViewOn) &&
                        props.insightLayers}
                    {props.insightsDonutMarker}
                    {props.mapSearchMarker}
                    {props.popup}
                    {props.contextMenu}
                    {props.mapType !== "dual" && (
                        <>
                            <ControlWrapper position="top-left">
                                {props.controls.tl}
                            </ControlWrapper>
                            <ControlWrapper position="bottom-left">
                                {props.controls.bl}
                            </ControlWrapper>
                        </>
                    )}
                    <ControlWrapper position="bottom-right">
                        {props.controls.br}
                    </ControlWrapper>
                    <ControlWrapper position="top-right">
                        {props.controls.tr}
                    </ControlWrapper>
                </MapGL>
            </div>
            {!Boolean(activeMapType === "single") && (
                <div
                    className={classes.sliderContainer}
                    style={{ left: sliderPos + "px" }}
                >
                    {Boolean(activeMapType === "compare") && (
                        <div
                            onMouseDown={onMouseDown}
                            className={classes.slider}
                            id="tourid_CompareMap_Slider"
                        >
                            <Icon path={mdiPanHorizontal} />
                        </div>
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

export default MegaMap;
