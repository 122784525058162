/**
 * LoadingScreen.
 */

import React, { FC } from "react";
import ButterflyLogo from "./ButterflyLogo/ButterflyLogo";
import classes from "./LoadingScreen.module.css";

interface LoadingScreenProps {}

const LoadingScreen: FC<LoadingScreenProps> = () => {
    return (
        <div className={classes.Container}>
            <ButterflyLogo height={100} animated={true} />
        </div>
    );
};

export default LoadingScreen;
