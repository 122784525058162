import { ConfigMenuGroup, ConfigMenuLayer } from "store/system/systemTypes";

export const getLayers = (
    group: (ConfigMenuGroup | ConfigMenuLayer)[],
    results: ConfigMenuLayer[] = [],
) => {
    const r = results;
    group.forEach((object) => {
        if (object.type === "layer") {
            r.push(object);
        } else {
            let children = object.children;
            getLayers(children!, r);
        }
    });
    return r;
};
