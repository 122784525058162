import React, {
    FC,
    RefObject,
    useCallback,
    useEffect,
    useRef,
    useState,
} from "react";
import Geocoder from "react-map-gl-geocoder";
import { useDispatch, useSelector } from "react-redux";

import { setCenter, setMapMarkerLocation } from "store/report/reportActions";

import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
import classes from "./GeocoderWrapper.module.css";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import ScrollableText from "../../../../../../_Library/ScrollableText/ScrollableText";
import ReactDOM from "react-dom";
import cx from "classnames";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { RootState } from "store/store";

interface SearchResult {
    place_name: string;
    bbox?: [number, number, number, number];
    center: [number, number];
}

interface SearchesType {
    searches: SearchResult[];
}

interface SearchType {
    result: SearchResult;
}

const GeocoderWrapper: FC = () => {
    const dispatch = useDispatch();

    let searchHistoryRoot: RefObject<HTMLDivElement> = useRef(null);
    let el: RefObject<HTMLDivElement> = useRef(null);

    const [pastSearches, setPastSearches] = useState<SearchesType>({
        searches: [],
    });

    const geocoderContainerRef = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "ref").geocoderContainerRef,
    );
    const mapRef = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "ref").mapRef,
    );

    useEffect(() => {
        if (geocoderContainerRef.current) {
            //@ts-ignore: current is assignable
            searchHistoryRoot.current = document.getElementById(
                "search-history-root",
            ) as HTMLDivElement;
            //@ts-ignore: current is assignable
            el.current = document.createElement("div");

            searchHistoryRoot.current.appendChild(el.current);

            let pastSearches: SearchesType = { searches: [] };

            if (localStorage["pastSearches"]) {
                pastSearches = JSON.parse(localStorage["pastSearches"]);

                setPastSearches(pastSearches);
            }
        }
    }, [geocoderContainerRef]);

    const storeSearches = useCallback(
        (search: SearchType) => {
            const duplicateSearch = pastSearches.searches.find((pastSearch) => {
                //Check if search already exists
                return pastSearch.place_name === search.result.place_name;
            });

            if (!duplicateSearch) {
                if (search.result.bbox) {
                    pastSearches.searches.push({
                        place_name: search.result.place_name,
                        bbox: search.result.bbox,
                        center: search.result.center,
                    });
                } else {
                    pastSearches.searches.push({
                        place_name: search.result.place_name,
                        center: search.result.center,
                    });
                }
            }

            localStorage.setItem("pastSearches", JSON.stringify(pastSearches));

            setPastSearches(pastSearches);

            let searchBox = document.getElementsByClassName(
                "mapboxgl-ctrl-geocoder--input",
            )[0] as HTMLInputElement;
            searchBox.value = "";
        },
        [pastSearches],
    );

    const showSearchHistory = useCallback(() => {
        let history = pastSearches;

        return (
            <div className={classes.PastSearch}>
                <div className={classes.PastSearchHeader}>
                    <h4>Search History</h4>
                    <div
                        className={cx(classes.Clickable, classes.MassDelete)}
                        onClick={() => {
                            localStorage.removeItem("pastSearches");
                            setPastSearches({ searches: [] });
                            let searchBox = document.getElementsByClassName(
                                "mapboxgl-ctrl-geocoder--input",
                            )[0] as HTMLInputElement;
                            searchBox.value = "";
                        }}
                    >
                        <p>Delete All</p>
                        <Icon path={mdiTrashCan} size={1} />
                    </div>
                </div>

                <div className={classes.HistoryContainer}>
                    {history.searches.reverse().map((search) => {
                        return (
                            <div
                                className={classes.HistoryResult}
                                key={search.place_name}
                            >
                                <div
                                    className={cx(
                                        classes.Clickable,
                                        classes.SearchItem,
                                    )}
                                >
                                    <div
                                        className={classes.SearchText}
                                        onClick={() => {
                                            let searchBox =
                                                document.getElementsByClassName(
                                                    "mapboxgl-ctrl-geocoder--input",
                                                )[0] as HTMLInputElement;
                                            searchBox.value = search.place_name;
                                            let zoom: number;

                                            if (search.bbox) {
                                                zoom = mapRef.current
                                                    ?.getMap()
                                                    .cameraForBounds([
                                                        [
                                                            search.bbox[0],
                                                            search.bbox[1],
                                                        ],
                                                        [
                                                            search.bbox[2],
                                                            search.bbox[3],
                                                        ],
                                                    ]).zoom;
                                            } else {
                                                zoom = 16;
                                            }

                                            dispatch(
                                                setMapMarkerLocation(
                                                    search.center,
                                                ),
                                            );

                                            dispatch(
                                                setCenter({
                                                    latitude: search.center[1]!,
                                                    longitude:
                                                        search.center[0]!,
                                                    zoom: zoom,
                                                }),
                                            );
                                        }}
                                    >
                                        <ScrollableText
                                            text={search.place_name}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={cx(
                                        classes.Clickable,
                                        classes.SearchDelete,
                                    )}
                                    onClick={() => {
                                        let newHistory =
                                            pastSearches.searches.filter(
                                                (object) => {
                                                    return (
                                                        object.place_name !==
                                                        search.place_name
                                                    );
                                                },
                                            );

                                        setPastSearches({
                                            searches: newHistory,
                                        });

                                        if (newHistory.length === 0) {
                                            localStorage.removeItem(
                                                "pastSearches",
                                            );
                                        } else {
                                            localStorage.setItem(
                                                "pastSearches",
                                                JSON.stringify(newHistory),
                                            );
                                        }

                                        let searchBox =
                                            document.getElementsByClassName(
                                                "mapboxgl-ctrl-geocoder--input",
                                            )[0] as HTMLInputElement;
                                        searchBox.value = "";
                                    }}
                                >
                                    <Icon path={mdiTrashCan} size={1} />
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }, [dispatch, mapRef, pastSearches]);

    if (
        geocoderContainerRef.current !== null &&
        geocoderContainerRef.current.children.length > 1
    ) {
        for (let child of geocoderContainerRef.current.children) {
            child.remove();
        }
    }

    return (
        <>
            {mapRef.current && geocoderContainerRef.current && (
                <Geocoder
                    mapRef={mapRef}
                    mapboxApiAccessToken={
                        "pk.eyJ1IjoibWlrZS1taXMiLCJhIjoidjBXS01iUSJ9.RW5YqGmR3hyfu28gO7l8ow"
                    }
                    onViewportChange={(newViewport: any) => {
                        return dispatch(setCenter(newViewport));
                    }}
                    marker={false}
                    containerRef={geocoderContainerRef}
                    onResult={(result: any) => {
                        storeSearches(result);

                        dispatch(
                            setMapMarkerLocation([
                                result.result.center[0],
                                result.result.center[1],
                            ]),
                        );
                    }}
                    onClear={() => {
                        dispatch(setMapMarkerLocation(null));
                    }}
                    localGeocoder={(query: string) => {
                        let matches = query.match(
                            /^[ ]*(?:Lat: )?(-?\d+\.?\d*)[, ]+(?:Lng: )?(-?\d+\.?\d*)[ ]*$/i,
                        );
                        if (!matches) {
                            return null;
                        }

                        function coordinateFeature(lng: number, lat: number) {
                            return {
                                center: [lng, lat],
                                geometry: {
                                    type: "Point",
                                    coordinates: [lng, lat],
                                },
                                place_name: "Lat: " + lat + " Lng: " + lng,
                                place_type: ["coordinate"],
                                properties: {},
                                type: "Feature",
                            };
                        }

                        function latitudeError(lng: number, lat: number) {
                            lat = 90;
                            return {
                                center: [lng, lat],
                                geometry: {
                                    type: "Point",
                                    coordinates: [lng, lat],
                                },
                                place_name:
                                    "Lat should be less than 90, Lat: " +
                                    lat +
                                    " Lng: " +
                                    lng,
                                place_type: ["coordinate"],
                                properties: {},
                                type: "Feature",
                            };
                        }

                        let coord1 = Number(matches[1]);
                        let coord2 = Number(matches[2]);
                        let geocodes = [];

                        if (coord1 > 90 && coord2 > 90) {
                            geocodes.push(latitudeError(coord1, coord2));
                            geocodes.push(latitudeError(coord2, coord1));
                        } else {
                            if (coord1 < -90 || coord1 > 90) {
                                geocodes.push(
                                    coordinateFeature(coord1, coord2),
                                );
                            }

                            if (coord2 < -90 || coord2 > 90) {
                                geocodes.push(
                                    coordinateFeature(coord2, coord1),
                                );
                            }
                        }

                        if (geocodes.length === 0) {
                            geocodes.push(coordinateFeature(coord1, coord2));
                            geocodes.push(coordinateFeature(coord2, coord1));
                        }

                        return geocodes;
                    }}
                />
            )}

            {pastSearches.searches.length !== 0 &&
                el.current &&
                ReactDOM.createPortal(showSearchHistory(), el.current)}
        </>
    );
};

export default GeocoderWrapper;
