import React, { FC } from "react";
import classes from "./InitialsIcon.module.css";
import _ from "lodash";

interface OwnProps {
    width?: number;
    firstName?: string | null;
    lastName?: string | null;
}

const InitialsIcon: FC<OwnProps> = ({ firstName, lastName, width = 3 }) => {
    let initials = _.first(firstName)! + _.first(lastName)! || "N/A";
    const fontSize = width / 3 + "rem";
    return (
        <svg
            className={classes.SVGIconContainer}
            height={`${width}rem`}
            width={`${width}rem`}
        >
            <text
                className={classes.SVGText}
                style={{ fontSize }}
                textAnchor={"middle"}
                alignmentBaseline={"central"}
                dominantBaseline={"central"}
                x={"50%"}
                y={"50%"}
            >
                {initials}
            </text>
        </svg>
    );
};

export default InitialsIcon;
