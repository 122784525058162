import React, { useEffect, useState } from "react";
import classes from "./RangeSelect.module.css";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiFilterRemove } from "@mdi/js";
import { NumberInput, RangeSlider } from "@mantine/core";
import { useClickOutside } from "@mantine/hooks";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

interface Props {
    prefix?: string;
    title: string;
    format?: boolean;
    minValue: number;
    maxValue: number;
    onSubmit?: (value: { min: number; max: number }) => void;
}

export const RangeSelect = ({
    prefix = "",
    title,
    format,
    minValue,
    maxValue,
    // onSubmit is fn to call when onChangeEnd for slider occurs
    // or as the input changes
    onSubmit = () => {},
}: Props) => {
    const [selectedMin, setSelectedMin] = useState<number>(minValue);
    const [selectedMax, setSelectedMax] = useState<number>(maxValue);
    const [expanded, setExpanded] = useState<boolean>(false);

    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const inputStyles = {
        input: { borderBottom: "0.1rem solid var(--border-color)" },
    };
    let minInputRef: React.RefObject<HTMLInputElement> =
        useClickOutside<HTMLInputElement>(() => minInputRef.current?.blur());
    let maxInputRef: React.RefObject<HTMLInputElement> =
        useClickOutside<HTMLInputElement>(() => maxInputRef.current?.blur());

    // Everytime component re-mounted, reset initial state
    useEffect(() => {
        setSelectedMin(minValue);
        setSelectedMax(maxValue);
    }, [minValue, maxValue]);

    useEffect(() => {
        onSubmit({ min: selectedMin, max: selectedMax });
    }, [selectedMin, selectedMax, onSubmit]);

    const formatNumber = (value: number) => {
        if (format) {
            return !Number.isNaN(value)
                ? `${prefix} ${Math.round(value)}`.replace(
                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                      ",",
                  )
                : `${prefix} `;
        } else {
            return value;
        }
    };

    const isFiltered = minValue !== selectedMin || maxValue !== selectedMax;

    return (
        <div
            className={classes.Container}
            onClick={() => {
                if (!expanded) {
                    setExpanded(!expanded);
                }
                trackUserEventWithCurrentEvent({
                    name: !expanded
                        ? "estimated_exposure_expanded"
                        : "estimated_exposure_collapsed",
                });
            }}
        >
            <div className={classes.TitleContainer}>
                <p className={classes.Title}>{title}</p>
                <div className={classes.Icons}>
                    {isFiltered && (
                        <div
                            onClick={() => {
                                setSelectedMin(minValue);
                                setSelectedMax(maxValue);
                                onSubmit({ min: minValue, max: maxValue });
                            }}
                        >
                            <Icon path={mdiFilterRemove} size={1} />
                        </div>
                    )}
                    <div onClick={() => setExpanded(!expanded)}>
                        <Icon
                            path={expanded ? mdiChevronUp : mdiChevronDown}
                            size={1.5}
                        />
                    </div>
                </div>
            </div>
            {expanded && (
                <>
                    <div className={classes.RangeInputs}>
                        <NumberInput
                            label="Minimum"
                            value={selectedMin}
                            onChange={(value: number) => {
                                setSelectedMin(value);
                                trackUserEventWithCurrentEvent({
                                    name: "estimated_exposure_value_toggled",
                                    payload: {
                                        source: "min_input",
                                    },
                                });
                            }}
                            precision={0}
                            step={100000}
                            max={selectedMax}
                            min={minValue}
                            stepHoldDelay={500}
                            stepHoldInterval={(t) =>
                                Math.max(1000 / t ** 2, 25)
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            formatter={(value) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `$ ${value}`.replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ",",
                                      )
                                    : "$ "
                            }
                            sx={inputStyles}
                            ref={minInputRef}
                        />
                        -
                        <NumberInput
                            label="Maximum"
                            value={Math.round(selectedMax)}
                            onChange={(value: number) => {
                                setSelectedMax(value);
                                trackUserEventWithCurrentEvent({
                                    name: "estimated_exposure_value_toggled",
                                    payload: {
                                        source: "max_input",
                                    },
                                });
                            }}
                            precision={0}
                            step={100000}
                            max={maxValue}
                            min={selectedMin}
                            stepHoldDelay={500}
                            stepHoldInterval={(t) =>
                                Math.max(1000 / t ** 2, 25)
                            }
                            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                            formatter={(value) =>
                                !Number.isNaN(parseFloat(value))
                                    ? `$ ${value}`.replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ",",
                                      )
                                    : "$ "
                            }
                            sx={inputStyles}
                            ref={maxInputRef}
                        />
                    </div>

                    <div className={classes.Slider}>
                        <RangeSlider
                            min={minValue}
                            max={maxValue}
                            value={[selectedMin, selectedMax]}
                            onChange={(value) => {
                                setSelectedMin(value[0]);
                                setSelectedMax(value[1]);
                            }}
                            onChangeEnd={(value) => {
                                onSubmit({ min: value[0], max: value[1] });
                                trackUserEventWithCurrentEvent({
                                    name: "estimated_exposure_slider_toggled",
                                    payload: {
                                        source: "slider",
                                    },
                                });
                            }}
                            label={(value) => (
                                <span className={classes.SliderLabel}>
                                    {formatNumber(value)}
                                </span>
                            )}
                            classNames={{
                                thumb: classes.Thumb,
                                label: classes.Label,
                            }}
                        />
                    </div>
                </>
            )}
            {!expanded && isFiltered && (
                <p className={classes.RangeValues}>
                    {formatNumber(selectedMin)} - {formatNumber(selectedMax)}
                </p>
            )}
        </div>
    );
};
