/**
 * This Component is used to display a dashboard message like an alert.
 * Multiple calls to SetAlert should stack the alerts with their own timeout.
 *
 */

import React from "react";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

import { RootState } from "../../../store/store";
import {
    AlertConfig,
    SystemActionTypes,
} from "../../../store/system/systemTypes";
import { setAlert } from "../../../store/system/systemActions";
import { getStoreAtNamespaceKey } from "../../../store/storeSelectors";

import classes from "./AlertContainer.module.css";
import Alert from "./Alert/Alert";

interface StateProps {
    alerts: { [key: string]: AlertConfig };
}

interface DispatchProps {
    setAlert: typeof setAlert;
}

type OwnProps = DispatchProps & StateProps;

const AlertContainer: React.FC<OwnProps> = (props: OwnProps) => {
    const renderAlerts = () => {
        return Object.keys(props.alerts).map((id) => {
            let alert = props.alerts[id];
            return (
                <div key={id}>
                    {" "}
                    <Alert key={id} uuid={id} alert={alert} />{" "}
                </div>
            );
        });
    };

    return <div className={classes.Container}>{renderAlerts()}</div>;
};

const mapStateToProps = (state: RootState): StateProps => ({
    alerts: getStoreAtNamespaceKey(state, "system").alerts,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    setAlert: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AlertContainer);
