import { ThunkDispatch } from "redux-thunk";
import { makeAction, makeActionWithPayload } from "../../utils/ReduxHelpers";
import {
    ADD_POLICY_INSIGHTS,
    AddPolicyInsightsAction,
    LOAD_INSIGHTS,
    LoadInsightsPayload,
    REMOVE_INSIGHTS_DATA,
    SET_EVENT_ID,
    SET_FETCHING_STATUS,
    TOGGLE_DAMAGE_LABEL_FILTER,
    SET_INSIGHTS_LAYER_VIEW,
    SET_INSIGHTS_LAYER_VISIBILITY,
    SET_INSIGHTS_LOADED,
    SET_INSIGHTS_TYPE,
    SET_CONTRACT_ID_FILTER,
    TOGGLE_INSIGHTS_VISIBILITY,
    SET_SELECTED_PORTFOLIO,
    SetEventIdPayload,
    SetFetchingStatusPayload,
    toggleDamageLabelFilterPayload,
    SetInsightsLayerVisibilityPayload,
    SetInsightsLoadedPayload,
    SetInsightsTypePayload,
    SetLayerInsightsLayerViewPayload,
    setSelectedPortfolioPayload,
    TOGGLE_CLUSTER,
    TOGGLE_MARKER,
    ToggleMarkerPayload,
    SET_ASSESSMENT_TYPE,
    SetAssessmentTypePayload,
    CLEAR_INSIGHTS_FILTERS,
    SET_FILTERED_INDICES,
    SetFilteredIndicesPayload,
    CLEAR_FILTERED_INDICES,
    InsightsActionTypes,
    LoadInsightsPackagePayload,
    SetContractIdFilterPayload,
    SET_SELECTED_PERIL,
    SetSelectedPerilPayload,
    SET_REVISIONS,
    SetRevisionsPayload,
    SET_SELECTED_REVISION_ID,
    SET_FILTERED_PROPERTIES,
    SetFilteredPropertiesPayload,
    SET_ASSESSMENT_FILTERS,
    SetAssessmentFiltersPayload,
    SET_ALL_INSIGHTS_STYLES,
    SetAllInsightsStylesAction,
    UPDATE_ASSESSMENT_STYLES,
    UpdateAssesmentStylesPayload,
} from "./insightsTypes";
import { SystemActionTypes } from "../system/systemTypes";
import { parseInsightsCSV } from "./utils";

export const loadInsightsPackage = (
    insightsPackage: LoadInsightsPackagePayload,
) => {
    return (
        dispatch: ThunkDispatch<
            any,
            any,
            SystemActionTypes | InsightsActionTypes
        >,
    ) => {
        let locationData: Papa.ParseResult<any> | null = null;

        const worker = new Worker("/workerScripts/createGeojson.js");

        if (insightsPackage.insightsData) {
            parseInsightsCSV(insightsPackage.insightsData, (parseResult) => {
                locationData = parseResult;
                worker.postMessage(locationData);
                worker.onmessage = function (e) {
                    let insightsGeojson = e.data;
                    dispatch(
                        loadInsights({
                            ...insightsPackage,
                            locationData,
                            insightsGeojson,
                        }),
                    );
                    worker.terminate();
                };

                worker.onerror = function (e) {
                    worker.terminate();
                };
            });
        } else {
            dispatch(
                loadInsights({
                    ...insightsPackage,
                    locationData: null,
                    insightsGeojson: null,
                }),
            );
        }
    };
};

export const setInsightsLoaded = makeActionWithPayload<
    typeof SET_INSIGHTS_LOADED,
    SetInsightsLoadedPayload
>(SET_INSIGHTS_LOADED);

export const removeInsightsData =
    makeAction<typeof REMOVE_INSIGHTS_DATA>(REMOVE_INSIGHTS_DATA);

export const setInsightsType = makeActionWithPayload<
    typeof SET_INSIGHTS_TYPE,
    SetInsightsTypePayload
>(SET_INSIGHTS_TYPE);

export const setInsightsLayerVisibility = makeActionWithPayload<
    typeof SET_INSIGHTS_LAYER_VISIBILITY,
    SetInsightsLayerVisibilityPayload
>(SET_INSIGHTS_LAYER_VISIBILITY);

export const setInsightsLayerView = makeActionWithPayload<
    typeof SET_INSIGHTS_LAYER_VIEW,
    SetLayerInsightsLayerViewPayload
>(SET_INSIGHTS_LAYER_VIEW);

export const toggleDamageLabelFilter = makeActionWithPayload<
    typeof TOGGLE_DAMAGE_LABEL_FILTER,
    toggleDamageLabelFilterPayload
>(TOGGLE_DAMAGE_LABEL_FILTER);

export const setContractIdFilter = makeActionWithPayload<
    typeof SET_CONTRACT_ID_FILTER,
    SetContractIdFilterPayload
>(SET_CONTRACT_ID_FILTER);

export const clearAllFilters = makeAction<typeof CLEAR_INSIGHTS_FILTERS>(
    CLEAR_INSIGHTS_FILTERS,
);

export const toggleInsightsVisibility = makeAction<
    typeof TOGGLE_INSIGHTS_VISIBILITY
>(TOGGLE_INSIGHTS_VISIBILITY);

export const setSelectedPortfolio = makeActionWithPayload<
    typeof SET_SELECTED_PORTFOLIO,
    setSelectedPortfolioPayload
>(SET_SELECTED_PORTFOLIO);

export const setEventId = makeActionWithPayload<
    typeof SET_EVENT_ID,
    SetEventIdPayload
>(SET_EVENT_ID);

export const setFetchingStatus = makeActionWithPayload<
    typeof SET_FETCHING_STATUS,
    SetFetchingStatusPayload
>(SET_FETCHING_STATUS);

export const SetAssessmentFilters = makeActionWithPayload<
    typeof SET_ASSESSMENT_FILTERS,
    SetAssessmentFiltersPayload
>(SET_ASSESSMENT_FILTERS);

export const toggleMarker = makeActionWithPayload<
    typeof TOGGLE_MARKER,
    ToggleMarkerPayload
>(TOGGLE_MARKER);

export const toggleCluster = makeAction<typeof TOGGLE_CLUSTER>(TOGGLE_CLUSTER);

export const setAssessmentType = makeActionWithPayload<
    typeof SET_ASSESSMENT_TYPE,
    SetAssessmentTypePayload
>(SET_ASSESSMENT_TYPE);

export const addPolicyInsights = makeActionWithPayload<
    typeof ADD_POLICY_INSIGHTS,
    AddPolicyInsightsAction
>(ADD_POLICY_INSIGHTS);

export const loadInsights = makeActionWithPayload<
    typeof LOAD_INSIGHTS,
    LoadInsightsPayload
>(LOAD_INSIGHTS);

export const updateAssessmentStyles = makeActionWithPayload<
    typeof UPDATE_ASSESSMENT_STYLES,
    UpdateAssesmentStylesPayload
>(UPDATE_ASSESSMENT_STYLES);

export const setFilteredIndices = makeActionWithPayload<
    typeof SET_FILTERED_INDICES,
    SetFilteredIndicesPayload
>(SET_FILTERED_INDICES);

export const setFilteredProperties = makeActionWithPayload<
    typeof SET_FILTERED_PROPERTIES,
    SetFilteredPropertiesPayload
>(SET_FILTERED_PROPERTIES);

export const clearFilteredIndices = makeAction<typeof CLEAR_FILTERED_INDICES>(
    CLEAR_FILTERED_INDICES,
);

export const setSelectedPeril = makeActionWithPayload<
    typeof SET_SELECTED_PERIL,
    SetSelectedPerilPayload
>(SET_SELECTED_PERIL);

export const setRevisions = makeActionWithPayload<
    typeof SET_REVISIONS,
    SetRevisionsPayload
>(SET_REVISIONS);

export const setSelectedRevisionId = makeActionWithPayload<
    typeof SET_SELECTED_REVISION_ID,
    string
>(SET_SELECTED_REVISION_ID);

export const setInsightsStyles = makeActionWithPayload<
    typeof SET_ALL_INSIGHTS_STYLES,
    SetAllInsightsStylesAction["payload"]
>(SET_ALL_INSIGHTS_STYLES);
