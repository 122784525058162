import {
    DELETE_ALERT,
    SET_ALERT,
    DeleteAlertPayload,
    SetAlertPayload,
    SET_COOKIES_SET,
    SetCookiesSetPayload,
    SET_PREVIEW_CONFIG,
    TOGGLE_MODAL,
} from "./systemTypes";
import { makeActionWithPayload } from "utils/ReduxHelpers";
import { SetPreviewConfigPayload } from "../service/serviceTypes";

export const setAlert = makeActionWithPayload<
    typeof SET_ALERT,
    SetAlertPayload
>(SET_ALERT);
export const deleteAlert = makeActionWithPayload<
    typeof DELETE_ALERT,
    DeleteAlertPayload
>(DELETE_ALERT);
export const setCookiesSet = makeActionWithPayload<
    typeof SET_COOKIES_SET,
    SetCookiesSetPayload
>(SET_COOKIES_SET);
export const SetPreviewConfig = makeActionWithPayload<
    typeof SET_PREVIEW_CONFIG,
    SetPreviewConfigPayload
>(SET_PREVIEW_CONFIG);
export const toggleModal = makeActionWithPayload<
    typeof TOGGLE_MODAL,
    boolean | null
>(TOGGLE_MODAL);
