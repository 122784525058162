import React, { CSSProperties, MouseEventHandler, ReactNode } from "react";

import classes from "./Button.module.css";
import cx from "classnames";
import { Badge, BadgeProps } from "@mantine/core";

export interface OwnProps {
    children: ReactNode;
    disabled?: boolean;
    size?: {
        width?: CSSProperties["width"];
        minWidth?: CSSProperties["width"];
        height?: CSSProperties["height"];
        minHeight?: CSSProperties["width"];
    };
    type?: "negative" | "positive" | "neutral" | "destructive";
    highlightBackground?: boolean;
    borderRadius?: CSSProperties["borderRadius"];
    onClick?: MouseEventHandler;
    badgeProps?: BadgeProps;
    badgeLabel?: string;
}

type ButtonProps = OwnProps;

const Button: React.FC<OwnProps> = (props: ButtonProps) => {
    return (
        <button
            disabled={props.disabled}
            style={{
                ...props.size,
                borderRadius: props.borderRadius,
            }}
            className={cx(classes.Button, {
                [classes.DisabledButton]: props.disabled,
                [classes.NegativeButton]: props.type === "negative",
                [classes.PositiveButton]: props.type === "positive",
                [classes.DestructiveButton]: props.type === "destructive",
                [classes.HighlightButton]: props.highlightBackground,
            })}
            onClick={props.onClick}
        >
            {props.children}
            {props.badgeProps && (
                <Badge
                    classNames={{
                        root: classes.ButtonBadgeRoot,
                        inner: classes.ButtonBadgeInner,
                    }}
                    {...props.badgeProps}
                >
                    {props?.badgeLabel}
                </Badge>
            )}
        </button>
    );
};

export default Button;
