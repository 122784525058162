/**
 * Generates a legend icon from the layer paint specified in the system. For raster returns mdi image icon.
 * This follows the mapbox style spec.
 * Also used to generates an icon from the map feature OnClick which presents the paint in a {r: , g: , b: } format.
 */
import React, { CSSProperties } from "react";

import classes from "./Square.module.css";

export interface OwnProps {
    color: string;
    borderColor?: string;
    width?: number;
    borderWidth?: number;
}

const Square: React.FC<OwnProps> = ({
    color,
    borderColor = color,
    borderWidth = 1,
    width = 16,
}: OwnProps) => {
    let style: CSSProperties = {
        height: width,
        width: width,
        backgroundColor: color,
        border: borderWidth + "px solid " + borderColor,
        borderRadius: "var(--border-radius-xsm)"
    };

    return <div className={classes.Square} style={style} />;
};

export default Square;
