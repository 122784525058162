import Airport from "./Airport.png";
import Dam from "./Dam.png";
import FireStation from "./FireStation.png";
import Medical from "./Medical.png";
import OilRefinery from "./OilRefinery.png";
import PoliceStation from "./PoliceStation.png";
import Port from "./Port.png";
import PowerPlant from "./PowerPlant.png";
import RailwayStation from "./RailwayStation.png";
import WaterTreatmentPlant from "./WaterTreatmentPlant.png";
import DroneImageryMarker from "./camera_marker.png";

const MapIcons = {
    Airport,
    Dam,
    "Fire Station": FireStation,
    Medical,
    "Oil Refinery": OilRefinery,
    "Police Station": PoliceStation,
    Port,
    "Power Plant": PowerPlant,
    "Water Treatment Plant": WaterTreatmentPlant,
    "Railway Station": RailwayStation,
    "Drone Imagery Marker": DroneImageryMarker,
};

export default MapIcons;
