import React, { ChangeEvent, DragEvent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Icon from "@mdi/react";
import csv2geojson from "csv2geojson";
import { mdiCheck, mdiClose, mdiFolderOutline } from "@mdi/js";
import cx from "classnames";

import Modal from "components/_Library/Modal/Modal";
import { addCustomLayer } from "store/report/reportActions";
import { SystemActionTypes } from "store/system/systemTypes";
import {
    ErrorList,
    ErrorPackage,
} from "components/_Library/ErrorList/ErrorList";

import classes from "./DragAndDrop.module.css";

export interface OwnProps {
    setDragAndDropState: (active: boolean) => void;
}
interface DispatchProps {
    addCustomLayer: typeof addCustomLayer;
}

interface StateProps {}

export type DragAndDropProps = OwnProps & DispatchProps & StateProps;

interface DragAndDropState {
    errors: ErrorPackage[];
    geojsonData: GeoJSON.FeatureCollection | null;
    layerName: string;
    layerType: any;
    layerColor: any;
    types: { id: string; typeName: string; disabled: boolean }[];
    swatches: { color: string; selected: boolean }[];
}

class DragAndDrop extends React.Component<DragAndDropProps, DragAndDropState> {
    state: DragAndDropState = {
        errors: [],
        geojsonData: null,
        layerName: "",
        layerType: "circle",
        layerColor: "blue",
        swatches: [
            { color: "#880015", selected: true },
            { color: "#ED1C24", selected: false },
            { color: "#FF7F27", selected: false },
            { color: "#FFF200", selected: false },
            { color: "#22B14C", selected: false },
            { color: "#00A2E8", selected: false },
            { color: "#FFC90E", selected: false },
            { color: "#3F48CC", selected: false },
            { color: "#C8BFE7", selected: false },
        ],
        types: [
            { id: "circle", typeName: "Point", disabled: false },
            { id: "line", typeName: "Line", disabled: false },
            { id: "fill", typeName: "Polygon", disabled: false },
        ],
    };

    closeModal = () => {
        this.props.setDragAndDropState(false);
    };

    stopEventPropagation(event: DragEvent | ChangeEvent) {
        event.preventDefault();
        event.stopPropagation();
    }

    processFileInputData = (event: ChangeEvent<HTMLInputElement>) => {
        this.stopEventPropagation(event);
        if (event.target.files?.length) {
            this.processFile(event.target.files);
        }
    };
    processDroppedData = (event: DragEvent) => {
        this.stopEventPropagation(event);
        if (event.dataTransfer.files.length) {
            this.processFile(event.dataTransfer.files);
        }
    };
    processFile = (files: FileList) => {
        Array.from(files).forEach((file: File) => {
            let allowedExtension = file.name.match(/.+\.(csv|geojson)(?!.)/);
            if (allowedExtension) {
                this.readFile(file, allowedExtension[1]);
            } else {
                let errors = [...this.state.errors];
                errors.push({
                    message: "Make sure your file is a GeoJSON or CSV file",
                    level: "error",
                    id: file.name,
                });
                this.setState({
                    errors: errors,
                });
            }
        });
    };
    disableTypeButtons(types: any) {
        let stateTypes = [...this.state.types];
        stateTypes.forEach((stateType) => {
            types.forEach((type: any) => {
                if (stateType.id === type) {
                    stateType.disabled = true;
                }
            });
        });
        this.setState({
            types: stateTypes,
        });
    }
    readFile(file: File, fileType: string) {
        let reader = new FileReader();

        reader.onabort = () => {
            console.error("ABORT FILE LOAD");
        };

        reader.onload = () => {
            if (fileType === "geojson") {
                this.setState({
                    geojsonData: JSON.parse(reader.result as string),
                });
            } else {
                this.disableTypeButtons(["line", "fill"]);
                csv2geojson.csv2geojson(
                    reader.result,
                    (err: any, data: any) => {
                        this.setState({
                            geojsonData: data,
                        });
                    },
                );
            }
        };
        reader.readAsText(file);
    }

    handleLayerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            layerName: event.target.value ?? "",
        });
    };

    addCustomLayer = () => {
        if (this.state.layerName !== "") {
            this.props.addCustomLayer({
                layerName: this.state.layerName,
                geojsonData: this.state.geojsonData ?? {
                    type: "FeatureCollection",
                    features: [],
                },
                layerType: this.state.layerType,
                layerColor: this.state.layerColor,
            });
            this.closeModal();
        } else {
            let layersError: ErrorPackage = {
                message: "Give your layer a name",
                level: "error",
                id: this.state.layerName,
            };
            let errors = [...this.state.errors];
            errors.push(layersError);
            this.setState({
                errors: errors,
            });
        }
    };

    renderColorSwatches() {
        return this.state.swatches.map((swatch) => {
            let selected = this.state.layerColor === swatch.color;
            return (
                <div
                    key={swatch.color}
                    className={cx(classes.ColorSwatches, {
                        [classes.ColorSelected]: selected,
                    })}
                    style={{ backgroundColor: swatch.color }}
                    onClick={() => this.handleLayerSwatchChange(swatch.color)}
                >
                    {" "}
                </div>
            );
        });
    }

    renderTypeButtons() {
        return this.state.types.map((type) => {
            return (
                <label key={type.id} style={{ cursor: "pointer" }}>
                    <input
                        style={{ cursor: "pointer" }}
                        key={type.id}
                        type="radio"
                        value={type.id}
                        checked={this.state.layerType === type.id}
                        onChange={(e: any) => this.handleLayerTypeChange(e)}
                        disabled={type.disabled}
                    />
                    <span>{type.typeName}</span>
                </label>
            );
        });
    }

    handleLayerTypeChange(e: any) {
        this.setState({
            layerType: e.target.value,
        });
    }

    handleLayerSwatchChange(color: any) {
        this.setState({
            layerColor: color,
        });
    }

    render() {
        return (
            <Modal closeModal={this.closeModal} title={"Overlay your data"}>
                <div
                    className={classes.Container}
                    id={"tourid_DragAndDropContainer"}
                >
                    <p>
                        Save your portfolio data as a .csv file, with each row
                        assigned a latitude and longitude.
                    </p>
                    <div
                        id={"tourid_DragAndDropDropZone"}
                        className={classes.DropZone}
                        onDrop={this.processDroppedData}
                        onDragOver={this.stopEventPropagation}
                        onDragEnter={this.stopEventPropagation}
                    >
                        {this.state.geojsonData ? (
                            <>
                                <div className={classes.SuccessAlert}>
                                    <Icon size={3} path={mdiCheck} />
                                    <p>Success</p>
                                </div>
                                <div className={classes.LayerNameInput}>
                                    <p className={classes.LayerNameLabel}>
                                        Name your layer:
                                    </p>
                                    <input
                                        className={classes.LayerNameInput}
                                        type={"text"}
                                        onChange={this.handleLayerNameChange}
                                        placeholder={"My Custom Layer"}
                                    />
                                    <p className={classes.LayerTypeLabel}>
                                        Choose your layer type and colour
                                    </p>
                                    <div className={classes.RadioButtonWrapper}>
                                        {this.renderTypeButtons()}
                                    </div>
                                    <div
                                        className={classes.RadioSwatchesWrapper}
                                    >
                                        {this.renderColorSwatches()}
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div onClick={this.addCustomLayer}>
                                            OK
                                        </div>
                                        <div onClick={this.closeModal}>
                                            CANCEL
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {this.state.errors.filter(
                                    (error) => error.level === "error",
                                ).length !== 0 ? (
                                    <>
                                        <div className={classes.ErrorAlert}>
                                            <Icon path={mdiClose} />
                                            <p>Error</p>
                                        </div>
                                        <p className={classes.DropInstruction}>
                                            Fix the issues below and try again.
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <p className={classes.DropInstruction}>
                                            Then, simply drag and drop the file
                                            here
                                        </p>
                                        <p className={classes.FileInstruction}>
                                            Or browse for the file on your
                                            device
                                        </p>
                                    </>
                                )}

                                <label
                                    className={classes.FileInput}
                                    id={"tourid_DragAndDropFileInput"}
                                >
                                    <Icon path={mdiFolderOutline} />
                                    <span>Select a file</span>
                                    <input
                                        type={"file"}
                                        onChange={this.processFileInputData}
                                    />
                                </label>
                            </>
                        )}
                    </div>
                    {this.state.errors.filter(
                        (error) => error.level === "error",
                    ).length !== 0 && (
                        <div className={classes.ErrorList}>
                            <ErrorList errors={this.state.errors} />
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => {
    return {
        addCustomLayer: bindActionCreators(addCustomLayer, dispatch)
    };
};

export default connect(null, mapDispatchToProps)(DragAndDrop);
