import { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { AxiosRequestConfig } from "axios";
import { useApiQuery } from "hooks/useAPI";
import { AccessInfo } from "types/auth";

/**
 * Custom hook for fetching the current user's access information.
 *
 * @param {Omit<UseQueryOptions<AccessInfo, Error>, "queryKey" | "queryFn"> & { config?: AxiosRequestConfig }} [options]
 * Override default options or add new ones.
 *
 * @returns {UseQueryResult<AccessInfo, Error>} A query result object for fetching user access information.
 *
 * @example
 * const { data: accessInfo, isLoading, error } = useUserMeAccessQuery({
 *   staleTime: 5 * 60 * 1000, // 5 minutes
 *   onSuccess: (data) => {
 *     console.log('Fetched access info:', data);
 *   },
 *   config: {
 *     headers: {
 *       'Custom-Header': 'value'
 *     }
 *   }
 * });
 */
export function useUserMeAccessQuery(
    options?: Omit<UseQueryOptions<AccessInfo, Error>, "queryKey" | "queryFn"> & {
        config?: AxiosRequestConfig;
    }
): UseQueryResult<AccessInfo, Error> {
    return useApiQuery<AccessInfo>(
        "/auth/users/me/access",
        ["userMeAccess"],
        options
    );
}