import { ViewportProps } from "react-map-gl";
import * as MapboxGL from "mapbox-gl";
import {
    BackgroundPaint,
    CirclePaint,
    Expression,
    FillExtrusionPaint,
    FillPaint,
    HeatmapPaint,
    HillshadePaint,
    LinePaint,
    RasterPaint,
    SymbolPaint,
} from "mapbox-gl";

import { LayerType } from "types/mapbox-types";
import {
    LayerTimelineConfig,
    ConfigMenuGroup,
    ConfigMenuLayer,
    EventType,
} from "../system/systemTypes";
import { AnyAction } from "redux";
import { ThemeOption } from "store/system/systemTypes";
import { History } from "history";
import { ReportDownloadSchema } from "crud/reportDownloadsCRUD";
import { ReportMapSchemaBase } from "crud/reportMapsCRUD";
import { ReportTextSchema } from "crud/reportTextCRUD";

// ACTION TYPE CONSTANTS
export const SET_LAYERS_CONFIG = "SET_LAYERS_CONFIG";
export const SET_MAP_MARKER_LOCATION = "SET_MAP_MARKER_LOCATION";
export const SET_VIEWPORT = "SET_VIEWPORT";
export const SET_CENTER = "SET_CENTER";
export const SET_MOUSE_POSITION = "SET_MOUSE_POSITION";
export const SET_LAYER_VISIBILITY = "SET_LAYER_VISIBILITY";
export const SET_GROUP_VISIBILITY = "SET_GROUP_VISIBILITY";
export const SET_MAP_TYPE = "SET_MAP_TYPE";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const TOGGLE_LOCATION_LABELS = "TOGGLE_LOCATION_LABELS";
export const SET_CLICKED_FEATURE_PROPERTIES = "SET_CLICKED_FEATURE_PROPERTIES";
export const SET_HIGHLIGHTED_LAYER = "SET_SELECTED_LAYER";
export const SET_LEGEND_POPUP = "SET_LEGEND_POPUP";
export const SET_BASEMAP = "SET_BASEMAP";
export const SET_INTERACTION_MODE = "SET_INTERACTION_MODE";
export const ADD_CUSTOM_LAYER = "ADD_CUSTOM_LAYER";
export const REMOVE_CUSTOM_LAYER = "REMOVE_CUSTOM_LAYER";
export const REPLACE_INSIGHTS_LAYER_DATA = "REPLACE_INSIGHTS_LAYER_DATA";
export const SET_LAYER_VIEW = "SET_LAYER_VIEW";
export const SET_LAYER_FILTER = "SET_LAYER_FILTER";
export const SET_EVENT_TYPE = "SET_EVENT_TYPE";
export const SET_CURRENT_DATE = "SET_CURRENT_DATE";
export const SET_DASHBOARD_VIEW = "SET_DASHBOARD_VIEW";
export const SET_REPORT_IS_PREVIEW = "SET_REPORT_IS_PREVIEW";

// PAYLOAD TYPES
export type SetLayersConfigPayload = LayersConfig;

export interface SetLayerVisibilityPayload {
    sourceName: string;
    layerName: string;
    visibility: Visibility;
}

export interface SetGroupVisibilityPayload {
    groupId: string;
    visibility: Visibility;
}

export interface SetDashboardViewPayload {
    view: DashboardView;
    history?: History;
}

export type Visibility = "visible" | "none" | undefined;

export interface SetHighlightedLayerPayload {
    layerName: string;
    sourceName: string;
}

export interface SetLegendPopupPayload {
    layerName: string;
    sourceName: string;
}

export interface SetBasemapPayload {
    mapIndex: number;
    basemap: BasemapType;
}

export interface AddCustomLayerPayload {
    geojsonData: GeoJSON.FeatureCollection;
    layerName: string;
    layerType: LayerType;
    layerColor: string | Expression;
    layerStroke?: string | Expression;
    sourceName?: string;
    groupName?: string;
}

export interface AddInsightsLayerPayload {
    geojsonData: GeoJSON.FeatureCollection;
    layerName: string;
    layerColor: string | Expression;
    layerStroke?: string | Expression;
    sourceName?: string;
    groupName: string;
    clusterColor: string;
    clusterOutline?: string;
    visibility: "visible" | "none";
}

export interface toggleDamageLabelFilterPayload {
    filter: string;
}

export interface ToggleMarkerPayload {
    marker: MarkerConfig | null;
}

export interface RemoveCustomLayerPayload {
    layerName: string;
}

export interface ReplaceInsightsLayerDataPayload {
    sourceName: string;
    data: GeoJSON.FeatureCollection;
}

export interface SetLayerViewPayload {
    sourceName: string;
    layerName: string;
    viewOn: "left" | "both" | "right";
}

export interface SetLayerFilterPayload {
    layerFilter: LayerFilter;
    sourceName: string;
}

export type SetEventTypePayload = EventType;
export type SetMapMarkerLocationPayload = null | [number, number];
export type SetInsightsTypePayload = InsightsType;

// ACTION TYPES
export interface SetLayersConfigAction extends AnyAction {
    type: typeof SET_LAYERS_CONFIG;
    payload: SetLayersConfigPayload;
}
export interface SetCurrentDateAction extends AnyAction {
    type: typeof SET_CURRENT_DATE;
    payload: number;
}

export interface SetDashboardViewAction extends AnyAction {
    type: typeof SET_DASHBOARD_VIEW;
    payload: SetDashboardViewPayload;
}
export interface SetEventTypeAction {
    type: typeof SET_EVENT_TYPE;
    payload: SetEventTypePayload;
}

export interface SetMapMarkerLocationAction {
    type: typeof SET_MAP_MARKER_LOCATION;
    payload: SetMapMarkerLocationPayload;
}

export interface AddCustomLayerAction extends AnyAction {
    type: typeof ADD_CUSTOM_LAYER;
    payload: AddCustomLayerPayload;
}

export interface RemoveCustomLayerAction extends AnyAction {
    type: typeof REMOVE_CUSTOM_LAYER;
    payload: RemoveCustomLayerPayload;
}

export interface ReplaceInsightsLayerDataAction extends AnyAction {
    type: typeof REPLACE_INSIGHTS_LAYER_DATA;
    payload: ReplaceInsightsLayerDataPayload;
}

export interface SetViewportAction extends AnyAction {
    type: typeof SET_VIEWPORT;
    payload: ViewportProps;
}

export type SetCenterPayload = MapCenter & {
    duration?: number;
};

export interface SetCenterAction extends AnyAction {
    type: typeof SET_CENTER;
    payload: SetCenterPayload;
}

export interface SetMousePositionAction extends AnyAction {
    type: typeof SET_MOUSE_POSITION;
    payload: [number, number];
}

export interface SetLayerVisibilityAction extends AnyAction {
    type: typeof SET_LAYER_VISIBILITY;
    payload: SetLayerVisibilityPayload;
}

export interface SetGroupVisibilityAction extends AnyAction {
    type: typeof SET_GROUP_VISIBILITY;
    payload: SetGroupVisibilityPayload;
}

export interface SetMapTypeAction extends AnyAction {
    type: typeof SET_MAP_TYPE;
    payload: MapType;
}

export interface SetBasemapAction extends AnyAction {
    type: typeof SET_BASEMAP;
    payload: SetBasemapPayload;
}

export interface SetActiveTabAction extends AnyAction {
    type: typeof SET_ACTIVE_TAB;
    payload: TabType;
}

export interface ToggleMenuAction extends AnyAction {
    type: typeof TOGGLE_MENU;
}

export interface ToggleLocationLabelsAction extends AnyAction {
    type: typeof TOGGLE_LOCATION_LABELS;
    payload: boolean;
}

export interface SetClickedFeaturePropertiesAction extends AnyAction {
    type: typeof SET_CLICKED_FEATURE_PROPERTIES;
    payload: { [key: string]: any[] };
}

export interface SetSelectedLayerAction extends AnyAction {
    type: typeof SET_HIGHLIGHTED_LAYER;
    payload: SetHighlightedLayerPayload | null;
}

export interface SetLegendPopupAction extends AnyAction {
    type: typeof SET_LEGEND_POPUP;
    payload: SetLegendPopupPayload | null;
}

export interface SetInteractionModeAction extends AnyAction {
    type: typeof SET_INTERACTION_MODE;
    payload: InteractionModeType;
}

export interface SetLayerViewAction extends AnyAction {
    type: typeof SET_LAYER_VIEW;
    payload: SetLayerViewPayload;
}

export interface SetLayerFilterAction {
    type: typeof SET_LAYER_FILTER;
    payload: SetLayerFilterPayload;
}

export interface SetReportIsPreviewAction extends AnyAction {
    type: typeof SET_REPORT_IS_PREVIEW;
    payload: boolean;
}

export type MapActionTypes =
    | SetLayersConfigAction
    | SetViewportAction
    | SetLayerVisibilityAction
    | SetGroupVisibilityAction
    | SetMapTypeAction
    | SetActiveTabAction
    | ToggleMenuAction
    | SetClickedFeaturePropertiesAction
    | SetSelectedLayerAction
    | SetLegendPopupAction
    | SetCenterAction
    | SetMousePositionAction
    | SetBasemapAction
    | SetInteractionModeAction
    | AddCustomLayerAction
    | RemoveCustomLayerAction
    | ReplaceInsightsLayerDataAction
    | SetLayerViewAction
    | SetLayerFilterAction
    | SetEventTypeAction
    | SetMapMarkerLocationAction
    | ToggleLocationLabelsAction
    | SetCurrentDateAction
    | SetDashboardViewAction
    | SetReportIsPreviewAction;

// REDUCER TYPES
export interface ReportState {
    viewport: ViewportProps;
    layersConfig: LayersConfig;
    reportTexts: ReportTextSchema[];
    reportDownloads: ReportDownloadSchema[];
    menuConfig: MenuConfig;
    highlightedLayer: { layerName: string; sourceName: string } | null;
    legendPopup: { layerName: string; sourceName: string } | null;
    clickedFeatureProperties: { [key: string]: any[] };
    mousePosition: number[];
    interactiveLayerIds: string[];
    basemaps: [BasemapType, BasemapType];
    basemapOptions: { [key in BasemapType]: string };
    interactionMode: InteractionModeType;
    eventType: EventType;
    mapMarkerLocation: null | [number, number];
    locationLabels: boolean;
    mapboxToken: string;
    dashboardView: DashboardView;
    layerFilters: LayerFilters;
    timelineConfig?: ReportTimelineSchema;
    currentTimelineDate?: number;
    isPreview: boolean;
}

export interface LayerInfo {
    name: string;
    type: LayerType;
    source: string;
    paint: MapboxGL.AnyPaint;
    panoramaKey: string; //empty string denotes no panorama
}

export interface LayersConfig {
    mapType: MapType;
    sources: { [key: string]: ConfigSource };
    menuIndex: (ConfigMenuGroup | ConfigMenuLayer)[];
}

export interface InsightsMenuLayer extends ConfigMenuLayer {
    paint:
        | BackgroundPaint
        | FillPaint
        | FillExtrusionPaint
        | LinePaint
        | SymbolPaint
        | RasterPaint
        | CirclePaint
        | HeatmapPaint
        | HillshadePaint;
    complexPaintProperties: Array<keyof MapboxGL.AnyPaint>;
    layerType: LayerType;
    groupName: string;
    layout: MapboxGL.AnyLayout;
    viewOn: "left" | "right" | "both";
    clusterId: string;
    clusterColor: string;
    clusterOutline?: string;
    dataConfig?: DataConfig;
}

export interface MarkerConfig {
    lnglat?: number[];
    cluster?: { [key: string]: number };
    clusterId: number;
}

export type BasemapType = ThemeOption | "satellite";

export interface MenuConfig {
    activeTab: TabType;
    menuHidden: boolean;
}

export interface LayerFilters {
    [sourceId: string]: LayerFilter;
}

export interface LayerFilter {
    identifier: string | null;
    values: string[];
}

export interface ConfigSource {
    filteredIds?: number[] | null;
    type: string;
    tiles?: string[];
    url?: string;
    dataLayerId?: string;
    data?: GeoJSON.FeatureCollection;
    dataConfig?: DataConfig;
    source?: string;
    layerName?: string;
    "source-layer"?: string;
    layerType: LayerType;
    layout: MapboxGL.AnyLayout;
    paint:
        | BackgroundPaint
        | FillPaint
        | FillExtrusionPaint
        | LinePaint
        | SymbolPaint
        | RasterPaint
        | CirclePaint
        | HeatmapPaint
        | HillshadePaint;
    complexPaintProperties: Array<keyof MapboxGL.AnyPaint>;
    actions?: LayerActions;
    interactive?: boolean;
    viewOn: "left" | "right" | "both";
    customLayer?: boolean;
    tileSize?: number;
    tier: string;
    beta: boolean;
    layerTierTypeId: string;
    downloadsAvailable: boolean;
}

export interface ConfigItem {
    groupName: string | null;
    sources: { [key: string]: ConfigSource };
}

export interface ConfigSources {
    [key: string]: ConfigSource;
}

export interface MapCenter {
    latitude: number;
    longitude: number;
    zoom: number;
}

export interface ZoomToBBox {
    bbox: [number, number, number, number];
}

export type LayerActions = {
    zoomTo?: ZoomToBBox;
};

export interface DataConfig {
    timeline: LayerTimelineConfig;
}

export interface FeatureDataConfig {
    localProperty: string;
    url: string;
}

export interface DatasetLinkConfig {
    name: string;
    join: [string, string];
}

export type MapType = "single" | "dual" | "compare";
export type SetMapTypePayload = MapType;

export type MapMenuTabType = "Layer" | "Info" | "Geocoder";
export type MainMenuTabType = "Settings" | "Tutorials";
export type CookieTabType =
    | "About"
    | "Necessary"
    | "Analytics"
    | "Functionality";

export type TabType = MainMenuTabType | MapMenuTabType | CookieTabType;
export type SetActiveTabPayload = TabType;
export type InsightsType = "noInsights" | "noAffectedProperties" | null; //This will remain null if Insights have been loaded - we only set this if Insights aren't available

export type InteractionModeType =
    | "standard"
    | "measure-distance"
    | "measure-area";
export type DashboardView = (typeof DashboardViews)[number];
export const DashboardViews = [
    "report",
    "insights",
    "policy_insights",
] as const;
export interface ReportTimelineSchema {
    id: string;
    report_id: string;
    max_datetime: string;
    min_datetime: string;
    user_seconds_per_timeline_step: number;
    timeline_step_measure: string;
    timeline_step_value: number;
    created_at: string;
}

export type ReportTimelineSchemaBase = Omit<
    ReportTimelineSchema,
    "id" | "created_at"
>;
export interface ReportComponents {
    reportId?: string;
    layersConfig: LayersConfig;
    reportMap: ReportMapSchemaBase;
    reportTexts: ReportTextSchema[];
    reportDownloads: ReportDownloadSchema[];
    reportTimeline?: ReportTimelineSchemaBase;
}
