import React from "react";
import GEOLogo from "/src/assets/images/logos/geo_logo_no_butterfly.svg";
import Button from "components/_Library/Button/Button";
import classes from "./ErrorPane.module.css";
import cx from "classnames";

interface ErrorPaneProps {
    title: string;
    textLineOne: string;
    textLineTwo?: string;
    image: any;
    largeImage?: boolean;
}

const ErrorPane: React.FC<ErrorPaneProps> = ({
    title,
    textLineOne,
    textLineTwo,
    image,
    largeImage = false,
}) => {
    return (
        <div className={classes.ErrorPane}>
            <div className={classes.ErrorPaneDividerLeft}>
                <div className={classes.LogoWrapper}>
                    <img
                        alt={"Geo Logo"}
                        className={classes.GEOLogo}
                        src={GEOLogo}
                    />
                </div>
                <h1 className={classes.TitleText}>{title}</h1>
                <p className={classes.LineHeight}>
                    {textLineOne}
                    <br />
                    {textLineTwo}{" "}
                    <a
                        className={classes.LinkText}
                        href="mailto:mail@mckenzieintelligence.com"
                    >
                        contact support
                    </a>
                    .
                </p>
                <Button
                    size={{
                        width: "40%",
                        minWidth: "15rem",
                        minHeight: "3.6rem",
                    }}
                    onClick={() => (window.location.href = "/")}
                >
                    Go to homepage
                </Button>
            </div>
            <div className={classes.ErrorPaneDividerRight}>
                <img
                    alt={"Geo Logo"}
                    className={cx(classes.ErrorLogo, {
                        [classes.LargeImage]: largeImage,
                    })}
                    src={image}
                />
            </div>
        </div>
    );
};

export default ErrorPane;
