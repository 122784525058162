import { useState } from 'react';
import { 
    PaginationState, 
    ColumnSort, 
    ColumnFilter, 
    ColumnPinningState, 
    VisibilityState,
    ColumnFiltersState,
    SortingState,
    Updater
} from '@tanstack/react-table';
import { MRT_ColumnFilterFnsState } from 'mantine-react-table';
import { useAnalytics } from 'hooks/useAnalytics/useAnalytics';

interface UseTableStateProps {
    source: "insights" | "map";
    type: "location" | "policy"
    initialAssessmentFilters?: {
        exposure: string[];
        claims: string[];
    };
    initialPinnedColumns?: string[];
}

interface TableState {
    pagination: PaginationState;
    sorting: ColumnSort[];
    globalFilter: string;
    columnFilters: ColumnFilter[];
    columnFilterFns: MRT_ColumnFilterFnsState | undefined;
    columnPinning: ColumnPinningState;
    columnVisibility: VisibilityState;
}

interface TableEventHandlers {
    onSortingChange: (updater: Updater<SortingState>) => void;
    onColumnFiltersChange: (updater: Updater<ColumnFiltersState>) => void;
    onColumnFilterFnsChange: (updater: Updater<MRT_ColumnFilterFnsState>) => void;
    onGlobalFilterChange: (updater: Updater<string | undefined>) => void;
    onColumnPinningChange: (updater: Updater<ColumnPinningState>) => void;
    onColumnVisibilityChange: (updater: Updater<VisibilityState>) => void;
    onPaginationChange: (updater: Updater<PaginationState>) => void;
}

export const useTableStateWithTracking = ({
    source,
    type,
    initialAssessmentFilters = { exposure: [], claims: [] },
    initialPinnedColumns = ["estimated_exposure"]
}: UseTableStateProps) => {
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const [paginationState, setPaginationState] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 12,
    });
    const [sorting, setSorting] = useState<ColumnSort[]>([]);
    const [globalFilter, setGlobalFilter] = useState<string>("");
    const [columnFilters, setColumnFilters] = useState<ColumnFilter[]>([
        {
            id: "Exposure Layer Assessment",
            value: initialAssessmentFilters.exposure.length
                ? initialAssessmentFilters.exposure
                : [""],
        },
        {
            id: "Claims Layer Assessment",
            value: initialAssessmentFilters.claims.length
                ? initialAssessmentFilters.claims
                : [""],
        },
    ]);
    const [columnFilterFns, setColumnFilterFns] = useState<MRT_ColumnFilterFnsState>();
    const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
        left: [], 
        right: initialPinnedColumns
    });
    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

    const getValueFromUpdater = (updater: Updater<any>): any => {
        return typeof updater === 'function'
            ? updater(sorting)
            : updater;
    }

    const eventHandlers: TableEventHandlers = {
        onSortingChange: (updater) => {
            const newValue: ColumnSort[] = getValueFromUpdater(updater);
            const newSorted = newValue.find((col) => !sorting.find((oldCol) => oldCol.id === col.id));
            setSorting(newValue);
            if (newSorted) {
                trackUserEventWithCurrentEvent({
                    name: "table_sorted",
                    payload: {
                        source,
                        type,
                        column: newSorted.id,
                        mode: newSorted.desc ? "desc" : "asc",
                    },
                });
            }
        },

        onColumnFiltersChange: (updater) => {
            const newValue: ColumnFiltersState = getValueFromUpdater(updater);
            setColumnFilters(newValue);
            if (newValue?.length) {
                trackUserEventWithCurrentEvent({
                    name: "table_column_filtered",
                    payload: {
                        source,
                        type,
                        column: newValue[0].id,
                    }
                });
            }
        },

        onColumnFilterFnsChange: (updater) => {
            const newValue: MRT_ColumnFilterFnsState = getValueFromUpdater(updater);
            setColumnFilterFns(newValue);
            if (Object.keys(newValue).length) {
                trackUserEventWithCurrentEvent({
                    name: "table_filter_mode_changed",
                    payload: {
                        source,
                        type,
                        column: Object.keys(newValue)[0],
                        mode: Object.values(newValue)[0],
                    }
                });
            }
        },

        onGlobalFilterChange: (updater) => {
            const newValue = getValueFromUpdater(updater);
            setGlobalFilter(newValue!);
            if (newValue) {
                trackUserEventWithCurrentEvent({
                    name: "table_searched",
                    payload: {
                        source,
                        type,
                    }
                });
            }
        },

        onColumnPinningChange: (updater) => {
            const newValue: ColumnPinningState = getValueFromUpdater(updater);
            const newLeft = newValue["left"]?.find((col) => !columnPinning["left"]?.find((oldCol) => oldCol === col));
            const newRight = newValue["right"]?.find((col) => !columnPinning["right"]?.find((oldCol) => oldCol === col));
            const oldLeft = columnPinning["left"]?.find((col) => !newValue["left"]?.find((oldCol) => oldCol === col));
            const oldRight = columnPinning["right"]?.find((col) => !newValue["right"]?.find((oldCol) => oldCol === col));
            
            
            if (oldLeft || oldRight) {
                setColumnPinning(newValue);
                trackUserEventWithCurrentEvent({
                    name: "table_column_unpin",
                    payload: {
                        source,
                        type,
                        column: (oldLeft || oldRight)!
                    }
                });
            }
            
            if (newLeft || newRight) {
                if (newLeft) {
                    setColumnPinning({
                        ...columnPinning,
                        left: [...columnPinning!.left!, newLeft]
                    })
                } else if (newRight) {  
                    setColumnPinning({
                        ...columnPinning,
                        right: [...columnPinning!.right!, newRight]
                    })
                }
                trackUserEventWithCurrentEvent({
                    name: "table_column_pin",
                    payload: {
                        source,
                        type,
                        column: (newLeft || newRight)!
                    }
                });
            }
        },

        onColumnVisibilityChange: (updater) => {
            const newValue: VisibilityState = getValueFromUpdater(updater);
            setColumnVisibility(updater);
            if (Object.keys(newValue).length) {
                const col = Object.keys(newValue)[0];
                const value = newValue[col];
                trackUserEventWithCurrentEvent({
                    name: Boolean(value) ? "table_column_unhide" : "table_column_hide",
                    payload: {
                        source,
                        type,
                        column: col,
                    }
                });
            }
        },

        onPaginationChange: (updater) => {
            const newValue: PaginationState = getValueFromUpdater(updater);
            const { pageIndex } = newValue;
            setPaginationState(updater);
            trackUserEventWithCurrentEvent({
                name: "table_pagination_changed",
                payload: {
                    source,
                    type,
                    page: pageIndex + 1,
                }
            });
        }
    };

    const state: TableState = {
        pagination: paginationState,
        sorting,
        globalFilter,
        columnFilters,
        columnFilterFns,
        columnPinning,
        columnVisibility
    };

    return { state, eventHandlers, setPaginationState };
};
