import React, { Component } from "react";
import cx from "classnames";

import classes from "./Toggle.module.css";

interface OwnProps {
    heightRem?: string;
    active: boolean;
    onClick: () => void;
}

type ToggleProps = OwnProps;
interface ToggleState {}

class Toggle extends Component<ToggleProps, ToggleState> {
    render() {
        return (
            <div
                className={cx(classes.Container, {
                    [classes.Active]: this.props.active,
                })}
                onClick={this.props.onClick}
                style={
                    {
                        "--toggle-dimension": this.props.heightRem ?? "2rem",
                    } as React.CSSProperties
                }
            >
                <div className={classes.Toggle} />
            </div>
        );
    }
}

export default Toggle;
