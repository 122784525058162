import { RefState, RefActionTypes, SetRefAction, SET_REF } from "./refTypes";
import { Reducer } from "redux";

const initState: RefState = {
    geocoderContainerRef: { current: null },
    modalRef: { current: null },
    mapRef: { current: null },
};

export const refReducer: Reducer<RefState, RefActionTypes> = (
    state = initState,
    action,
): RefState => {
    switch (action.type) {
        case SET_REF:
            return setRef(state, action);
        default:
            return state;
    }
};

const setRef = (state = initState, action: SetRefAction): RefState => {
    return {
        ...state,
        [action.payload.refName]: action.payload.ref
            ? action.payload.ref
            : { current: null },
    };
};
