/**
 * Generates a ref to hold the map's geocoder search box.
 */

import React, { Component } from "react";
import { Dispatch } from "redux";

import { connect } from "react-redux";

import { RootState } from "store/store";
import { setRef } from "store/ref/refActions";
import { RefActionTypes, SetRefPayload } from "store/ref/refTypes";

import classes from "./GeocoderTab.module.css";
import { getStoreAtNamespaceKey } from "../../../../../../../../store/storeSelectors";

interface OwnProps {}
interface StateProps {}
interface DispatchProps {
    SetRef: typeof setRef;
}
type GeocoderTabProps = OwnProps & StateProps & DispatchProps;

interface GeocoderTabState {}

class GeocoderTab extends Component<GeocoderTabProps, GeocoderTabState> {
    private geocoderContainerRef: React.RefObject<HTMLDivElement>;

    constructor(props: GeocoderTabProps) {
        super(props);
        this.geocoderContainerRef = React.createRef();
    }

    componentDidMount() {
        this.props.SetRef({
            refName: "geocoderContainerRef",
            ref: this.geocoderContainerRef,
        });
    }

    render() {
        return (
            <>
                <div
                    className={classes.Container}
                    ref={this.geocoderContainerRef}
                ></div>
                
                <div id={"search-history-root"} />
            </>
        );
    }
}

const mapStateToProps = (state: RootState): StateProps => {
    return {
        refs: getStoreAtNamespaceKey(state, "ref"),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RefActionTypes>) => ({
    SetRef: (refPayload: SetRefPayload) => dispatch(setRef(refPayload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeocoderTab);
