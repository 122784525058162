import { Select, SelectItem } from "@mantine/core";
import React, { useEffect, useRef, useState } from "react";
import _ from "lodash";
import classes from "./PrettySelect.module.css";
import { IconX } from "@tabler/icons-react";

type Props = {
    onChange: (value: string) => void;
    onClick?: () => void;
    data: string[];
    defaultValue: string;
    placeholder: string;
    inputClassName?: string;
    searchable?: boolean;
};

const PrettySelect = ({
    onChange,
    onClick,
    data,
    defaultValue,
    placeholder,
    searchable,
}: Props) => {
    /**
     * Searchable dropdown is a dropdown, select like element with double chevron
     * On submission or selection of an option, chevron changes
     * and onSubmit is triggered
     * If closed or clicked out of without submit, revert back to old value
     * If the input is searchable, for now it is assumed that we also have
     * a clear state to clear the input
     * Upon change, onChange is triggered which will unfocus the element
     */
    const [value, setValue] = useState(defaultValue);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        setValue(defaultValue);
        // When value resets, ensure the input does not have focus
        inputRef.current!.blur();
    }, [defaultValue]);

    const processData = (data: string[]): SelectItem[] => {
        /**
         * Put the default value at the top
         * and sort the rest with the default
         * value at the top
         */
        if (!data.length) return [];
        const processedData = _.uniq(data).map((value) => {
            return {
                label: value,
                value: value,
                selected: value === defaultValue,
            };
        });
        processedData.sort((a, b) => {
            if (a.selected) return -1;
            if (b.selected) return 1;
            return 0;
        });
        return processedData;
    };
    const processedData = processData(data);
    return (
        <div style={{ maxHeight: "2.25rem"}}>
            <Select
                ref={inputRef}
                // Make the data unique, and put the
                // default value at the top
                value={value}
                data={processedData}
                placeholder={placeholder}
                onChange={(value: string) => {
                    setValue(value);
                    onChange(value);
                }}
                searchValue={searchValue}
                onSearchChange={(value: string) => {
                    setSearchValue(value);
                }}
                searchable={searchable ?? false}
                transitionProps={{
                    transition: "pop",
                    duration: 150,
                    timingFunction: "ease",
                }}
                classNames={{
                    dropdown: classes.Dropdown,
                    input: classes.Input,
                    item: classes.Item,
                    itemsWrapper: classes.ItemsWrapper,
                    root: classes.InputWrapper,
                }}
                // Approx 5 rows
                maxDropdownHeight={275}
                onDropdownOpen={() => {
                    if (!data?.length) return;
                    if (onClick) {
                        onClick();
                    }
                    setDropdownOpen(true);
                }}
                onDropdownClose={() => {
                    if (!data?.length) return;
                    setDropdownOpen(false);
                }}
            />
            {dropdownOpen && searchable && (
                <div
                    // Avoid click closing the dropdown
                    onClick={(e) => {
                        setSearchValue("");
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    onMouseUp={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                    className={classes.SearchClearContainer}
                >
                    <IconX size="1rem" />
                </div>
            )}
        </div>
    );
};

export default PrettySelect;
