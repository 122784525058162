import React from "react";
import { RootState } from "../../../../store/store";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { UserActionTypes } from "../../../../store/user/userTypes";
import { SystemActionTypes } from "../../../../store/system/systemTypes";

import classes from "./CookieBanner.module.css";
import { bindActionCreators } from "redux";
import { setCookiesSet } from "../../../../store/system/systemActions";
import { deleteCookie, getCookie, parseCookie, setCookie } from "../../../../utils/Cookies";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import Button from "../../../_Library/Button/Button";
import Toggle from "components/_Library/Inputs/Toggle/Toggle";
import GEOLogo from "../../../../assets/images/logos/GEO_full_logo.svg";

interface OwnProps {}
interface StateProps {
    cookiesSet: boolean;
}
interface DispatchProps {
    setCookiesSet: typeof setCookiesSet;
}

type Extensions = StateProps & DispatchProps;
type CookieBannerProps = OwnProps & Extensions;

enum CookieOption {
    Necessary,
    Analytics,
    Functionality,
    Advertising,
}

export type CookieOptions = keyof typeof CookieOption;
export type CookieSettings = { [key in CookieOptions]: boolean };

interface CookieBannerState extends CookieSettings {
    settingsOpen: boolean;
}

export const COOKIE_SETTINGS_COOKIE_NAME = "mc_cookie_cat_settings_v2";
export const HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME = "__hs_cookie_cat_pref";
const HUBSPOT_OPT_OUT_COOKIE_NAME = "__hs_opt_out";

export const NECESSARY_COOKIE_DESCRIPTION = `
    These cookies are necessary for the website to function and cannot be switched off 
    in our systems. They are usually only set in response to actions made by you which 
    amount to a request for services, such as setting your privacy preferences, logging in, 
    or filling in forms.
`
export const ANALYTICS_COOKIE_DESCRIPTION = `
    These cookies help us to understand how visitors engage with the website. We may use a set 
    of cookies to collect information and report site usage statistics.
`

export const FUNCTIONALITY_COOKIE_DESCRIPTION = `
    We use a set of cookies that are optional for the website to function. They are usually 
    only set in response to information provided to the website to personalize and optimize
    your experience as well as remember your chat history.
`

export const ADVERTISING_COOKIE_DESCRIPTION = `
    We use cookies to make our ads more engaging and valuable to site visitors. Some common 
    applications of cookies are to select advertising based on what's relevant to a user; 
    to improve reporting on ad campaign performance; and to avoid showing ads the user has 
    already seen.
`

class CookieBanner extends React.Component<
    CookieBannerProps,
    CookieBannerState
> {
    state: CookieBannerState = {
        settingsOpen: false,
        Necessary: true,
        Analytics: false,
        Functionality: false,
        Advertising: false,
    };

    componentDidMount() {
        setCookie(HUBSPOT_OPT_OUT_COOKIE_NAME, `yes`, 182);
        setCookie(
            HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
            `1:false,2:false,3:false`,
            182,
        );

        let cookieSettingsStr = getCookie(COOKIE_SETTINGS_COOKIE_NAME);
        if (cookieSettingsStr) {
            let settings = parseCookie(cookieSettingsStr);
            this.updateCookieSettings(settings, true);
        }
    }

    updateCookieSettings = (
        update: Partial<CookieSettings>,
        finishProcess: boolean = false,
    ) => {
        // @ts-ignore: it seems typescript cant deal with setting state with an enum like this
        this.setState(update, () => {
            setCookie(
                COOKIE_SETTINGS_COOKIE_NAME,
                `Analytics:${this.state.Analytics},Advertising:${this.state.Advertising},Functionality:${this.state.Functionality}`,
                182,
            );
            setCookie(
                HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
                `1:${this.state.Analytics},2:${this.state.Advertising},3:${this.state.Functionality}`,
                182,
            );
            if (finishProcess) {
                this.completeCookieSelection();
            }
        });
        //If user still has old cookies set, delete these. No longer used.
        if (getCookie("mc_cookie_cat_settings")){
            deleteCookie("mc_cookie_cat_settings");
        }
    };

    completeCookieSelection = () => {
        this.props.setCookiesSet(true);
    };

    render() {
        return (
            !this.props.cookiesSet && (
                <>
                    <div className={classes.Container}>
                        <div className={classes.LogoContainer}>
                            <div className={classes.LogoWrapper}>
                                <img alt="GEO Logo" src={GEOLogo} />
                            </div>
                        </div>
                        
                        <div className={classes.TextContainer}>
                            <p>
                                This website stores cookies on your computer.
                                These cookies are used to collect information
                                about how you interact with our website and
                                allow us to remember you. We use this
                                information in order to improve and customize
                                your browsing experience and for analytics and
                                metrics about our visitors.
                            </p>
                            <p>
                                If you decline, your information won’t be
                                tracked when you visit this website. A single
                                cookie will be used in your browser to remember
                                your preference not to be tracked.
                            </p>
                            <div className={classes.ButtonContainer}>
                                 <Button
                                    size={{ width: "50%" }}
                                    onClick={() => {
                                        this.updateCookieSettings(
                                            {
                                                Analytics: false,
                                                Functionality: false,
                                                Advertising: false,
                                            },
                                            true,
                                        );
                                    }}
                                >
                                    Reject non-essential cookies
                                </Button>
                                <Button
                                    size={{ width: "50%" }}
                                    onClick={() => {
                                        this.updateCookieSettings(
                                            {
                                                Analytics: true,
                                                Functionality: true,
                                                Advertising: true,
                                            },
                                            true,
                                        );
                                    }}
                                >
                                    Accept non-essential cookies
                                </Button>
                            </div>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <p className={classes.CookieName}>Essential Cookies</p>
                            <p className={classes.CookieDesc}>
                                {NECESSARY_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.CookieName}>Analytics Cookies</p>
                                <Toggle onClick={() => {
                                        this.setState({
                                            Analytics: !this.state.Analytics
                                        })
                                    }} active={this.state.Analytics}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                {ANALYTICS_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.CookieName}>Functionality Cookies</p>
                                <Toggle onClick={()=>{
                                    this.setState({
                                        Functionality: !this.state.Functionality
                                    })
                                }} active={this.state.Functionality}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                {FUNCTIONALITY_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.CookieName}>Advertising Cookies</p>
                                <Toggle onClick={()=>{
                                    this.setState({
                                        Advertising: !this.state.Advertising
                                    })
                                }} active={this.state.Advertising}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                {ADVERTISING_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SaveButtonContainer}>
                            <Button 
                                size={{width: "50%"}}
                                onClick={() => {
                                    this.updateCookieSettings(
                                        {
                                            Analytics: this.state.Analytics,
                                            Functionality: this.state.Functionality,
                                            Advertising: this.state.Advertising,
                                        },
                                        true,
                                    );
                                }}
                            >
                                Save and Close
                            </Button>
                        </div>
                    </div>
                </>
            )
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    cookiesSet: getStoreAtNamespaceKey(state, "system").cookiesSet,
});

const mapDispatchToProps = (
    dispatch: ThunkDispatch<
        any,
        any,
        UserActionTypes | SystemActionTypes
    >,
) => ({
    setCookiesSet: bindActionCreators(setCookiesSet, dispatch),
});

export type CookieBannerClass = CookieBanner;

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CookieBanner);
