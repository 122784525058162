import { OktaAuthOptions } from "@okta/okta-auth-js";

export const oktaAuthConfig: OktaAuthOptions = {
    issuer: `https://${import.meta.env.VITE_OKTA_DOMAIN}/oauth2/default`,
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    logoutUrl: window.location.origin + "/login",
    scopes: [
        "openid",
        "profile",
        "phone",
        "email",
        "user_preferences",
        "industry",
    ],
    pkce: true,
};

export const oktaSignInConfig = {
    issuer: `https://${import.meta.env.VITE_OKTA_DOMAIN}/oauth2/default`,
    clientId: import.meta.env.VITE_OKTA_CLIENT_ID,
    redirectUri: window.location.origin + "/login/callback",
    scopes: [
        "openid",
        "profile",
        "phone",
        "email",
        "user_preferences",
        "industry",
    ],
    authParams: {
        pkce: true,
    },
    i18n: {
        en: {
            "enroll.choices.submit.configure": "Configure Multi-Factor",
        },
    },
};
