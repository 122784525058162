import { useApiQueryWithAlert } from "./useAPI";

interface LayerDownload {
    name: string;
    id: string;
    layer_id: string;
    parent_names: string;
    layer_name: string;
    beta: boolean;
}

export function useLayerDownloadsAPI(reportId: string | undefined) {
    return useApiQueryWithAlert<
        LayerDownload[],
        Error,
        LayerDownload[]
    >({
        url: `/data/layers/downloads/${reportId}`,
        queryKey: ["dataLayerDownloads", reportId],
        options: {
            enabled: !!reportId,
            staleTime: 30_000,
        },
        alertOptions: {
            errorMessage: "Failed to fetch layer data",
        },
    });
}
