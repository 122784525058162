import { AnyAction } from "redux";

import { DashboardView, ReportComponents } from "../report/reportTypes";
import { ViewportProps } from "react-map-gl";
import { SetPreviewConfigPayload } from "../service/serviceTypes";

// ACTION TYPE CONSTANTS
export const SET_ALERT = "SET_ALERT";
export const DELETE_ALERT = "DELETE_ALERT";
export const SET_COOKIES_SET = "SET_COOKIES_SET";
export const SET_PREVIEW_CONFIG = "SET_PREVIEW_CONFIG";
export const TOGGLE_MODAL = "TOGGLE_MODAL";

// PAYLOAD TYPES
export interface SetAlertPayload {
    message: string;
    timeout?: number;
    type?: AlertConfig["type"];
}

export interface DeleteAlertPayload {
    uuid: string;
}

export type SetCookiesSetPayload = boolean;

// ACTION TYPES

export interface SetAlertAction extends AnyAction {
    type: typeof SET_ALERT;
    payload: SetAlertPayload;
}

export interface DeleteAlertAction extends AnyAction {
    type: typeof DELETE_ALERT;
    payload: DeleteAlertPayload;
}

export interface SetCookiesSetAction extends AnyAction {
    type: typeof SET_COOKIES_SET;
    payload: SetCookiesSetPayload;
}

export interface SetPreviewConfigAction {
    type: typeof SET_PREVIEW_CONFIG;
    payload: SetPreviewConfigPayload;
}

export type ToggleModalAction = {
    type: typeof TOGGLE_MODAL;
    payload: boolean | null;
};

export type SystemActionTypes =
    | SetAlertAction
    | DeleteAlertAction
    | SetCookiesSetAction
    | SetPreviewConfigAction
    | ToggleModalAction;

// REDUCER TYPES
export interface SystemState {
    alerts: { [key: string]: AlertConfig };
    cookiesSet: boolean;
    previewConfig: ReportComponents | null;
    appLoading: {
        loading: boolean;
        message: string;
    };
    modalOpen: boolean | null;
}

export interface BaseComponentConfig {
    type: DashboardComponentType;
}

export type DashboardComponentType = "map" | "text" | "summary";

export interface LayerTimelineOnConfig {
    type: "filter-on";
    data: {
        type: "internal" | "external";
        dateColumnName: string;
        format: string;
    };
}

export interface LayerTimelineOnArrayConfig {
    type: "filter-on-array";
    data: {
        type: "internal" | "external";
        dateColumnName: string;
        format: string;
    };
}

export interface LayerTimelineBetweenConfig {
    type: "filter-between";
    data: {
        type: "internal" | "external";
        dateColumnName: string;
        dateToColumnName: string;
        format: string;
    };
}

export type LayerTimelineConfig =
    | LayerTimelineOnConfig
    | LayerTimelineBetweenConfig
    | LayerTimelineOnArrayConfig;

export interface AlertConfig {
    message: string;
    timeout: number;
    type?: "Warning" | "Error" | "Success" | "Default";
}

export type DataFunction = [FunctionName, ...string[]];

export type FunctionName = "sum" | "divide" | "count";

export interface MapBuilderMetaData {
    overrides: Partial<ViewportProps>;
    minBounds: [[number, number], [number, number]] | null;
    minZoom: number;
}
export interface AppDataSetConfig {
    url: string;
}

export interface DisplayOptions {
    topLeft: [number, number];
    width: number;
    height: number;

    view: DashboardView;
}

export interface ConfigMenuLayer {
    id: string;
    type: "layer";
    layerName: string;
    layerSource: string;
}

export interface ConfigMenuGroup {
    id: string;
    type: "group";
    groupName: string;
    children: (ConfigMenuGroup | ConfigMenuLayer)[];
    asLayer?: boolean;
    isInsights?: boolean;
}

export const EventTypes = [
    "agriculture",
    "drought",
    "earthquake",
    "explosion",
    "fire",
    "flood",
    "freeze",
    "hail",
    "hurricane",
    "industrial",
    "riot",
    "sinkhole",
    "storm",
    "tornado",
    "volcano",
    "other",
];

export const PerilTypes = [
    "agriculture",
    "drought",
    "earthquake",
    "explosion",
    "fire",
    "flood",
    "freeze",
    "hail",
    "industrial",
    "riot",
    "sinkhole",
    "tornado",
    "volcano",
    "wind",
    "other",
] as const;

export const AssessmentPerilTypes = [
    "earthquake",
    "fire",
    "flood",
    "freeze",
    "hail",
    "terrorism",
    "tornado",
    "wind",
] as const;

export type AssessmentPerilType = (typeof AssessmentPerilTypes)[number];

export const EventImpacts = ["low", "moderate", "high"];

export type PerilType = (typeof PerilTypes)[number];

export type EventType = (typeof EventTypes)[number];

export type EventImpact = (typeof EventImpacts)[number];

export const AssessmentTypes = ["claims", "exposure"] as const;
export type AssessmentType = (typeof AssessmentTypes)[number];

export const Countries = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic of the",
    "Cook Islands",
    "Costa Rica",
    "Cote D'Ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic Of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People'S Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People'S Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "RWANDA",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia and Montenegro",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and the South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];

export const LicenseTypes = ["full", "partial", "prospective", "internal", "limoss", "poc", "sponsored", "expired"] as const;
export type LicenseType = typeof LicenseTypes[number];
export const CompanyRegions = [
    "United Kingdom",
    "European Union",
    "North America",
    "Other",
] as const;
export type CompanyRegion = (typeof CompanyRegions)[number];

export const CompanySegments = [
    "Lloyd's Only", 
    "Dual Platform", 
    "DCA",
    "Primary",
    "Broker",
    "Internal",
    "Services"
] as const;
export type CompanySegment = typeof CompanySegments[number];

export type ThemeOption = "light" | "dark";
