// loopInRange keeps a number in a range but loops the figure to the beginning + remainder if the max is exceeded.
export function loopInRange(
    minInRange: number,
    maxInRange: number,
    value: number,
): number {
    if (value >= minInRange && value <= maxInRange) {
        return value;
    }
    let rangeMagnitude = maxInRange - minInRange + 1;
    return value < minInRange ? value + rangeMagnitude : value - rangeMagnitude;
}

// Limits a value to between min and max.
export function clamp(min: number, max: number, number: number): number {
    return Math.max(min, Math.min(number, max));
}

export function generateHash(length: number = 10): string {
    return (Math.random() + 1).toString(36).substr(2, length);
}
