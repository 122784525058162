/**
 * Display layers from system into the layer list.
 *
 */

import React, {
    ChangeEvent,
    FC,
    ReactNode,
    useCallback,
    useState,
} from "react";

import MapboxGL from "mapbox-gl";

import classes from "./LayersTab.module.css";

import Icon from "@mdi/react";
import { ConfigMenuGroup, ConfigMenuLayer } from "store/system/systemTypes";
import {
    mdiDownload,
    mdiLayersPlus,
    mdiLayersTriple,
    mdiUnfoldLessHorizontal,
    mdiUnfoldMoreHorizontal,
} from "@mdi/js";

import GroupListItem, {
    GroupListItemHandle,
} from "./GroupListItem/GroupListItem";
import GroupedLayersListItem from "./GroupedLayersListItem/GroupedLayersListItem";
import InsightsLayerListItem from "./InsightsLayerListItem/InsightsLayerListItem";
import Button from "../../../../../../../_Library/Button/Button";
import { getCssVar } from "../../../../../../../../utils/CSSHelpers";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { useSelector } from "react-redux";
import { ActionIcon, Flex, Group, TextInput, Tooltip } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import _ from "lodash";
import LayerListItem from "./LayerListItem/LayerListItem";
import { getComplexAttrs } from "utils/PaintHelpers";

interface LayersTabProps {
    setDragAndDropState: (active: boolean) => void;
    toggleDownloadModal: () => void;
}

const LayersTab: FC<LayersTabProps> = (props: LayersTabProps) => {
    let [searchTerm, setSearchTerm] = useState("");
    let [groupRefs] = useState<GroupListItemHandle[]>([]);
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    let menuIndex = useSelector(
        (state) =>
            getStoreAtNamespaceKey(state, "report").layersConfig.menuIndex,
    );

    let sources = useSelector(
        (state) => getStoreAtNamespaceKey(state, "report").layersConfig.sources,
    );

    const searchAnalytics = _.debounce((searchTerm: string) => {
        trackUserEventWithCurrentEvent({
            name: "layer_searched",
            payload: {
                value: searchTerm,
            },
        });
    }, 500);

    const recursivelyFindVisibleChildren = (
        groupConfig: ConfigMenuGroup,
    ): boolean => {
        let visibleChild: ConfigMenuGroup | ConfigMenuLayer | undefined =
            groupConfig.children.find((child) => {
                if (child.type === "layer") {
                    return (
                        sources[child.layerSource].layout.visibility ===
                        "visible"
                    );
                } else if (child.type === "group") {
                    return recursivelyFindVisibleChildren(child);
                } else {
                    return false;
                }
            });
        return visibleChild !== undefined;
    };

    const getLayer = useCallback(
        (child: ConfigMenuLayer) => {
            return sources[(child as ConfigMenuLayer).layerSource!];
        },
        [sources],
    );

    const renderChildren = (
        arrayOfChildren: (ConfigMenuGroup | ConfigMenuLayer)[],
    ): ReactNode => {
        const renderedChildren = arrayOfChildren.map((child) => {
            if (searchTerm) {
                if (child.type === "layer") {
                    if (
                        child.layerName
                            .toLowerCase()
                            .includes(searchTerm.toLowerCase())
                    ) {
                        return renderLayer(child);
                    } else {
                        return null;
                    }
                } else {
                    return renderChildren(child.children);
                }
            } else {
                if (child.type === "layer") {
                    return renderLayer(child);
                } else if (child.asLayer) {
                    return renderGroupAsLayer(child);
                } else {
                    return renderGroup(child);
                }
            }
        });
        return renderedChildren;
    };

    const renderGroup = (group: ConfigMenuGroup) => {
        let childVisible = recursivelyFindVisibleChildren(group);
        return (
            <GroupListItem
                ref={(node) => {
                    if (
                        node !== null &&
                        !groupRefs.find((ref) => ref === node)
                    ) {
                        groupRefs.push(node);
                    }
                }}
                initiallyExpanded={childVisible}
                group={group}
                key={group.id}
                renderChildren={renderChildren}
                downloadsAvailable={anyDownloadsAvailable(group)}
            />
        );
    };

    const anyDownloadsAvailable = (
        item: ConfigMenuGroup | ConfigMenuLayer,
    ): boolean => {
        if (item.type === "layer") {
            return getLayer(item as ConfigMenuLayer).downloadsAvailable;
        } else {
            // Recursively look for a layer that has downloads available
            return item.children.some(
                (child: ConfigMenuLayer | ConfigMenuGroup) =>
                    anyDownloadsAvailable(child),
            );
        }
    };

    const renderGroupAsLayer = (inputGroup: ConfigMenuGroup) => {
        const { children } = inputGroup;
        const betaLayers = children.filter(
            (child) => getLayer(child as ConfigMenuLayer)?.beta,
        );

        const firstNonBasicLayer = children
            .filter((child) => {
                const layer = getLayer(child as ConfigMenuLayer);
                return layer && layer.tier !== "basic";
            })
            .map((child) => {
                const layer = getLayer(child as ConfigMenuLayer);
                return layer.tier;
            })[0];

        return (
            <GroupedLayersListItem
                key={inputGroup.id}
                group={inputGroup}
                beta={betaLayers.length > 0}
                tier={firstNonBasicLayer ?? "basic"}
                downloadsAvailable={anyDownloadsAvailable(inputGroup)}
                visible={recursivelyFindVisibleChildren(inputGroup)}
            />
        );
    };

    const renderLayer = useCallback(
        (inputLayer: ConfigMenuLayer) => {
            const { layerName, layerSource, id } = inputLayer;
            let layer = sources[layerSource!];

            let complexPaintProperties: Array<keyof MapboxGL.AnyPaint> = [];
            switch (layer.layerType) {
                case "fill":
                    complexPaintProperties = complexPaintProperties.concat(
                        getComplexAttrs<MapboxGL.FillPaint>(
                            layer.paint as MapboxGL.FillPaint,
                            ["fill-outline-color", "fill-color"],
                        ) as Array<keyof MapboxGL.AnyPaint>,
                    );
                    break;
                case "circle":
                    complexPaintProperties = complexPaintProperties.concat(
                        getComplexAttrs<MapboxGL.CirclePaint>(
                            layer.paint as MapboxGL.CirclePaint,
                            [
                                "circle-radius",
                                "circle-stroke-color",
                                "circle-color",
                                "circle-stroke-width",
                            ],
                        ) as Array<keyof MapboxGL.AnyPaint>,
                    );
                    break;
                case "line":
                    complexPaintProperties = complexPaintProperties.concat(
                        getComplexAttrs<MapboxGL.LinePaint>(
                            layer.paint as MapboxGL.LinePaint,
                            ["line-color", "line-width"],
                        ) as Array<keyof MapboxGL.AnyPaint>,
                    );
                    break;
                case "symbol":
                    //@ts-ignore
                    complexPaintProperties = complexPaintProperties.push(
                        //@ts-ignore
                        "this is used to trick misgis into thinking this is a complex paint",
                    );
                    break;
            }
            layer.complexPaintProperties = complexPaintProperties;

            let dataPropertyPresent = !!layer.data;
            return (
                <LayerListItem
                    id={`tourid${id}`}
                    key={layerName}
                    layerName={layerName}
                    sourceName={layerSource}
                    actions={layer.actions}
                    visibility={layer.layout.visibility}
                    viewOn={layer.viewOn}
                    paint={layer.paint}
                    layout={layer.layout}
                    layerType={layer.layerType}
                    complexPaintProperties={layer.complexPaintProperties}
                    isCustomLayer={dataPropertyPresent}
                    beta={layer.beta ?? false}
                    tier={layer.tier ?? "basic"}
                    downloadsAvailable={layer.downloadsAvailable ?? false}
                />
            );
        },
        [sources],
    );

    const updateSearchTerm = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        searchAnalytics(event.target.value);
    };

    const expandAllGroups = () => {
        groupRefs.forEach((groupRef) => groupRef.openGroup());
    };
    const collapseAllGroups = () => {
        groupRefs.forEach((groupRef) => groupRef.closeGroup());
    };

    return (
        <div className={classes.LayersTab} id={"tourid_layersMenu"}>
            <InsightsLayerListItem />
            <div className={classes.LayerHeader}>
                <Icon path={mdiLayersTriple} /> Data Layers
                <div className={classes.LayerListActions}>
                    <Group spacing="xs">
                        <Tooltip label={"Download intelligence layers"}>
                            <div>
                                <Button
                                    size={{ height: "3.2rem", width: "12rem" }}
                                    highlightBackground
                                    borderRadius={getCssVar(
                                        "--border-radius-sm",
                                    )}
                                    onClick={() => {
                                    props.toggleDownloadModal();
                                        trackUserEventWithCurrentEvent({
                                            name: "data_layers_download_clicked",
                                        });
                                    }}
                                >
                                    <Icon path={mdiDownload} size={1.5} />
                                    <p>Download</p>
                                </Button>
                            </div>
                        </Tooltip>

                        <Tooltip label={"Add your own data"}>
                            <div
                                onClick={() => {
                                    trackUserEventWithCurrentEvent({
                                        name: "data_layer_upload_clicked",
                                    });
                                    props.setDragAndDropState(true);
                                }}
                                id="tourid_DataUpload"
                            >
                                <Button
                                    size={{ width: "3.2rem", height: "3.2rem" }}
                                    highlightBackground
                                    borderRadius={getCssVar(
                                        "--border-radius-sm",
                                    )}
                                >
                                    <Icon path={mdiLayersPlus} />
                                </Button>
                            </div>
                        </Tooltip>
                    </Group>
                </div>
            </div>
            <div className={classes.LayerBody} id={"tourId_LayerBody"}>
                <Flex justify="space-between">
                    <div className={classes.LayerSearchContainer}>
                        <IconSearch
                            style={{ zIndex: 1, position: "absolute" }}
                            size="1.5rem"
                        />
                        <TextInput
                            onChange={updateSearchTerm}
                            type="search"
                            placeholder={"Layer Search"}
                            value={searchTerm}
                            size="sm"
                            styles={{
                                input: {
                                    paddingLeft: "2.5rem",
                                    "&::placeholder": {
                                        color: "var(--text-color-lo-cont)",
                                    },
                                },
                            }}
                        />
                    </div>
                    <Group noWrap align="flex-start" spacing="0" pl="xs">
                        <Tooltip label={"Collapse All"}>
                            <ActionIcon
                                size="lg"
                                c="var(--text-color)"
                                variant="subtle"
                                onClick={collapseAllGroups}
                            >
                                <Icon
                                    path={mdiUnfoldLessHorizontal}
                                    size={1.5}
                                />
                            </ActionIcon>
                        </Tooltip>
                        <Tooltip label={"Expand All"}>
                            <ActionIcon
                                size="lg"
                                c="var(--text-color)"
                                variant="subtle"
                                onClick={expandAllGroups}
                            >
                                <Icon
                                    path={mdiUnfoldMoreHorizontal}
                                    size={1.5}
                                />
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Flex>
                {renderChildren(menuIndex)}
            </div>
        </div>
    );
};

export default LayersTab;
