import React from "react";
import Icon from "@mdi/react";
import classes from "./Badges.module.css";
import { mdiCrown, mdiFlask } from "@mdi/js";
import ReactTooltip from "react-tooltip";

interface Props {
    beta?: boolean;
    tier?: string;
}

export const Badges = ({
   beta = false,
   tier = "basic"
}: Props) => {

    const betaBadge = () => {
        return (
            <div className={classes.LayerAccessIcon} 
                data-for={"layerAccessBadge"} 
                data-tip={"Beta Layer"}
            >
                <Icon path={mdiFlask} size={1}/> 
            </div>
        )
    }
    
    const premiumBadge = () => {
        return (
            <div className={classes.LayerAccessIcon} 
                data-for={"layerAccessBadge"} 
                data-tip={"Premium Layer"}
            >
                <Icon path={mdiCrown} size={1}/>
                
            </div>
        )
    }

    return (
        <div className={classes.BadgeContainer}>
            <ReactTooltip
                id={"layerAccessBadge"}
                place={"top"}
                effect={"solid"}
            /> 
            {beta && betaBadge()}
            {tier === "premium" && premiumBadge()}  
        </div>
    )
};
