import { RevisionSchema } from "crud/insightsCRUD";
import { ExtendedAccessManagementUser } from "types/auth";
import _ from "lodash";

export type RevisionTypeWithLabel = RevisionSchema & { label: string };

export function formatExposureClaim(revision: RevisionSchema): RevisionTypeWithLabel {
    let regex = /\/([^/]+)$/;
    const label = `${revision!.path!.match(regex)![1]}${
        revision.publisher ? "" : " (unpublished)"
    }`;
    return {
        label,
        ...revision,
    };
}

export function formatExposureClaims(
    revisionVals: RevisionSchema[],
): RevisionTypeWithLabel[] {
    /**
     * Formats a list of revisions, including a 'label' key for presentation (i.e. E1C2)
     */
    return revisionVals
        .map((val: RevisionSchema) => formatExposureClaim(val))
}


export const shouldDisableDownloads = (user: ExtendedAccessManagementUser | null, portfolioId: string) => {
    /**
     * Disable downloads if the user does not
     * have access to download for that portfolio
     */
    if (user?.is_admin) return false;
    if (!user || !user?.portfolios) {
        return true;
    }
    const isAllowed = _.get(user.insights_access, portfolioId, false);
    return !isAllowed

}