import React, { Component, ReactElement } from "react";
import Icon from "@mdi/react";
import classes from "./ActionDropdown.module.css";
import cx from "classnames";
import ReactTooltip, { Place } from "react-tooltip";

type ActionButtonProps = {
    onClick: () => void;
    renderElementFn: () => ReactElement; // Provide either a JSX element or Icon
    icon: string;
    subHeading: string;
    tooltip: string;
    tooltipPlacement: Place;
    className: string;
    id: string;
};

type ExpanderButtonProps = {
    icon?: string;
    renderElementFn?: () => ReactElement;
    onClick?: () => void;
    className?: string;
    size?: number;
    tooltip?: string;
    tooltipPlacement?: Place;
    id?: string;
};

type ActionProps = {
    expanderButtonProps: ExpanderButtonProps;
    innerButtonProps: Array<Partial<ActionButtonProps>>;
    expanded: boolean;
    containerClassName?: string;
    buttonsContainerClassName?: string;
    id?: string;
};

class ActionDropdown extends Component<ActionProps> {
    private expanderToolTipId =
        "DropdownExpander" +
        this.props.expanderButtonProps.tooltipPlacement +
        this.props.expanderButtonProps.id;
    componentDidUpdate() {
        // The expander when shown does not remove the tooltip
        // even with using state variables or conditional rendering of the tooltip
        // so we need to manually hide it on re-render if the expander is shown
        const expanderTooltip = document.getElementById(this.expanderToolTipId);
        if (expanderTooltip)
            expanderTooltip.style.visibility = this.props.expanded
                ? "hidden"
                : "visible";
    }

    renderIconOrCustom(props: Partial<ActionButtonProps>): ReactElement | null {
        let innerElements = null;
        if (props.icon) {
            innerElements = (
                <span
                    onClick={() => {
                        if (props.onClick) props.onClick();
                    }}
                >
                    <Icon
                        path={props.icon}
                        className={cx(
                            classes.ActionDropdownItem,
                            props.className,
                        )}
                    />
                </span>
            );
        }
        if (props.renderElementFn) {
            innerElements = props.renderElementFn();
        }
        return (
            <div
                data-for={"DropdownButton" + props.tooltip}
                data-tip={props.tooltip}
                className={props.className}
                id={props.id}
            >
                {innerElements}
                <ReactTooltip
                    id={"DropdownButton" + props.tooltip}
                    place={props.tooltipPlacement}
                    effect={"solid"}
                />
            </div>
        );
    }

    renderExpanderIconOrCustom(
        props: Partial<ExpanderButtonProps>,
    ): ReactElement | null {
        let innerElements = null;
        if (props.icon) {
            innerElements = <Icon path={props.icon} size={props.size} />;
        }
        if (props.renderElementFn) {
            innerElements = props.renderElementFn();
        }
        return (
            <div
                data-tip={props.tooltip}
                data-for={
                    "DropdownExpander" + props.tooltipPlacement + props.id
                }
                className={cx(props.className, classes.ActionDropdownExpander)}
                id={props.id}
            >
                {innerElements}
                {!this.props.expanded && (
                    <ReactTooltip
                        
                        id={this.expanderToolTipId}
                        className={"ExpanderTooltip"}
                        place={props.tooltipPlacement}
                        effect={"solid"}
                    />
                )}
            </div>
        );
    }

    render() {
        return (
            <div
                className={cx(
                    classes.ActionDropdownContainer,
                    this.props.containerClassName,
                )}
                id={this.props.id}
                onClick={() => {
                    if (this.props?.expanderButtonProps?.onClick)
                        this.props.expanderButtonProps.onClick();
                }}
            >
                {this.renderExpanderIconOrCustom(
                    this.props.expanderButtonProps,
                )}
                {this.props.expanded && (
                    <div
                        className={cx(
                            classes.ActionDropdownButtonsContainer,
                            this.props.buttonsContainerClassName,
                            {
                                [classes.ActionDropdownButtonsContainerExpanded]:
                                    this.props.expanded,
                            },
                        )}
                    >
                        {this.props.innerButtonProps.map(
                            (
                                props: Partial<ActionButtonProps>,
                                idx: number,
                            ) => {
                                return (
                                    <div
                                        className={
                                            classes.ActionDropdownButtonsInnerContainer
                                        }
                                        key={`innerButtons-${idx}`}
                                    >
                                        {this.renderIconOrCustom(props)}
                                        <p
                                            className={
                                                classes.ActionDropdownItemSubHeading
                                            }
                                        >
                                            {props.subHeading}
                                        </p>
                                    </div>
                                );
                            },
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ActionDropdown;
