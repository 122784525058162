/**
 * Generates a legend icon from the layer paint specified in the system. For raster returns mdi image icon.
 * This follows the mapbox style spec.
 * Also used to generates an icon from the map feature OnClick which presents the paint in a {r: , g: , b: } format.
 */

import React from "react";
import * as MapboxGL from "mapbox-gl";

import Circle from "./Circle";
import {
    getColorFromPaint,
    getNumberFromPaint,
} from "../../../../../../../utils/PaintHelpers";

interface OwnProps {
    paint: MapboxGL.CirclePaint;
}
const PointIcon: React.FC<OwnProps> = (props: OwnProps) => {
    let color = getColorFromPaint(
        props.paint,
        "circle-color" as keyof MapboxGL.AnyPaint,
        "#000000",
    );
    let borderColor = getColorFromPaint(
        props.paint,
        "circle-stroke-color" as keyof MapboxGL.AnyPaint,
        "#000000",
    );
    let borderWidth = getNumberFromPaint(
        props.paint,
        "circle-stroke-width" as keyof MapboxGL.AnyPaint,
        1,
    );
    let radius = getNumberFromPaint(
        props.paint,
        "circle-radius" as keyof MapboxGL.AnyPaint,
        5,
    );

    let iconRadius = radius * 2 + borderWidth * 2;
    if (iconRadius > 18) {
        radius *= 18 / iconRadius;
        borderWidth *= 18 / iconRadius;
    }

    return (
        <Circle
            color={color}
            radius={radius}
            borderWidth={borderWidth}
            borderColor={borderColor}
        />
    );
};

export default PointIcon;
