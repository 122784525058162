import React, { ReactElement } from "react";
import { Layer, LayerProps, Source, Marker, SourceProps } from "react-map-gl";
import moment from "moment";

import {
    ConfigSource,
    LayerFilter,
    LayerFilters,
    LayersConfig,
} from "store/report/reportTypes";
import { MarkerConfig } from "store/insights/insightsTypes";
import { ConfigMenuLayer as MenuIndexLayer } from "store/system/systemTypes";
import { getLayers } from "utils/Layers";
import { buildLegendTextForRows } from "utils/ExtractLegendData";
import MarkerImage from "/src/assets/images/location_marker.png";
import { Expression } from "mapbox-gl";

export interface MarkerProps {
    marker: MarkerConfig | null;
    locationLabels: boolean;
}

export interface MarkerSearchProps {
    lnglat: [number, number];
}

const createLayerFilter = (
    layer: ConfigSource,
    legendRowTexts: string[],
    layerFilters?: LayerFilter,
    currentTimelineDate?: number,
): LayerProps["filter"] => {
    let filters: Expression[] = [];
    if (layerFilters && layerFilters.values.length > 0) {
        const filteredValues = layerFilters.values;
        const identifier = layerFilters.identifier;

        if (filteredValues.includes("Other")) {
            let filtersToExclude: any[] = [];
            for (let i = 0; i < legendRowTexts.length; i++) {
                if (!filteredValues.includes(legendRowTexts[i])) {
                    filtersToExclude.push(legendRowTexts[i]);
                }
            }
            filtersToExclude.forEach((label) => {
                filters.push(["!=", ["get", identifier], label]);
            });
        } else {
            filters.push([
                "in",
                ["get", identifier],
                ["literal", filteredValues],
            ]);
        }
    }
    let timeline = layer.dataConfig?.timeline;
    if (currentTimelineDate && timeline) {
        if (timeline.type === "filter-on") {
            filters.push([
                "==",
                ["get", timeline.data.dateColumnName],
                moment(currentTimelineDate).format(timeline.data.format),
            ]);
        } else if (timeline.type === "filter-between") {
            filters.push([
                "<=",
                ["get", timeline.data.dateColumnName],
                parseInt(
                    moment(currentTimelineDate).format(timeline.data.format),
                ),
            ]);
            filters.push([
                ">",
                ["get", timeline.data.dateToColumnName],
                parseInt(
                    moment(currentTimelineDate).format(timeline.data.format),
                ),
            ]);
        } else if (timeline.type === "filter-on-array") {
            filters.push([
                "in",
                parseInt(
                    moment(currentTimelineDate).format(timeline.data.format),
                ),
                ["get", timeline.data.dateColumnName],
            ]);
        }
    }

    if (filters.length) {
        if (filters.length === 1) {
            return filters[0];
        }
        if (filters.length > 1) {
            //mapbox needs 'all' added to beginning of array when multiple filters present
            return ["all", ...filters];
        }
    }
};

const prepareLayerInfo = (
    locationLabels: boolean,
    layerSource: ConfigSource,
    layerName: string,
    legendRowTexts: string[],
    layerFilter?: LayerFilter,
    currentTimelineDate?: number,
): ReactElement => {
    let layerProps: LayerProps = {
        id: layerName,
        type: layerSource.layerType,
        paint: { ...layerSource.paint },
        source: layerSource.source, // analogous with source-layer in regular mapbox
        layout: { ...layerSource.layout },
    };

    if (layerFilter || currentTimelineDate) {
        const layerFilters = createLayerFilter(
            layerSource,
            legendRowTexts,
            layerFilter,
            currentTimelineDate,
        );

        if (layerFilters) {
            layerProps.filter = layerFilters;
        }
    }

    layerProps["source-layer"] =
        layerSource["source-layer"] ?? layerProps.source ?? "";
    const reactLayer = (
        <Layer
            key={layerProps.id}
            {...layerProps}
            beforeId={locationLabels ? "road-label" : ""}
        />
    );
    return reactLayer;
};

const createLayer = (
    locationLabels: boolean,
    layerName: string,
    layerSourceId: string,
    layerSource: ConfigSource,
    layerFilter?: LayerFilter,
    currentTimelineDate?: number,
): ReactElement => {
    const type = layerSource.layerType;
    const paint = layerSource.paint;
    const legendRowTexts = buildLegendTextForRows(paint, type);
    const layerInfo = prepareLayerInfo(
        locationLabels,
        layerSource,
        layerName,
        legendRowTexts,
        layerFilter,
        currentTimelineDate,
    );
    const sourceProps: SourceProps = {
        id: layerSourceId,
        type: layerSource.type,
        url: layerSource.url,
        data: layerSource.data,
        tiles: layerSource.tiles,
    };
    if (layerSource.tiles) {
        delete sourceProps.url;
        delete sourceProps.data;
        sourceProps.tileSize = layerSource.tileSize || 256;
    }
    if (layerSource.data) {
        delete sourceProps.url;
        delete sourceProps.tiles;
    }
    if (layerSource.url) {
        delete sourceProps.data;
        delete sourceProps.tiles;
    }

    return (
        <Source key={layerSourceId} {...sourceProps} maxzoom={19}>
            {layerInfo}
        </Source>
    );
};

const MapLayers = (
    layersConfig: LayersConfig,
    locationLabels: boolean,
    layerFilters: LayerFilters,
    currentTimelineDate?: number,
): ReactElement[] => {
    if (!layersConfig) {
        return [];
    }

    let extractedLayers: Array<any> = getLayers(layersConfig.menuIndex);
    let layerElements = extractedLayers.map((layerObject: MenuIndexLayer) => {
        return createLayer(
            locationLabels,
            layerObject.layerName,
            layerObject.layerSource,
            layersConfig.sources[layerObject.layerSource],
            layerFilters[layerObject.layerSource],
            currentTimelineDate,
        );
    });
    return layerElements.reverse();
};

export const MapSearchMarker = (props: MarkerSearchProps) => {
    if (props.lnglat) {
        return (
            <Marker
                longitude={props.lnglat[0]}
                latitude={props.lnglat[1]}
                offsetTop={-45}
                offsetLeft={-16.5}
            >
                <img
                    alt={"Location Pin"}
                    src={MarkerImage}
                    style={{ width: "3.3rem", height: "4.5rem" }}
                />
            </Marker>
        );
    } else {
        return <></>;
    }
};

export const CreateLayerFromConfigSource = (source: ConfigSource) => {
    return (
        <Source key={source.source} {...source}>
            <Layer
                id={source.source}
                type={source.layerType}
                source={source.source}
                paint={source.paint}
                layout={source.layout}
            />
        </Source>
    );
};

export default MapLayers;
