export const makeActionWithPayload =
    <T, P>(type: T) =>
    (payload: P) => {
        return {
            type,
            payload,
        };
    };

export const makeAction =
    <T>(type: T) =>
    () => {
        return {
            type,
        };
    };
