import * as MapboxGL from "mapbox-gl";
import { isNumber, isString } from "./TypeGuards";
import { StyleConfig_Style } from "crud/layerStylesCRUD";

export const getComplexAttrs = <T_Paint>(
    paint: T_Paint,
    triggers: Array<keyof T_Paint>,
) => {
    let complex: Array<keyof T_Paint> = [];
    for (let trigger of triggers) {
        if (Array.isArray(paint[trigger])) {
            complex.push(trigger);
        }
    }
    return complex;
};

interface Color {
    r: number;
    g: number;
    b: number;
}

export function getColorFromPaint(
    paint: MapboxGL.AnyPaint,
    att: keyof MapboxGL.AnyPaint,
    def = "#f0f",
) {
    let attrValue: string;

    if (isString(paint[att])) {
        attrValue = paint[att];
    } else if ((paint[att] as Color)?.r !== undefined) {
        let color = paint[att] as Color;
        attrValue = `rgb(${color.r * 255}, ${color.g * 255}, ${color.b * 255})`;
    } else {
        attrValue = def;
    }
    return attrValue;
}

export const getNumberFromPaint = (
    paint: MapboxGL.AnyPaint,
    att: keyof MapboxGL.AnyPaint,
    def = 0,
) => {
    return isNumber(paint[att]) ? paint[att] : def;
};

export const extractMatchedProperty = (insightsStyle: StyleConfig_Style) => {
    // pull out the matched property
    return (
        (insightsStyle.style.paint as MapboxGL.CirclePaint)[
            "circle-color"
        ] as MapboxGL.Expression
    )[1][1];
};

export const extractPaintCategories = (insightsStyle: StyleConfig_Style): string[] => {
    // The bellow filter removes the "match" operator and its argument array,
    // removes the last default "Other" color,
    // as well as the category colors leaving you with a list of categories
    return (
        (insightsStyle.style.paint as MapboxGL.CirclePaint)[
            "circle-color"
        ] as MapboxGL.Expression
    ).filter((_, index) => {
        return (
            index >= 2 &&
            index % 2 === 0 &&
            index !==
                (
                    (insightsStyle.style.paint as MapboxGL.CirclePaint)[
                        "circle-color"
                    ] as MapboxGL.Expression
                ).length -
                    1
        );
    });
};

export const extractCatToPaintTuples = (insightsStyle: StyleConfig_Style): [string, string][] => {
    const style = (insightsStyle.style.paint as MapboxGL.CirclePaint)[
        "circle-color"
    ] as MapboxGL.Expression;

    const categories = extractPaintCategories(insightsStyle);
    const tuples = categories.map((category, index) => {
        return [
            category,
            style[style.findIndex((styleElem) => category === styleElem) + 1],
        ] as [string, string];
    });

    tuples.push(["Other", style.slice(-1)[0]]);
    return tuples;
};
