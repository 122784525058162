import { ParseResult } from "papaparse";

function geoJSONFromPapaparse(
    pdf: ParseResult<any>,
): GeoJSON.FeatureCollection {
    return {
        type: "FeatureCollection",
        features: pdf.data.map((row, index) => {
            for (let prop in row) {
                if (row[prop] === null) {
                    row[prop] = "";
                }
            }

            return {
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: [
                        parseFloat(
                            row.LONG ||
                            row.LONGITUDE ||
                            row.Longitude ||
                            row.longitude ||
                            row.lng ||
                            row.long,
                        ),
                        parseFloat(
                            row.LAT ||
                            row.LATITUDE ||
                            row.Latitude ||
                            row.latitude ||
                            row.lat,
                        ),
                    ],
                },
                properties: { ...row, feature_id: index },
            };
        }),
    };
}

function filterGeojsonByProperties(
    featureCollection: GeoJSON.FeatureCollection,
    propertiesAndValues: {
        property: string;
        values: string[] | Number[] | null;
    }[],
): GeoJSON.FeatureCollection {
    // Create a new FeatureCollection with the filtered features
    return {
        type: "FeatureCollection",
        features: featureCollection.features.filter((feature) => {
            // Ensure that the feature has properties
            if (!feature.properties) return false;

            // Check if the feature's property matches the key and value
            // for each of the filters provided
            return propertiesAndValues.every(({ property, values }) => {
                if (values?.length === 0) return true;

                if (property === "MIS_ContractID") {
                    return values?.includes(
                        feature.properties![property].toString(),
                    );
                }
                if (!Object.keys(feature.properties!).includes(property))
                    return false;

                if (property === "MIS_ContractID") {
                    return values?.includes(
                        feature.properties![property].toString(),
                    );
                }

                return values?.includes(feature.properties![property]);
            });
        }),
    };
}

function filterGeojsonByIndex(
    featureCollection: GeoJSON.FeatureCollection,
    indices: number[],
): GeoJSON.FeatureCollection {
    // Create a new FeatureCollection with the filtered features
    return {
        ...featureCollection,
        features: featureCollection.features.filter((_, index) => {
            return indices.includes(index);
        }),
    };
}

export {
    geoJSONFromPapaparse,
    filterGeojsonByProperties,
    filterGeojsonByIndex,
};
