import { EventImpact, EventType } from "../store/system/systemTypes";
import {
    makeAPICall_DynamicUrl,
    makeAPICall_StaticUrl,
} from "../utils/APIHelpers";
import { genericAPIResponse } from "./genericResponse";

export interface EventSchema {
    id: string;
    name: string;
    has_report: boolean;
    type: EventType;
    impact: EventImpact;
    start: number;
    end: number | null;
    location: string;
    latitude: number;
    longitude: number;
    created_at: number;
    updated_at: number;
    countries_impacted: string[];
    lloyds_cat_code: boolean;
    restricted: boolean;
    scope_id: string;
}

export type EventSchemaBase = Omit<EventSchema, "updated_at" | "created_at">;

export const eventsGetAll = makeAPICall_StaticUrl<
    genericAPIResponse<EventSchema[]>
>("/events/report", "GET");
export const eventGet = makeAPICall_DynamicUrl<
    genericAPIResponse<EventSchema>,
    { eventId: string }
>("/events/", "GET");
export const eventsCreate = makeAPICall_StaticUrl<EventSchema, EventSchemaBase>(
    "/events/",
    "POST",
);
export const eventsUpdate = makeAPICall_DynamicUrl<
    genericAPIResponse<EventSchema>,
    {},
    { eventId: string },
    EventSchemaBase | EventSchema
>("/events/{eventId}", "PATCH");
