import _ from "lodash";
import { Dict } from "../types/misgis";

type HTTPRequestMethods =
    | "PUT"
    | "PATCH"
    | "GET"
    | "POST"
    | "HEAD"
    | "DELETE"
    | "CONNECT"
    | "OPTIONS"
    | "TRACE";

/**
 * Builds a function for making api calls to a static url
 *
 * @param url - The endpoint to call.
 *
 * @param method - GET, POST, DELETE etc.
 *
 * @returns function - A function which accepts a token, http body for POST, PUT etc.
 * The generated function will call the api endpoint specified and return the result in a promise.
 */
export const makeAPICall_StaticUrl = <ReturnType, BodyType = void>(
    url: string,
    method: HTTPRequestMethods = "GET",
) => {
    return async (token: string, data: BodyType): Promise<ReturnType> => {
        let res = await fetch(`${import.meta.env.VITE_API_ROOT}${url}`, {
            method: method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
        return await res.json();
    };
};
/**
 * Builds URLSearchParams with non-empty, non-undefined, and truthy values.
 *
 * @param parameters - An object containing parameters to include in the URLSearchParams.
 * @returns URLSearchParams - URLSearchParams object with valid parameters.
 */
const buildSearchParams = <UrlParameterPack extends Dict<any>>(parameters: {
    [key in keyof UrlParameterPack]: any;
}): URLSearchParams => {
    const searchParams = new URLSearchParams();

    for (const key in parameters) {
        if (
            parameters[key] !== undefined &&
            parameters[key] !== null &&
            parameters[key] !== ""
        ) {
            searchParams.append(key, parameters[key]);
        }
    }

    return searchParams;
};

/**
 * Builds a function for making API calls with URLSearchParams for query parameters.
 *
 * @param url - This should be formatted with the parameter names which will be provided wrapped in curly brackets,
 * e.g. '/user/{user_id}'. When the returned function is called, the parameters object should contain keys matching the URL parameters.
 *
 * @param method - GET, POST, DELETE etc.
 *
 * @returns function - A function which accepts a token, URL parameters, and request body.
 * The generated function will call the API endpoint specified and return the result in a promise.
 */
export const makeAPICall_DynamicUrl = <
    ReturnType,
    UrlParameterPack extends Dict<any> = {},
    PathParameterPack extends Dict<any> = {},
    BodyType = void,
>(
    url: string,
    method: HTTPRequestMethods = "GET",
) => {
    return async (
        token: string,
        parameters: { [key in keyof UrlParameterPack]: any },
        pathParameters: { [key in keyof PathParameterPack]: any },
        signal: AbortSignal | null = null,
        data?: BodyType,
    ): Promise<ReturnType> => {
        let newUrl = _.cloneDeep(url);
        const searchParams = buildSearchParams(parameters);
        const queryString = searchParams.toString();
        if (pathParameters) {
            for (const [key, value] of Object.entries(pathParameters)) {
                newUrl = newUrl.replace(`{${key}}`, value.toString());
            }
        }
        const apiUrl = queryString ? `${newUrl}?${queryString}` : newUrl;
        const res = await fetch(`${import.meta.env.VITE_API_ROOT}${apiUrl}`, {
            method: method,
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
            signal,
        });
        return await res.json();
    };
};
