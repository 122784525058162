import {
    FullRootState,
    getStore,
    NamespaceKey,
    registerReducer,
    RootState,
} from "./store";

import { refReducer } from "./ref/refReducer";
import { once } from "lodash";
import { reportReducer } from "./report/reportReducer";
import { serviceReducer } from "./service/serviceReducer";
import { userReducer } from "./user/userReducer";
import { insightsReducer } from "./insights/insightsReducer";
export function getStoreAtNamespaceKey<T extends NamespaceKey>(
    state: RootState,
    namespace: T,
): FullRootState[T] {
    const namespaceState = state[namespace];
    if (!namespaceState) {
        throw new Error(
            `Attempted to access state for an unregistered namespace at key: "${namespace}"`,
        );
    }
    // We need to explicitly say this is not undefined because TypeScript doesn't
    // recognize the thrown error will prevent us from ever getting here.
    return namespaceState as FullRootState[T];
}

export const getHomepageStore = once(() => {
    registerReducer({
        service: serviceReducer,
        report: reportReducer,
    });
    return getStore();
});

export const getEventsPageStore = once(() => {
    registerReducer({
        service: serviceReducer,
    });
    return getStore();
});

export const getReportPageStore = once(() => {
    registerReducer({
        report: reportReducer,
        ref: refReducer,
        service: serviceReducer,
        user: userReducer,
        insights: insightsReducer,
    });
    return getStore();
});

export const getAdminPageStore = once(() => {
    registerReducer({
        report: reportReducer,
        service: serviceReducer,
        insights: insightsReducer,
    });
    return getStore();
});
