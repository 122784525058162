import React, { useEffect } from "react";
import BasePage from "../BasePage/BasePage";
import GEO404Logo from "/src/assets/images/404_graphic.png";
import ErrorPane from "components/_Library/Errors/ErrorPanel/ErrorPane";
import classes from "./404Page.module.css";
import { fetchAllEventData } from "store/service/serviceActions";
import { withOktaAuth } from "@okta/okta-react";
import { useDispatch } from "react-redux";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

type Error404Props = IOktaContext;
const Error404Page: React.FC<Error404Props> = ({ oktaAuth }) => {
    const dispatch = useDispatch();
    let token = oktaAuth.getAccessToken();
    useEffect(() => {
        if (token) {
            dispatch(fetchAllEventData(token));
        }
    }, [dispatch, token]);

    return (
        <BasePage>
            <div className={classes.PageContainer}>
                <ErrorPane
                    title="Page Not Found"
                    textLineOne="Oops! We couldn't find the page that you're looking for."
                    textLineTwo="Please check the address and try again, or"
                    image={GEO404Logo}
                />
            </div>
        </BasePage>
    );
};

export default withOktaAuth(Error404Page);
