import React, { useContext } from "react";
import { Icon } from "@mdi/react";
import { mdiDownload, mdiDownloadOff, mdiEyeOutline } from "@mdi/js";
import cx from "classnames";
import { DownloadsContext } from "components/Pages/Report/Report";
import classes from "../LayerListItem/LayerListItem.module.css";
import { mdiArrowUpDownRightBold } from "assets/icons/paths";
import { Tooltip } from "@mantine/core";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

interface LayerContextMenuProps {
    onZoomTo: () => void;
    onViewSelection: (view: "left" | "right" | "both") => void;
    currentView: "left" | "right" | "both";
    downloadsAvailable?: boolean;
    name: string;
    type: "group_as_layer" | "layer"
}

const LayerContextMenu: React.FC<LayerContextMenuProps> = ({
    onZoomTo,
    onViewSelection,
    currentView,
    downloadsAvailable = false,
    name,
    type,
}) => {
    const { setDownloadModalOpen, setOpenLayerId } =
        useContext(DownloadsContext);
    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const handleDownload = () => {
        if (!downloadsAvailable) return;
        setDownloadModalOpen(true);
        setOpenLayerId(name);
        if (type === "layer") {
            trackUserEventWithCurrentEvent({
                name: "layer_modal_download_clicked", 
                payload: { layer_id: name },
            });
        } else {
            trackUserEventWithCurrentEvent({
                name: "group_as_layer_modal_download_clicked", 
                payload: { group_name: name },
            });
        }
    };

    return (
        <>
            <div onClick={onZoomTo} className={classes.ContextItem}>
                <Icon
                    path={mdiArrowUpDownRightBold}
                    style={{ cursor: "pointer" }}
                />
                <span style={{ cursor: "pointer", margin: 0 }}>Go to</span>
            </div>
            <div className={cx(classes.ContextItem, classes.LayerViewToggle)}>
                <Icon path={mdiEyeOutline} style={{ cursor: "auto" }} />
                <span
                    onClick={() => onViewSelection("left")}
                    className={cx({ [classes.Active]: currentView === "left" })}
                >
                    Left
                </span>
                <span
                    onClick={() => onViewSelection("both")}
                    className={cx({ [classes.Active]: currentView === "both" })}
                >
                    Both
                </span>
                <span
                    onClick={() => onViewSelection("right")}
                    className={cx({
                        [classes.Active]: currentView === "right",
                    })}
                >
                    Right
                </span>
                <Tooltip label={downloadsAvailable ? "Download this layer" : "Download not available"} position="bottom-start">
                    <span
                        onClick={handleDownload}
                        style={{
                            cursor: downloadsAvailable ? "pointer" : "not-allowed",
                        }}
                    >
                        <Icon
                            path={downloadsAvailable ? mdiDownload : mdiDownloadOff}
                            color={
                                downloadsAvailable
                                    ? "var(--text-color-hi-cont)"
                                    : "var(--text-color-lo-cont)"
                            }
                            size={0.9}
                            className={classes.noPadding}
                        />
                    </span>
                </Tooltip>
            </div>
        </>
    );
};

export default LayerContextMenu;
