// **
// * Used to display a loading spinner
// *
// */

import React, { Component } from "react";
import classes from "./Spinner.module.css";

interface SpinnerProps {
    removeSpinner?: () => void;
    zIndex?: number;
    timeoutLength?: number;
}

class Spinner extends Component<SpinnerProps> {
    componentDidMount(): void {
        window.setTimeout(() => {
            if (this.props.removeSpinner) this.props.removeSpinner();
        }, this.props.timeoutLength ?? 500);
    }

    styles = {
        zIndex: this.props.zIndex,
    };

    render() {
        return <div className={classes.Loading} style={this.styles}></div>;
    }
}

export default Spinner;
