import { InsightsSchema } from "../crud/insightsCRUD";

export const hasNoAffectedProperties = (
    insightsData: InsightsSchema["insights"],
) => {
    return (
        Object.values(insightsData).reduce(
            (prev, curr) => prev + curr.length,
            0,
        ) === 0
    );
};
