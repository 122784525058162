import { makeAPICall_StaticUrl } from "../utils/APIHelpers";
import { genericAPIResponse } from "./genericResponse";

export interface ScopeSchema {
    industry: string;
    access: string;
    desc: string;
    id: string;
    updated_at: number;
    created_at: number;
}

export interface APIKeySchema {
    api_key: string;
}

type ScopeSchemaBase = Omit<ScopeSchema, "id" | "updated_at" | "created_at">;

export const scopesCreate = makeAPICall_StaticUrl<ScopeSchema, ScopeSchemaBase>(
    "/auth/scopes/create",
    "POST",
);
export const scopesGetById = async (token: string, scopeId: string) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/auth/scopes/get/id/${scopeId}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
    return await res.json();
};
export const scopesGetByIndustryAndAccess = async (
    token: string,
    industry: string,
    access: string,
) => {
    let res = await fetch(
        `${
            import.meta.env.VITE_API_ROOT
        }/scopes/get/industry/${industry}/access/${access}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        },
    );
    return await res.json();
};
export const scopesGetAll =
    makeAPICall_StaticUrl<genericAPIResponse<ScopeSchema[]>>("/scopes/get/all");
export const scopesUpdate = makeAPICall_StaticUrl<ScopeSchema, ScopeSchema>(
    "/scopes/put",
    "PUT",
);

export const generateAPIKey = makeAPICall_StaticUrl<
    genericAPIResponse<APIKeySchema>
>("/api_key/create", "POST");
export const getAPIKey = makeAPICall_StaticUrl<
    genericAPIResponse<APIKeySchema>
>("/api_key/get", "GET");
export const revokeAPIKey = async (token: string, apiKey: string) => {
    let res = await fetch(
        `${import.meta.env.VITE_API_ROOT}/api_key/revoke?api_key=${apiKey}`,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            method: "PUT",
        },
    );
    return await res.json();
};
