import React, { Component } from "react";
import classes from "../SectionRow/SectionRow.module.css";
import cx from "classnames";
import { IconButton } from "../Button/IconButton";

/**
 * To be used for any menu
 * composed of sections
 * each with buttons annotated
 * by some label
 */

type SectionItemConfig = {
    label: string;
    iconComponent: JSX.Element;
    onClick: () => void;
    active: boolean;
    id?: string;
};
type SectionConfig = {
    items: Array<SectionItemConfig>;
};

class SectionRow extends Component<SectionConfig> {
    render() {
        return (
            <div className={classes.Container}>
                <div className={classes.SectionRowTopSpacer}/>
                <div className={classes.SectionRowContainer}>
                    {this.props.items.map(
                        (item: SectionItemConfig, index: number) => (
                            <div key={index} className={cx(classes.SectionRowItemBG,{ [classes.Active]: item.active})}>
                                <IconButton
                                    onClick={() => item.onClick()}
                                    active={item.active}
                                    id={item.id}
                                    iconComponent={item.iconComponent}
                                    label={item.label}
                                    activeClassName={classes.Active}
                                    className={cx(
                                        classes.SectionRowItem
                                    )}
                                />
                            </div>
                        ),
                    )}
                </div>
            </div>

        );
    }
}

export default SectionRow;
