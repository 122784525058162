import React from "react";
import ReactDOM from "react-dom";
import cx from "classnames";
import classes from "./ConfirmModal.module.css";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import Button from "../Button/Button";

interface ConfirmModalProps {
    title?: string;
    onConfirm: () => void;
    onCancel: () => void;
}

export default function ConfirmModal(props: ConfirmModalProps) {
    return ReactDOM.createPortal(
        <div className={cx(classes.Overlay)}>
            <div className={classes.Modal}>
                <div className={classes.CloseButton} onClick={props.onCancel}>
                    <Icon path={mdiClose} />
                </div>
                <div className={classes.Header}>
                    <p>{props.title}</p>
                </div>
                <div className={classes.Buttons}>
                    <Button type="positive" onClick={props.onConfirm}>
                        Confirm
                    </Button>
                    <Button type="negative" onClick={props.onCancel}>
                        Cancel
                    </Button>
                </div>
            </div>
        </div>,
        document.getElementById("modal-root")!,
    );
}
