import React, { FC, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import AlertContainer from "../_Library/AlertContainer/AlertContainer";
import DefaultProviders from "./DefaultProviders";
import CookieBanner from "./Permissions/CookieBanner/CookieBanner";
import ThemeProvider from "./ThemeProvider";
import { NEW_THEME_COOKIE, getCookie } from "utils/Cookies";
import { Notifications } from "@mantine/notifications";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { IntercomProvider } from "react-use-intercom";
import { load } from "hooks/useAnalytics/useAnalytics";

const App: FC = () => {
    useEffect(() => {
        if (load) {
            load();
        }
    });


    let htmlElem = document.getElementsByTagName("html")[0];
    htmlElem.dataset["theme"] = getCookie(NEW_THEME_COOKIE) ?? "light";
    return (
        <BrowserRouter>
            <QueryClientProvider client={new QueryClient()}>
                <DefaultProviders>
                    <IntercomProvider appId={import.meta.env.VITE_INTERCOM_APP_ID}>
                        <ThemeProvider>
                            <Notifications position="top-right" style={{marginTop: "var(--nav-height)"}}/>
                            <AlertContainer />
                            <Router />
                            <CookieBanner />
                        </ThemeProvider>
                    </IntercomProvider>
                </DefaultProviders>
                {
                (import.meta.env.VITE_ENVIRONMENT === "local" 
                || import.meta.env.VITE_ENVIRONMENT === "sandbox")
                    && <ReactQueryDevtools initialIsOpen={false}/>
                }
            </QueryClientProvider>
            <div id={"modal-root"} />
        </BrowserRouter>
    );
};

export default App;
