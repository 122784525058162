/**
 * Component for rendering some JSON nicely
 */

import React, { Component, ReactNode } from "react";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import cx from "classnames";

import Icon from "@mdi/react";

import classes from "./DataTree.module.css";
import { isURL } from "../../../../utils/URLs";

export interface OwnProps {
    data: any;
}

export interface StateProps {}

export interface DispatchProps {}

export type DataTreeProps = OwnProps & StateProps & DispatchProps;

interface DataTreeState {
    expanded: boolean;
}

class DataTree extends Component<DataTreeProps, DataTreeState> {
    state: DataTreeState = {
        expanded: false,
    };

    handleClick = () => {
        this.setState((state) => {
            return { expanded: !state.expanded };
        });
    };

    renderTree = (): ReactNode => {
        if (!this.props.data) return null;

        let type = typeof this.props.data;

        switch (type) {
            case "undefined":
            case "boolean":
            case "number":
            case "string":
                return (
                    <div title={this.props.data} className={classes.Element}>
                        {isURL(this.props.data) ? (
                            <a
                                href={this.props.data}
                                target={"_blank"}
                                rel={"noreferrer"}
                            >
                                {this.props.data}
                            </a>
                        ) : (
                            this.props.data
                        )}
                    </div>
                );

            case "object":
                let isArray = Array.isArray(this.props.data);

                if (isArray) {
                    let elements = this.props.data.map(
                        (element: any, index: number) => {
                            return <DataTree key={index} data={element} />;
                        },
                    );

                    return <div className={classes.List}>{elements}</div>;
                } else {
                    // is dict

                    let elements = Object.keys(this.props.data).map(
                        (key, index) => {
                            let element = this.props.data[key];
                            return (
                                <DataTree
                                    key={index}
                                    data={key + ": " + element}
                                />
                            );
                        },
                    );

                    let path = this.state.expanded
                        ? mdiChevronUp
                        : mdiChevronDown;

                    return (
                        <div
                            className={cx(classes.DropdownContainer)}
                            onClick={this.handleClick}
                        >
                            <div
                                className={cx(classes.Dropdown, {
                                    [classes.Hidden]: !this.state.expanded,
                                })}
                            >
                                {elements}
                            </div>
                            <Icon path={path} />
                        </div>
                    );
                }
        }
    };

    render() {
        return this.renderTree();
    }
}

export default DataTree;
