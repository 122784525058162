import React, { FC, useState } from "react";
import classes from "./AccountDropdown.module.css";
import { Text } from "@mantine/core";
import ThemeToggle from "../../../_Library/ThemeToggle/ThemeToggle";
import { useOktaAuth } from "@okta/okta-react";
import { useHistory } from "react-router-dom";
import InitialsIcon from "../../../_Library/InitialsIcon/InitialsIcon";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { getStoreAtNamespaceKey } from "../../../../store/storeSelectors";
import ScrollableText from "../../../_Library/ScrollableText/ScrollableText";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import AccountModal from "../AccountModal/AccountModal";

interface StateProps {
    closeDropdown: () => void;
    setAccountMenuVisible: any;
}

const AccountDropdown: FC<StateProps> = ({setAccountMenuVisible}) => {
    const { authState, oktaAuth } = useOktaAuth();
    const history = useHistory();
    const user = useSelector(
        (state: RootState) => getStoreAtNamespaceKey(state, "user").user,
    );

    const signOut = async () => {
        if (authState?.isAuthenticated) {
            await oktaAuth.revokeAccessToken();
            await oktaAuth.closeSession();
            history.push("/login");
        } else {
            history.push("/login");
        }
    };

    const { trackUserEvent } = useAnalytics();

    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <div className={classes.DropdownContainer}>
            <div className={classes.UserCompany}>
                <ScrollableText text={user?.company_name || ""} />
            </div>
            <div className={classes.InitialIconContainer}>
                <InitialsIcon
                    width={6}
                    firstName={user?.first_name}
                    lastName={user?.last_name}
                />
            </div>
            <div className={classes.UserInfo}>
                <ScrollableText
                    className={classes.UserInfoName}
                    text={user?.first_name + " " + user?.last_name}
                />
                <ScrollableText text={user!.email!} />
                <div
                    onClick={() => {
                        trackUserEvent({
                            name: "view_profile_clicked",
                        });
                        setAccountMenuVisible(false);
                    }}
                >
                    
                    <p className={classes.Link} onClick={() => {
                        setModalOpen(true)
                        
                    }}>
                        <Text td="underline" className={classes.textUnderline}>View Profile</Text>
                    </p>
                    
                </div>
            </div>
            <div className={classes.ActionBar}>
                <div className={classes.ThemeToggle}>
                    <ThemeToggle />
                </div>
                <p
                    className={classes.LogoutButton}
                    onClick={() => {
                        trackUserEvent({
                            name: "sign_out_clicked",
                        });
                        signOut();
                    }}
                >
                    Sign out
                </p>
            </div>
            {modalOpen && <AccountModal closeModal={closeModal} user={user}/>}
        </div>
    );
};

export default AccountDropdown;
