import { ViewportProps } from "react-map-gl";

import {
    ADD_CUSTOM_LAYER,
    AddCustomLayerPayload,
    InteractionModeType,
    REMOVE_CUSTOM_LAYER,
    RemoveCustomLayerPayload,
    SET_ACTIVE_TAB,
    SET_BASEMAP,
    SET_CENTER,
    SET_CLICKED_FEATURE_PROPERTIES,
    SET_EVENT_TYPE,
    SET_GROUP_VISIBILITY,
    SET_HIGHLIGHTED_LAYER,
    SET_INTERACTION_MODE,
    SET_LAYER_FILTER,
    SET_LAYER_VIEW,
    SET_LAYER_VISIBILITY,
    SET_LEGEND_POPUP,
    SET_MAP_MARKER_LOCATION,
    SET_MAP_TYPE,
    SET_MOUSE_POSITION,
    SET_VIEWPORT,
    SetActiveTabPayload,
    SetBasemapPayload,
    SetEventTypePayload,
    SetGroupVisibilityPayload,
    SetHighlightedLayerPayload,
    SetLayerFilterPayload,
    SetLayerViewPayload,
    SetLayerVisibilityPayload,
    SetLegendPopupPayload,
    SetMapMarkerLocationPayload,
    SetMapTypePayload,
    TOGGLE_LOCATION_LABELS,
    TOGGLE_MENU,
    SET_CURRENT_DATE,
    SET_DASHBOARD_VIEW,
    SetDashboardViewPayload,
    SET_LAYERS_CONFIG,
    LayersConfig,
    SET_REPORT_IS_PREVIEW,
    SetCenterPayload,
} from "./reportTypes";
import { makeActionWithPayload } from "utils/ReduxHelpers";

export const setLayerConfig = makeActionWithPayload<
    typeof SET_LAYERS_CONFIG,
    LayersConfig
>(SET_LAYERS_CONFIG);

export const setCenter = makeActionWithPayload<
    typeof SET_CENTER,
    SetCenterPayload
>(SET_CENTER);
export const setViewport = makeActionWithPayload<
    typeof SET_VIEWPORT,
    ViewportProps
>(SET_VIEWPORT);
export const setLayerVisibility = makeActionWithPayload<
    typeof SET_LAYER_VISIBILITY,
    SetLayerVisibilityPayload
>(SET_LAYER_VISIBILITY);
export const setGroupVisibility = makeActionWithPayload<
    typeof SET_GROUP_VISIBILITY,
    SetGroupVisibilityPayload
>(SET_GROUP_VISIBILITY);
export const setActiveTab = makeActionWithPayload<
    typeof SET_ACTIVE_TAB,
    SetActiveTabPayload
>(SET_ACTIVE_TAB);
export const setHighlightedLayer = makeActionWithPayload<
    typeof SET_HIGHLIGHTED_LAYER,
    SetHighlightedLayerPayload | null
>(SET_HIGHLIGHTED_LAYER);
export const setLegendPopup = makeActionWithPayload<
    typeof SET_LEGEND_POPUP,
    SetLegendPopupPayload | null
>(SET_LEGEND_POPUP);
export const setClickedFeatureProperties = makeActionWithPayload<
    typeof SET_CLICKED_FEATURE_PROPERTIES,
    { [key: string]: any[] }
>(SET_CLICKED_FEATURE_PROPERTIES);
export const setMousePosition = makeActionWithPayload<
    typeof SET_MOUSE_POSITION,
    number[]
>(SET_MOUSE_POSITION);
export const setBasemap = makeActionWithPayload<
    typeof SET_BASEMAP,
    SetBasemapPayload
>(SET_BASEMAP);
export const setInteractionMode = makeActionWithPayload<
    typeof SET_INTERACTION_MODE,
    InteractionModeType
>(SET_INTERACTION_MODE);
export const addCustomLayer = makeActionWithPayload<
    typeof ADD_CUSTOM_LAYER,
    AddCustomLayerPayload
>(ADD_CUSTOM_LAYER);
export const removeCustomLayer = makeActionWithPayload<
    typeof REMOVE_CUSTOM_LAYER,
    RemoveCustomLayerPayload
>(REMOVE_CUSTOM_LAYER);
export const setMapType = makeActionWithPayload<
    typeof SET_MAP_TYPE,
    SetMapTypePayload
>(SET_MAP_TYPE);
export const setLayerView = makeActionWithPayload<
    typeof SET_LAYER_VIEW,
    SetLayerViewPayload
>(SET_LAYER_VIEW);
export const setLayerFilter = makeActionWithPayload<
    typeof SET_LAYER_FILTER,
    SetLayerFilterPayload
>(SET_LAYER_FILTER);
export const setEventType = makeActionWithPayload<
    typeof SET_EVENT_TYPE,
    SetEventTypePayload
>(SET_EVENT_TYPE);
export const setMapMarkerLocation = makeActionWithPayload<
    typeof SET_MAP_MARKER_LOCATION,
    SetMapMarkerLocationPayload
>(SET_MAP_MARKER_LOCATION);
export const toggleLocationLabels = makeActionWithPayload<
    typeof TOGGLE_LOCATION_LABELS,
    boolean
>(TOGGLE_LOCATION_LABELS);
export const toggleMenu = makeActionWithPayload<typeof TOGGLE_MENU, boolean>(
    TOGGLE_MENU,
);
export const setCurrentDate = makeActionWithPayload<
    typeof SET_CURRENT_DATE,
    number
>(SET_CURRENT_DATE);
export const setDashboardView = makeActionWithPayload<
    typeof SET_DASHBOARD_VIEW,
    SetDashboardViewPayload
>(SET_DASHBOARD_VIEW);
export const setReportIsPreview = makeActionWithPayload<
    typeof SET_REPORT_IS_PREVIEW,
    boolean
>(SET_REPORT_IS_PREVIEW);
