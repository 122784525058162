//@ts-nocheck

import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import classes from "./OktaSigninWidget.module.css";

const OktaSignInWidget = ({ config, onSuccess, onError }) => {
    const widgetRef = useRef();
    useEffect(() => {
        if (!widgetRef.current) return false;

        const widget = new OktaSignIn(config);

        widget
            .showSignInToGetTokens({
                el: widgetRef.current,
            })
            .then(onSuccess)
            .catch(onError);

        return () => widget.remove();
    }, [config, onSuccess, onError]);

    return (
        <div className={classes.SigninWrapper}>
            <div>
                <div ref={widgetRef}></div>
                <a
                    target="_blank"
                    className={classes.DemoLink}
                    rel="noopener noreferrer"
                    href="https://connect.mckenzieintelligence.com/en-gb/request-a-demo "
                >
                    Don't have access to Geo? Request a demo
                </a>
            </div>
        </div>
    );
};
export default OktaSignInWidget;
