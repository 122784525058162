import { useOktaAuth } from "@okta/okta-react";
import { genericAPIResponse } from "crud/genericResponse";
import { InsightsSchema, RevisionSchema } from "crud/insightsCRUD";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
    removeInsightsData,
    setFetchingStatus,
    setInsightsLoaded,
    setSelectedPortfolio,
    setRevisions,
    setSelectedRevisionId,
} from "store/insights/insightsActions";
import { RootState } from "store/store";
import { getStoreAtNamespaceKey } from "store/storeSelectors";
import { setAlert } from "store/system/systemActions";
import { PortfolioItem } from "store/user/userTypes";
import {
    ExtendedAccessManagementUser,
    GEOUserWithPortfolios,
} from "types/auth";
import { isUnsuccessfulAPIResponse } from "utils/TypeGuards";
import { replaceSearchParams } from "utils/URLs";
import { loadInsightsPackage } from "../../../../../store/insights/insightsActions";
import { ADD_POLICY_INSIGHTS } from "store/insights/insightsTypes";
import { PerilType } from "store/system/systemTypes";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { formatExposureClaims } from "./utils";
import { useCurrentEvent } from "hooks/useCurrentEvent";
import { useUserMeAccessQuery } from "crud/me_access";

type Props = {
    user: ExtendedAccessManagementUser | null;
    eventId: string;
};

type ReturnType = {
    handlePortfolioChange: (value: string) => void;
    handleRevisionChange: (revisionName: string, peril: PerilType) => void;
    selectedPortfolio: PortfolioItem | null | undefined;
    selectedRevisionPublished: boolean;
    setSelectedRevisionPublished: React.Dispatch<React.SetStateAction<boolean>>;
    revisions: RevisionSchema[];
    setRevisions: (revisions: RevisionSchema[]) => void;
    fetchInsights: (
        portfolioId: string,
        insightId: string | null,
    ) => Promise<void>;
    refreshInsights: (revisionId?: string) => Promise<void>;
};

const findPortfolioOrFirst = (
    portfolioName: string,
    user: GEOUserWithPortfolios | null,
): PortfolioItem | undefined => {
    const portfolios = user?.portfolios ?? [];
    let portfolio = portfolios.find(
        (portfolio: PortfolioItem) => portfolio.name === portfolioName,
    );
    if (!portfolio) {
        portfolio = portfolios[0];
    }
    return portfolio;
};

export default function usePortfolioRevisions({
    user,
    eventId,
}: Props): ReturnType {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { oktaAuth } = useOktaAuth();
    const portfolios = user?.portfolios ?? [];
    const [searchParams, setSearchParams] = useState<URLSearchParams>(
        new URLSearchParams(location.search),
    );
    const portfolioQuery = searchParams.get("portfolio");
    const currentEvent = useCurrentEvent();
    const selectedPortfolio = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedPortfolio ||
            user?.portfolios[0] ||
            null,
    );
    const selectedPeril = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").selectedPeril,
    );
    const revisions = useSelector(
        (state: RootState) =>
            getStoreAtNamespaceKey(state, "insights").revisions,
    );
    const [selectedRevisionPublished, setSelectedRevisionPublished] =
        useState<boolean>(false);
    const extendedController = useRef<AbortController>(new AbortController());
    const revisionController = useRef<AbortController>(new AbortController());
    const portfolio =
        findPortfolioOrFirst(portfolioQuery!, user) || selectedPortfolio;
    const portfolioName = portfolio?.name || "";
    const fetchRevisions = useCallback(
        async (portfolioId?: string) => {
            try {
                if (!portfolioId) return;
                let token = oktaAuth.getAccessToken();
                if (revisionController) {
                    revisionController.current.abort();
                }
                revisionController.current = new AbortController();
                const portfolioIdParam = `?portfolio_id=${portfolioId}`;
                const res = await fetch(
                    `${
                        import.meta.env.VITE_API_ROOT
                    }/events/${eventId}/insights/geo_revisions/${portfolioIdParam}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        signal: revisionController.current.signal,
                    },
                );
                return (await res.json()) as genericAPIResponse<
                    RevisionSchema[]
                >;
            } catch (error) {
                if ((error as any).name === "AbortError") {
                    return;
                }
                dispatch(
                    setAlert({
                        message: "Failed to fetch insights",
                        type: "Error",
                    }),
                );
            }
        },
        [dispatch, oktaAuth, eventId],
    );

    const { data: permissionData } = useUserMeAccessQuery();

    const fetchInsights = useCallback(
        async (portfolioId: string, insightId: string | null = null) => {
            try {
                if (!portfolioId) return;
                let token = oktaAuth.getAccessToken();
                let insightsUser =
                    user?.portfolios && user?.portfolios.length > 0;
                if (!insightsUser) {
                    return;
                }
                let superUser = user?.is_admin;
                const portfolioSearchParams = new URLSearchParams();
                portfolioSearchParams.set("portfolio_id", portfolioId);
                const insightsSpecificParams = new URLSearchParams();
                insightsSpecificParams.set("portfolio_id", portfolioId);
                if (insightId) {
                    portfolioSearchParams.set("revision_id", insightId);
                    insightsSpecificParams.set("revision_id", insightId);
                }
                dispatch(setFetchingStatus(true));
                dispatch(setInsightsLoaded(false));
                dispatch(removeInsightsData());

                if (extendedController) {
                    extendedController.current.abort();
                }
                extendedController.current = new AbortController();
                if (!portfolioSearchParams.toString()) {
                    return;
                }

                const res = await fetch(
                    `${
                        import.meta.env.VITE_API_ROOT
                    }/events/${eventId}/insights/extended/?${insightsSpecificParams.toString()}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        signal: extendedController.current.signal,
                    },
                )
                    .then((res) => {
                        return res.json();
                    })
                    .then((data) => {
                        return data as genericAPIResponse<InsightsSchema>;
                    });

                if (res && !isUnsuccessfulAPIResponse(res)) {
                    if (superUser) {
                        setSelectedRevisionPublished(res.data.published);
                    }
                }

                if (permissionData?.has_policy_access) {
                    fetch(
                        `${
                            import.meta.env.VITE_API_ROOT
                        }/events/${eventId}/insights/with_policies/?${portfolioSearchParams.toString()}`,
                        {
                            method: "get",
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        },
                    )
                        .then((res) => {
                            return res.json();
                        })
                        .then((json) => {
                            if (json.success) {
                                dispatch({
                                    type: ADD_POLICY_INSIGHTS,
                                    payload: json.data,
                                });
                            } else {
                                dispatch({
                                    type: ADD_POLICY_INSIGHTS,
                                    payload: [],
                                });
                            }
                        });
                }
                dispatch(
                    loadInsightsPackage({
                        insightsData: res.data!,
                        eventId: eventId,
                        eventType: currentEvent!.type,
                        portfolio: portfolioName!,
                    }),
                );
                dispatch(setFetchingStatus(false));
            } catch (e) {
                let exception = e as string;
                // Aborting the fetch triggers a DomException.
                // It is not an error, so is ignored below.
                if (
                    exception.hasOwnProperty("includes") &&
                    !exception.includes("DomException")
                ) {
                    dispatch(
                        setAlert({
                            message: "Failed to fetch insights",
                            type: "Error",
                        }),
                    );
                    dispatch(
                        loadInsightsPackage({
                            insightsData: null,
                            eventId: eventId,
                            eventType: currentEvent!.type,
                            portfolio: portfolioName!,
                        }),
                    );
                    dispatch(setFetchingStatus(false));
                }
            }
        },
        [
            oktaAuth,
            user?.portfolios,
            user?.is_admin,
            dispatch,
            eventId,
            currentEvent,
            portfolioName,
            permissionData?.has_policy_access,
        ],
    );

    const createOrUpdateSearchParams = useCallback(
        (params: Record<string, string>) => {
            history.push({
                search: replaceSearchParams(location.search, params),
            });
            setSearchParams(new URLSearchParams(location.search));
        },
        [location.search, history],
    );

    const fetchAndSetRevisions = useCallback(async () => {
        if (user && portfolio && currentEvent?.type && eventId) {
            const data = await fetchRevisions(portfolio?.id);
            if (data?.data && data.success) {
                const perilTypes = new Set<PerilType>();
                data.data.forEach((revision) => {
                    perilTypes.add(revision.peril);
                });

                dispatch(setRevisions(data.data));
            } else {
                dispatch(setRevisions([]));
                dispatch(
                    loadInsightsPackage({
                        insightsData: null,
                        eventId: eventId,
                        eventType: currentEvent.type,
                        portfolio: portfolioName!,
                    }),
                );
            }
        }
    }, [
        user,
        portfolio,
        currentEvent,
        eventId,
        fetchRevisions,
        dispatch,
        portfolioName,
    ]);

    // On page load & when the portfolio
    // changes, fetch the insights
    useEffect(() => {
        fetchAndSetRevisions();
    }, [fetchAndSetRevisions]);

    // Everytime the peril changes fetch insights
    useEffect(() => {
        if (
            user &&
            portfolio &&
            currentEvent?.type &&
            eventId &&
            selectedPeril
        ) {
            const filteredRevs = revisions.filter(
                (revision) => revision.peril === selectedPeril,
            );
            const formattedRevs = formatExposureClaims(filteredRevs);
            if (formattedRevs.length) {
                fetchInsights(portfolio?.id, formattedRevs[0].id);
            }
        }
    }, [
        eventId,
        currentEvent,
        fetchInsights,
        portfolio,
        user,
        selectedPeril,
        revisions,
        dispatch,
    ]);

    // Everytime selected portfolio changes, reflect this in query param
    useEffect(() => {
        if (selectedPortfolio?.name) {
            createOrUpdateSearchParams({ portfolio: selectedPortfolio!.name });
        } else {
            let portfolioOrFirst = findPortfolioOrFirst(portfolioQuery!, user)
                ?.name;
            if (portfolioOrFirst) {
                createOrUpdateSearchParams({ portfolio: portfolioOrFirst });
            }
        }
    }, [
        createOrUpdateSearchParams,
        dispatch,
        portfolio,
        portfolioQuery,
        selectedPortfolio,
        user,
    ]);

    useEffect(() => {
        //This removes policy data on unmount
        return () => {
            dispatch({
                type: ADD_POLICY_INSIGHTS,
                payload: [],
            });
        };
    }, [dispatch]);

    const { trackUserEventWithCurrentEvent } = useAnalytics();

    const handlePortfolioChange = (value: string) => {
        /**
         * When a portfolio is selected, fetch insights and revisions for that portfolio
         * and set the selected portfolio in the store
         * Additionally, set the selected revision to the first revision
         */

        trackUserEventWithCurrentEvent({
            name: "portfolio_selected",
            payload: {
                from: selectedPortfolio?.name || "",
                to: value,
            },
        });

        portfolios.forEach(async (portfolio: PortfolioItem) => {
            if (portfolio.name === value) {
                dispatch(
                    setSelectedPortfolio({
                        selectedPortfolio: portfolio,
                    }),
                );
            }
        });
    };

    const handleRevisionChange = async (
        revisionLabel: string,
        peril: PerilType,
    ) => {
        const revisionMatchingValue = revisions
            .filter((revision) => revision.peril === peril.toLowerCase())
            .find((revision) =>
                revision.path.endsWith(
                    `/${revisionLabel.split(" (unpublished)")[0]}`,
                ),
            );

        dispatch(setSelectedRevisionId(revisionMatchingValue?.id!));
        if (portfolio?.id) {
            await fetchInsights(portfolio!.id, revisionMatchingValue?.id);
        }
    };

    return {
        handlePortfolioChange,
        handleRevisionChange,
        selectedPortfolio,
        revisions,
        setRevisions,
        selectedRevisionPublished,
        setSelectedRevisionPublished,
        fetchInsights,
        refreshInsights: fetchAndSetRevisions,
    };
}
