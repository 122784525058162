import React, { ReactNode, useState, useEffect, useRef, useCallback } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import cx from "classnames";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { setRef } from "../../../store/ref/refActions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/store";
import { toggleModal } from "store/system/systemActions";

interface Props {
    children: ReactNode;
    closeModal: () => void;
    confirmOnCloseText?: string;
    height?: number;
    width?: number;
    title?: string;
    disableOverlayClose?: boolean;
    contentClassName?: string;
    containerClassName?: string;
}


export const Modal = ({
    children,
    closeModal,
    confirmOnCloseText,
    height = 70,
    width = 50,
    title,
    disableOverlayClose,
    containerClassName,
    contentClassName,
}: Props) => {
    const [confirmClose, setConfirmClose] = useState(false);
    const modalRef = useRef(null); 
    const modalOpen = useSelector(
        (state: RootState) => state.system!.modalOpen
    )
    const dispatch = useDispatch();

    const handleClose = useCallback(() => {
        if (confirmOnCloseText) {
            setConfirmClose(true);
        } else {
            closeModal();
        }
    }, [confirmOnCloseText, closeModal]);

    useEffect(() => {
        if (modalOpen !== null) handleClose();
    }, [modalOpen, handleClose]);

    useEffect(() => {
        dispatch(setRef({
            refName: "modalRef",
            ref: modalRef.current,
        }));
        return () => { 
            setRef({
                refName: "modalRef",
                ref: null,
            });
            dispatch(
                toggleModal(null)
            )
        };
    }, [dispatch, modalRef]);

    const Styles = {
        width: width + "%",
        height: height + "%",
    };
    return ReactDOM.createPortal(
        <>
            {confirmClose && (
                <ConfirmModal
                    onCancel={() => setConfirmClose(false)}
                    title={confirmOnCloseText}
                    onConfirm={() => {
                        closeModal();
                        setConfirmClose(false);
                    }}
                />
            )}
            <div
                className={classes.Overlay}
                onClick={(e) => {
                    e.stopPropagation();
                    !disableOverlayClose && closeModal();
                }}
            >
                <div
                    ref={modalRef}
                    className={cx(classes.Modal, containerClassName)}
                    style={Styles}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                >
                    <div onClick={handleClose} className={classes.CloseButton}>
                        <Icon path={mdiClose} />
                    </div>

                    <div className={classes.Header}>
                        {title && (
                            <p className={classes.Title}>{title}</p>
                        )}
                    </div>
                    <div
                        className={cx(classes.Content, contentClassName)}
                    >
                        {children}
                    </div>
                </div>
            </div>
        </>,
        document.body
    );
};

export default Modal;