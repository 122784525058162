/**
 * Generates a legend icon from the layer paint specified in the system. For raster returns mdi image icon.
 * This follows the mapbox style spec.
 * Also used to generates an icon from the map feature OnClick which presents the paint in a {r: , g: , b: } format.
 */

import React, { CSSProperties } from "react";
import classes from "./Line.module.css";

export interface OwnProps {
    color: string;
    borderStyle: "solid" | "dotted";
    width?: number;
}

const Line: React.FC<OwnProps> = ({
    color,
    borderStyle,
    width = 2,
}: OwnProps) => {
    let style: CSSProperties = {
        borderColor: color,
        borderStyle,
        borderWidth: 0,
        borderBottomWidth: width,
    };

    return <div className={classes.Line} style={style} />;
};

export default Line;
