import React from "react";
import {
    useAnalytics,
    useAnalyticsReturnType,
} from "hooks/useAnalytics/useAnalytics";

export interface withAnalyticsProps {
    analytics: useAnalyticsReturnType;
}

const withAnalytics = <T extends withAnalyticsProps>(
    Component: React.ComponentType<T>,
) => {
    return function WrappedComponent(props: any) {
        const analytics = useAnalytics();
        return <Component {...props} analytics={analytics} />;
    };
};

export default withAnalytics;
