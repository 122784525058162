import React, { ReactNode } from "react";

import classes from "./ControlWrapper.module.css";

export interface OwnProps {
    position: "top-left" | "top-right" | "bottom-right" | "bottom-left";
    children: ReactNode;
}

type ControlWrapperProps = OwnProps;

const ControlWrapper: React.FC<ControlWrapperProps> = (
    props: ControlWrapperProps,
) => {
    let className: string;

    switch (props.position) {
        case "top-left":
            className = classes.TopLeft;
            break;
        case "top-right":
            className = classes.TopRight;
            break;
        case "bottom-right":
            className = classes.BottomRight;
            break;
        case "bottom-left":
            className = classes.BottomLeft;
            break;
    }

    return <div className={className}>{props.children}</div>;
};

export default ControlWrapper;
