import cx from "classnames";
import React from "react";

type ButtonProps = {
    className: string;
    activeClassName?: string;
    onClick: () => void;
    active: boolean;
    id?: string;
    iconComponent: JSX.Element;
    label: string;
};

export const IconButton: React.FC<ButtonProps> = ({
    className,
    activeClassName,
    onClick,
    active,
    id,
    iconComponent,
    label,
}) => {
    return (
        <div
            className={cx(className, { [activeClassName ?? '']: active })}
            onClick={onClick}
            id={id ? id : undefined}
        >
            {iconComponent}
            <span>{label}</span>
        </div>
    );
};
