import React, { PureComponent } from "react";
import cx from "classnames";

import classes from "./ButterflyLogo.module.css";

export interface OwnProps {
    animated?: boolean;
    color?: string;
    height?: number;
    animationSpeed?: number;
}

type Props = OwnProps;

interface ButterflyLogoState {
    animate: boolean;
}

class ButterflyLogo extends PureComponent<Props, ButterflyLogoState> {
    state = {
        animate: false,
    };

    private interval: number = -1;

    componentDidMount(): void {
        if (this.props?.animated) {
            this.interval = window.setInterval(() => {
                this.setState((state) => {
                    return { animate: !state.animate };
                });
            }, this.props.animationSpeed ?? 1500);
        }
    }

    componentWillUnmount(): void {
        if (this.interval !== -1) {
            window.clearInterval(this.interval);
        }
    }

    render() {
        return (
            <div
                style={{ height: this.props?.height ? this.props.height : 50 }}
            >
                <svg
                    version="1.1"
                    id="Butterfly"
                    x="0px"
                    y="0px"
                    viewBox="62 70 76 77"
                    fill={this.props?.color ? this.props.color : "currentColor"}
                    className={cx(classes.Logo, {
                        [classes.Flap]: this.state.animate,
                    })}
                >
                    <path
                        id="left_squ"
                        d="M82.9,125.6l0-16.6l16.6,0l0,16.6L82.9,125.6z"
                    />
                    <polygon
                        id="left_mid_tri"
                        points="65.2,109.1 81.5,109.1 81.6,125.4 "
                    />
                    <polygon
                        id="left_lower_tri"
                        points="82.8,143.3 82.8,127 99.1,127 "
                    />
                    <polygon
                        id="left_upper_tri"
                        points="65,107.7 65,74.4 98.2,107.7 "
                    />
                    <polygon
                        id="right_upper_tri"
                        points="101.7,107.7 135,74.4 135,107.7 "
                    />
                    <polygon
                        id="right_lower_tri"
                        points="118.1,125.7 101.5,109.2 134.6,109.2 "
                    />
                    <polygon
                        id="right_para"
                        points="100.9,110.5 117.4,127 117.4,143.2 100.9,126.9 "
                    />
                </svg>
            </div>
        );
    }
}

export default ButterflyLogo;
