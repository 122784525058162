export function generateHash(length: number = 10): string {
    return (Math.random() + 1).toString(36).substr(2, length);
}

export function hashString(input: any) {
    let hash = 0,
        i,
        chr;
    if (input === 0) return hash;
    for (i = 0; i < input.length; i++) {
        chr = input.charCodeAt(i);
        hash = (hash << 5) - hash + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
}
