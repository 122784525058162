import { StyleConfig_Style } from "crud/layerStylesCRUD";
import { useApiQuery } from "hooks/useAPI";

export function useInsightsLayerStylesAPI() {
    return useApiQuery<StyleConfig_Style[]>(
        "/report_layer_styles?group=insights",
        ["reportLayerStyles", "insights"],
        {
            staleTime: Infinity,
        },
    );
}
