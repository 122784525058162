import React from "react";
import classes from "./Leaderboard.module.css";
import ScrollableText from "../ScrollableText/ScrollableText";
import { mdiHelpCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import ReactTooltip from "react-tooltip";
import { css } from "@emotion/css";
import cx from "classnames"

interface LabelValue {
    label: string;
    value: number;
}

type Props = {
    data: LabelValue[];
    title?: string;
    rows: number;
    columns: number;
    helpTooltip?: string;
    noResultsMessage?: string;
};

export const Leaderboard = ({
    data,
    title,
    rows,
    columns,
    helpTooltip,
    noResultsMessage
}: Props) => {
    const splitDataIntoChunks = (
        data: LabelValue[],
        rows: number,
        columns: number,
    ) => {
        /**
         * Iterate over the data with columns, rows
         * If index > row * columns, we should exit
         * If index % rows === 0, we should push the column array
         * If index % rows !== 0, we should push the rows to the current column
         * If index === data.length - 1, we should push the last column
         */
        const chunks = [];
        let currentColumn: LabelValue[] = [];
        for (let i = 0; i < data.length; i++) {
            if (i !== 0 && i % rows === 0) {
                chunks.push(currentColumn);
                currentColumn = [];
            }
            currentColumn.push(data[i]);
            if (i === columns * rows - 1) {
                chunks.push(currentColumn);
                break;
            }
            if (i === (data.length - 1)) {
                chunks.push(currentColumn);
                break;
            }
        }
        return chunks;
    };
    const splitData = splitDataIntoChunks(data, rows, columns);


    const displayData = () => {
        if (data.length > 0) {
            return (
                <div
                    className={classes.LeaderboardContainer}
                    style={{ maxHeight: `${rows * columns + 1}rem` }}
                >
                    {
                        splitData.map((column, colIndex) => (
                            <div
                                key={`${colIndex}-stack`}
                                className={classes.EntryColumn}
                                style={{
                                    width: `${100 / columns}%`,
                                }}
                            >
                                {column.map((row, rowIndex) => (
                                    <div 
                                        className={
                                            cx(classes.Entry, css`&:nth-child(${rows}) {border-bottom: 0;}`)
                                        }
                                        key={`${colIndex}${rowIndex}-entry`}
                                    >
                                        <div className={classes.EntryLeftElements}>
                                            <p className={classes.ItemNumber}>
                                                {rowIndex + 1 + colIndex * rows}
                                            </p>
                                            <div className={classes.ItemDescription}>
                                                <ScrollableText text={row.label} />
                                            </div>
                                        </div>
                                        <p className={classes.ItemValue}>{row.value}</p>
                                    </div>
                                ))}
                            </div>
                        ))
                    }
                </div>        
            )
        } else {
            return (
                <div className={classes.NoResultsMessage}>
                    <p>{noResultsMessage}</p>
                </div>
            )
        }
        
    }

    return (
        <div>
            <div className={classes.LeaderboardTitleContainer}>
                <p>{title}</p>
                {helpTooltip && (
                    <Icon
                        path={mdiHelpCircleOutline}
                        size={1}
                        data-tip={helpTooltip}
                        data-for={"HelpTooltip"}
                    />
                )}
            </div>

           {displayData()}

            <ReactTooltip id={"HelpTooltip"} place={"left"} effect={"solid"} />
        </div>
    );
};
