import React, { useContext } from "react";
import Icon from "@mdi/react";
import {
    mdiEyeCheck,
    mdiEyeRemove,
    mdiDownload,
    mdiDownloadOff,
} from "@mdi/js";
import { DownloadsContext } from "components/Pages/Report/Report";
import classes from "../LayersTab.module.css";
import { ConfigMenuGroup } from "store/system/systemTypes";
import { Tooltip } from "@mantine/core";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";

interface GroupContextMenuProps {
    group: ConfigMenuGroup;
    onShowAll: () => void;
    onHideAll: () => void;
    downloadsAvailable?: boolean;
}

const GroupContextMenu: React.FC<GroupContextMenuProps> = ({
    group,
    onShowAll,
    onHideAll,
    downloadsAvailable = false,
}) => {
    const { setDownloadModalOpen, setOpenLayerId } =
        useContext(DownloadsContext);
    const { trackUserEventWithCurrentEvent } = useAnalytics();
    const handleDownload = () => {
        if (!downloadsAvailable) return;
        setDownloadModalOpen(true);
        setOpenLayerId(group.groupName);
        trackUserEventWithCurrentEvent({
            name: "group_layer_modal_download_clicked",
            payload: {
                group_name: group.groupName,
            },
        });
    };

    return (
        <>
            <div onClick={onShowAll} className={classes.ContextMenuItem}>
                <Icon path={mdiEyeCheck} size={1} />
                Show All Layers
            </div>
            <div onClick={onHideAll} className={classes.ContextMenuItem}>
                <Icon path={mdiEyeRemove} size={1} />
                Hide All Layers
            </div>
            <Tooltip
                label={
                    downloadsAvailable
                        ? "View downloads for this group"
                        : "Download not available"
                }
                position="bottom-start"
            >
                <div
                    onClick={handleDownload}
                    className={classes.ContextMenuItem}
                    style={{
                        cursor: downloadsAvailable ? "pointer" : "not-allowed",
                        opacity: downloadsAvailable ? 1 : 0.5,
                    }}
                >
                    <Icon
                        path={downloadsAvailable ? mdiDownload : mdiDownloadOff}
                        size={1}
                        color={
                            downloadsAvailable
                                ? "inherit"
                                : "var(--text-color-lo-cont)"
                        }
                    />
                    Download Layers
                </div>
            </Tooltip>
        </>
    );
};

export default GroupContextMenu;
