/**
 * Generates a legend icon from the layer paint specified in the system. For raster returns mdi image icon.
 * This follows the mapbox style spec.
 * Also used to generates an icon from the map feature OnClick which presents the paint in a {r: , g: , b: } format.
 */

import React from "react";
import * as MB from "mapbox-gl";

import Square from "./Square";
import { getColorFromPaint } from "../../../../../../../utils/PaintHelpers";

interface OwnProps {
    paint: MB.FillPaint;
}

const PolygonIcon: React.FC<OwnProps> = (props: OwnProps) => {
    let color = getColorFromPaint(
        props.paint,
        "fill-color" as keyof MB.AnyPaint,
        "#000000",
    );
    let borderColor = getColorFromPaint(
        props.paint,
        "fill-outline-color" as keyof MB.AnyPaint,
        color,
    );

    return <Square color={color} borderColor={borderColor} />;
};

export default PolygonIcon;
