/**
 * Displays the layer info for a clicked layer.
 * A table is created for each of the features selected.
 */

import React, { Component, ReactNode } from "react";
import startCase from "lodash/startCase";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import { RootState } from "../../../../../../../../store/store";
import {
    LayersConfig,
    MenuConfig,
    SetHighlightedLayerPayload,
} from "../../../../../../../../store/report/reportTypes";
import DataTree from "../../../../../DataTree/DataTree";
import classes from "./InfoTab.module.css";
import { getStoreAtNamespaceKey } from "../../../../../../../../store/storeSelectors";
import ScrollableText from "../../../../../../../_Library/ScrollableText/ScrollableText";
import { formatValue } from "store/insights/utils";

interface OwnProps {}

interface StateProps {
    menuConfig: MenuConfig;
    mapConfig: LayersConfig | undefined;
    highlightedLayer: SetHighlightedLayerPayload | null;
    clickedFeatureProperties: { [key: string]: any[] };
}

interface DispatchProps {}

type InfoTabProps = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class InfoTab extends Component<InfoTabProps> {
    renderRows = (propertyList: { [key: string]: any }) => {
        return Object.keys(propertyList)
            .filter(
                (propertyName) =>
                    propertyName !== "feature_id" &&
                    !propertyName.includes("MIS_"),
            )
            .map((propertyName) => {
                let result: string | number = propertyList[propertyName];

                return (
                    <tr key={propertyName}>
                        <td>
                            <ScrollableText text={startCase(propertyName)} />
                        </td>
                        <td className={classes.DataCol}>
                            <DataTree
                                data={formatValue(result, propertyName)}
                            />
                        </td>
                    </tr>
                );
            });
    };

    renderInfo = () => {
        const clickedFeatureProperties = this.props.clickedFeatureProperties;
        let propertiesList;
        let tableData = null;
        let featureCount = 0;

        if (this.props.highlightedLayer) {
            const { layerName } = this.props.highlightedLayer;

            if (this.props.mapConfig && clickedFeatureProperties?.[layerName]) {
                propertiesList = clickedFeatureProperties[layerName];
                featureCount = clickedFeatureProperties[layerName].length;

                if (propertiesList) {
                    tableData = propertiesList.map(
                        (
                            propertyList: { [key: string]: any },
                            index: number,
                        ) => {
                            const propertiesData: ReactNode[] = [];
                            propertiesData.push(
                                <>
                                    <table className={classes.InfoTable}>
                                        <colgroup>
                                            <col />
                                            <col className={classes.DataCol} />
                                        </colgroup>
                                        <thead>
                                            <tr>
                                                <th>Description</th>
                                                <th>Result</th>
                                            </tr>
                                        </thead>
                                        <tbody key={index}>
                                            {this.renderRows(propertyList)}
                                        </tbody>
                                    </table>
                                </>,
                            );

                            return propertiesData;
                        },
                    );
                }
            }
        }

        return (
            <div className={classes.InfoTab}>
                {this.props.highlightedLayer !== null ? (
                    <div className={classes.SelectedFeaturesContainer}>
                        <p className={classes.LayerTitle}>
                            {this.props.highlightedLayer.layerName}
                        </p>
                        <p className={classes.FeatureCount}>
                            {featureCount} feature{featureCount !== 1 && "s"}{" "}
                            shown
                        </p>
                        {tableData}
                    </div>
                ) : (
                    <div className={classes.SelectedFeaturesContainer}>
                        <p>No Features Selected</p>
                    </div>
                )}
                <ReactTooltip
                    id={"InfoTabTooltip"}
                    class={classes.Tooltip}
                    effect={"float"}
                    place={"left"}
                />
            </div>
        );
    };

    shouldComponentUpdate(nextProps: any) {
        return nextProps.menuConfig.activeTab === "Info";
    }

    render() {
        return <div>{this.renderInfo()}</div>;
    }
}

const mapStateToProps = (state: RootState) => ({
    menuConfig: getStoreAtNamespaceKey(state, "report").menuConfig,
    mapConfig: getStoreAtNamespaceKey(state, "report").layersConfig,
    highlightedLayer: getStoreAtNamespaceKey(state, "report").highlightedLayer,
    clickedFeatureProperties: getStoreAtNamespaceKey(state, "report")
        .clickedFeatureProperties,
});

export default withRouter(connect(mapStateToProps)(InfoTab));
