import { UseQueryResult } from '@tanstack/react-query';
import { useUserMeAccessQuery } from 'crud/me_access';
import React from 'react';
import { AccessInfo } from 'types/auth';

export interface WithUserMeAccessProps {
    userMeAccessQuery: UseQueryResult<AccessInfo, Error>;
}

export const withMeAccess = <P extends WithUserMeAccessProps>(
    WrappedComponent: React.ComponentType<P>
) => {
    return (props: Omit<P, keyof WithUserMeAccessProps>) => {
        const userMeAccessQuery = useUserMeAccessQuery();
        return <WrappedComponent {...(props as P)} userMeAccessQuery={userMeAccessQuery} />;
    };
};

export default withMeAccess;