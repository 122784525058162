import { Theme } from "assets/themes/Theme";

export const getCssVar = (variable_name: keyof Theme): string => {
    return getComputedStyle(document.body as Element).getPropertyValue(
        variable_name,
    );
};

export const setCssVar = (
    variable_name: keyof Theme,
    variable_value: string,
) => {
    document.body.style.setProperty(variable_name, variable_value);
};
