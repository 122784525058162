import React, { FC } from "react";
import classes from "./BasePage.module.css";

const BasePage: FC = ({ children }) => {
    return (
        <div className={classes.Container}>
            <div className={classes.BlueCircle} />
            <div className={classes.PinkCircle} />
            <div className={classes.PageContent}>{children}</div>
        </div>
    );
};

export default BasePage;
