/**
 * Used to remove some of the boilerplate on the topmost component in the tree. Usually Report.
 */

import React, { FunctionComponent, ReactNode } from "react";
import { Provider } from "react-redux";

import { getStore } from "store/store";

interface OwnProps {
    children: ReactNode;
}

type Props = OwnProps;

const DefaultProviders: FunctionComponent<Props> = ({ children }) => {

    return (
            <Provider store={getStore()}>{children}</Provider>
    );
};

export default DefaultProviders;
