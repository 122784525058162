/**
 *
 */

import React, { CSSProperties } from "react";

import classes from "./Circle.module.css";

export interface OwnProps {
    color: string;
    radius?: number;
    borderColor?: string;
    borderWidth?: number;
}

const Circle: React.FC<OwnProps> = ({
    color,
    borderColor = color,
    radius = 7.5,
    borderWidth = 0,
}: OwnProps) => {
    let style: CSSProperties = {
        width: radius * 2,
        height: radius * 2,
        backgroundColor: color,
        border: borderWidth + "px solid " + borderColor,
    };

    return <div className={classes.Circle} style={style} />;
};

export default Circle;
