import React, { FC } from "react";
import InsightsBanner from "../../../Dashboard/InsightsBanner/InsightsBanner";

interface InsightsBannerWrapperProps {
    loading?: boolean
    policy?: boolean
}

export const InsightsBannerWrapper: FC<InsightsBannerWrapperProps> = (props: InsightsBannerWrapperProps) => {
    return (
        <>
            <div
                style={{
                    padding: "2rem",
                    top: "calc(40vh - 25rem)",
                    height:"50rem",
                    width:"100%",
                    position: "absolute"
                }}
            >
                <InsightsBanner loading={props.loading} policy={props.policy}/>
            </div>
        </>
    );
};
