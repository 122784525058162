import { Reducer } from "redux";
import {
    ServiceActionTypes,
    ServiceState,
    SET_ALL_COMPANY_DATA,
    SET_ALL_GROUP_DATA,
    SET_ALL_USER_DATA,
    SET_EVENT_DATA,
    SET_GROUP_DATA,
    SET_LOADING_COMPANY_DATA,
    SET_LOADING_GROUP_DATA,
    SET_LOADING_USER_DATA,
    SET_REPORT_LAYER_STYLES_DATA,
    SET_REPORT_LAYER_TYPES_DATA,
    SET_USER_DATA,
    SetAllCompanyDataAction,
    SetAllGroupDataAction,
    SetAllUserDataAction,
    SetEventDataAction,
    SetGroupDataAction,
    SetLoadingCompanyDataAction,
    SetLoadingGroupDataAction,
    SetLoadingUserDataAction,
    SetReportLayerStylesDataAction,
    SetReportLayerTypesDataAction,
    SetUserDataAction,
} from "./serviceTypes";

const initState: ServiceState = {
    eventData: [],
    groupData: null,
    userData: null,
    allUserData: null,
    allGroupData: null,
    allCompanyData: null,
    loadingUserData: false,
    loadingGroupData: false,
    loadingCompanyData: false,
    reportLayerStylesData: {},
    reportLayerTypesData: [],
};

export const serviceReducer: Reducer<ServiceState, ServiceActionTypes> = (
    state = initState,
    action,
): ServiceState => {
    switch (action.type) {
        case SET_EVENT_DATA:
            return Reduce_setEventData(state, action);
        case SET_GROUP_DATA:
            return Reduce_setGroupData(state, action);
        case SET_USER_DATA:
            return Reduce_setUserData(state, action);
        case SET_ALL_USER_DATA:
            return Reduce_setAllUserData(state, action);
        case SET_ALL_GROUP_DATA:
            return Reduce_setAllGroupData(state, action);
        case SET_ALL_COMPANY_DATA:
            return Reduce_setAllCompanyData(state, action);
        case SET_LOADING_USER_DATA:
            return Reduce_setLoadingUserData(state, action);
        case SET_LOADING_GROUP_DATA:
            return Reduce_setLoadingGroupData(state, action);
        case SET_LOADING_COMPANY_DATA:
            return Reduce_setLoadingCompanyData(state, action);
        case SET_REPORT_LAYER_STYLES_DATA:
            return Reduce_setReportLayerStylesData(state, action);
        case SET_REPORT_LAYER_TYPES_DATA:
            return Reduce_setReportLayerTypesData(state, action);
        default:
            return state;
    }
};

const Reduce_setEventData = (
    state: ServiceState = initState,
    action: SetEventDataAction,
): ServiceState => {
    return {
        ...state,
        eventData: action.payload.eventData.sort(
            (a, b) => b.updated_at - a.updated_at,
        ),
    };
};

const Reduce_setGroupData = (
    state: ServiceState = initState,
    action: SetGroupDataAction,
): ServiceState => {
    return { ...state, groupData: action.payload };
};

const Reduce_setUserData = (
    state: ServiceState = initState,
    action: SetUserDataAction,
): ServiceState => {
    return { ...state, userData: action.payload };
};

const Reduce_setAllUserData = (
    state: ServiceState = initState,
    action: SetAllUserDataAction,
): ServiceState => {
    return { ...state, allUserData: action.payload };
};

const Reduce_setAllGroupData = (
    state: ServiceState = initState,
    action: SetAllGroupDataAction,
): ServiceState => {
    return { ...state, allGroupData: action.payload };
};

const Reduce_setAllCompanyData = (
    state: ServiceState = initState,
    action: SetAllCompanyDataAction,
): ServiceState => {
    return { ...state, allCompanyData: action.payload };
};

const Reduce_setLoadingUserData = (
    state: ServiceState = initState,
    action: SetLoadingUserDataAction,
): ServiceState => {
    return { ...state, loadingUserData: action.payload };
};

const Reduce_setLoadingGroupData = (
    state: ServiceState = initState,
    action: SetLoadingGroupDataAction,
): ServiceState => {
    return { ...state, loadingGroupData: action.payload };
};

const Reduce_setLoadingCompanyData = (
    state: ServiceState = initState,
    action: SetLoadingCompanyDataAction,
): ServiceState => {
    return { ...state, loadingCompanyData: action.payload };
};

const Reduce_setReportLayerStylesData = (
    state: ServiceState = initState,
    action: SetReportLayerStylesDataAction,
): ServiceState => {
    return { ...state, reportLayerStylesData: action.payload };
};

const Reduce_setReportLayerTypesData = (
    state: ServiceState = initState,
    action: SetReportLayerTypesDataAction,
): ServiceState => {
    return { ...state, reportLayerTypesData: action.payload };
};
