interface CookieSettings {
    [optionName: string]: boolean;
}

export const OLD_THEME_COOKIE = "theme";
export const NEW_THEME_COOKIE = "mc_theme";

export const IMAGE_SHARE_EMAIL_COOKIE = "mc_image_share_email";

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (key: string) => {
    let cookieArr = document.cookie.split(";");
    for (let i = 0; i < cookieArr.length; i++) {
        let cookiePair = cookieArr[i].split("=");
        if (key === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1]);
        }
    }
    return null;
};

export const deleteCookie = (key: string) => {
    setCookie(key, "", 0);
};

export const parseCookie = (cookie: string): CookieSettings => {
    const settingsArray = cookie.split(",");
    let settings: CookieSettings = {};
    for (const setting of settingsArray) {
        const [name, value] = setting.split(":");
        settings[name] = value === "true";
    }
    return settings;
};
