import React, { useEffect, useState } from "react";
import classes from "./AccountModal.module.css";
import { useOktaAuth } from "@okta/okta-react";
import { Modal, Tabs, TextInput } from "@mantine/core";
import { mdiAccountDetails, mdiChevronRight, mdiCog, mdiHomeAccount, mdiShieldLock } from "@mdi/js";
import Icon from "@mdi/react";
import { ExtendedAccessManagementUser } from "types/auth";
import InitialsIcon from "components/_Library/InitialsIcon/InitialsIcon";
import cx from "classnames"
import { useUserMeAccessQuery } from "crud/me_access";
import moment from "moment";
import { MOMENT_DATE_FORMAT } from "index";
import Toggle from "components/_Library/Inputs/Toggle/Toggle";
import ThemeToggle from "components/_Library/ThemeToggle/ThemeToggle";
import Button from "components/_Library/Button/Button";
import { isUnsuccessfulAPIResponse } from "utils/TypeGuards";
import { generateAPIKey, getAPIKey, revokeAPIKey } from "crud/securityCRUD";
import CopyAlertButton from "components/_Library/CopyAlertButton/CopyAlertButton";
import { getCookie, parseCookie, setCookie } from "utils/Cookies";
import { ADVERTISING_COOKIE_DESCRIPTION, ANALYTICS_COOKIE_DESCRIPTION, COOKIE_SETTINGS_COOKIE_NAME, CookieSettings, FUNCTIONALITY_COOKIE_DESCRIPTION, HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME, NECESSARY_COOKIE_DESCRIPTION } from "components/App/Permissions/CookieBanner/CookieBanner";
import { useSetState } from "@mantine/hooks";
import { useUpdateUserDetailsMutation } from "crud/hooks/users";

interface OwnProps {
    closeModal: () => void;
    user: ExtendedAccessManagementUser | null
}

type AccountModalProps = OwnProps;

const AccountModal: React.FC<AccountModalProps> = ({ closeModal, user }) => {

    const { oktaAuth } = useOktaAuth();
    const { data } = useUserMeAccessQuery();

    const closeModalWithAnalytics = () => {
        closeModal();
    };

    const accessExpiryDate = moment().add(data?.access_expiry_days, 'days').format(MOMENT_DATE_FORMAT)

    const [userDetails, setUserDetails] = useSetState({
        first_name: user?.first_name!,
        last_name: user?.last_name!,
        job_role: user?.job_role,
    })
    const [cookieSettings, setCookieSettings] = useSetState<CookieSettings>({
        Necessary: true,
        Analytics: false,
        Functionality: false,
        Advertising: false,
    })
    const [selectedPanel, setSelectedPanel] = useState<string>("overview")
    
    const [apiKey, setApiKey] = useState("")

    useEffect(() => {
        getUserAPIKey()

        let cookieSettingsStr = getCookie(COOKIE_SETTINGS_COOKIE_NAME);
        if (cookieSettingsStr) {
            let settings = parseCookie(cookieSettingsStr);
            updateCookieSettings(settings);
        }
        //(This is being used as componentDidMount, doesn't need dependencies.)
        // eslint-disable-next-line 
    }, [])

    const updateCookieSettings = (
        update: Partial<CookieSettings>,
    ) => {        
        setCookie(
            COOKIE_SETTINGS_COOKIE_NAME,
            `Analytics:${update.Analytics ?? cookieSettings.Analytics},Advertising:${update.Advertising ?? cookieSettings.Advertising},Functionality:${update.Functionality ?? cookieSettings.Functionality}`,
            182,
        );
        setCookie(
            HUBSPOT_COOKIE_SETTINGS_COOKIE_NAME,
            `Analytics:${update.Analytics ?? cookieSettings.Analytics},Advertising:${update.Advertising ?? cookieSettings.Advertising},Functionality:${update.Functionality ?? cookieSettings.Functionality}`,
            182,
        );
        setCookieSettings({
            Necessary: true,
            Functionality: update.Functionality ?? cookieSettings.Functionality,
            Advertising: update.Advertising ?? cookieSettings.Advertising,
            Analytics: update.Analytics ?? cookieSettings.Analytics
        })
        console.log(cookieSettings)
    };

    const createUpdateUserMutation = useUpdateUserDetailsMutation();

    const handleUserUpdate = async () => {
        const updatedUser =
            await createUpdateUserMutation.mutateAsync({
                ...userDetails,
                last_login_at: new Date().toISOString(),
            }); 
        return updatedUser;
    };
    
    const getUserAPIKey = async () => {
        const token = oktaAuth.getAccessToken();
        if (token) {
            let res = await getAPIKey(token);
            if (!isUnsuccessfulAPIResponse(res)) {
                setApiKey(res.data.api_key);
            }
        }
    };

    const requestAPIKey = async () => {
        let token = oktaAuth.getAccessToken();
        if (token) {
            let res = await generateAPIKey(token);
            if (!isUnsuccessfulAPIResponse(res)) {
                setApiKey(res.data.api_key);
            }
            console.log(res.data?.api_key)
        }
    };

    const removeApiKey = async () => {
        let token = oktaAuth.getAccessToken();
        if (token) {
            let res = await revokeAPIKey(token, apiKey);

            if (!isUnsuccessfulAPIResponse(res)) {
                setApiKey("");
            }
        }
    };

    const overviewTab = () => {
        return (
            <>
                <div className={classes.ContentContainer}>
                    <div className={classes.DetailsOverview}>
                        <div className={classes.InitialsIcon}>
                            <InitialsIcon 
                                firstName={user?.first_name} 
                                lastName={user?.last_name} 
                                width={7}
                            />
                        </div>
                        <div className={classes.UserDetailsContainer}>
                            <p className={cx(classes.UserDetails, classes.UserName)}>{user?.first_name} {user?.last_name}</p>
                            <p className={classes.UserDetails}>{user?.job_role}</p>
                            <p className={classes.UserDetails}>{user?.company_name}</p>
                        </div>
                        <div className={classes.MemberBadgeContainer}>
                            <p className={classes.MemberBadge}>Member since {moment.unix(user?.created_at).format(MOMENT_DATE_FORMAT)}</p>
                        </div>
                    </div>
                </div>
                <p>Quick Links:</p>
                <div className={classes.ContentContainer}>
                    <div className={classes.QuickLink} onClick={() => setSelectedPanel("details")}>
                        <p>Update Account Details</p>
                        <Icon path={mdiChevronRight} size={1.5}/>
                    </div>
                    {
                        data?.has_api_access &&
                            <div className={classes.QuickLink} onClick={() => setSelectedPanel("settings")}>
                                <p>Developer API Key</p>
                                <Icon path={mdiChevronRight} size={1.5}/>
                            </div>
                    }
                    
                </div>
                {data?.access_expiry_days && 
                    <div className={cx(classes.ContentContainer, classes.SubscriptionContainer)}>
                        <p className={classes.ExpiryTitle}>Subscription</p>
                        <p className={classes.LoContText}>Your GEO subscription expires on {accessExpiryDate}</p>
                    </div>
                }
            </>
        )
    }

    const detailsTab = () => {
        return (
            <>
                <div className={classes.ContentContainer}>
                    <div className={cx(classes.Input, classes.NameInputs)}>
                        <TextInput
                            label={"First Name"}
                            value={userDetails.first_name}
                            variant={"twoColumn"}
                            onChange={(event) => {
                                setUserDetails({
                                    first_name: event.currentTarget.value,
                                })
                            }}
                        />
                        <TextInput
                            label={"Last Name"}
                            value={userDetails.last_name}
                            variant={"twoColumn"}
                            onChange={(event) => {
                                setUserDetails({
                                    last_name: event.currentTarget.value,
                                })
                            }}
                        />
                    </div>
                    <div className={classes.Input}>
                        <TextInput
                            label={"Job Title"}
                            value={userDetails.job_role}
                            variant={"primaryBg"}
                            onChange={(event) => {
                                setUserDetails({
                                    job_role: event.currentTarget.value,
                                })
                            }}
                        />
                    </div>
                    <div className={classes.ButtonContainer}>
                        <Button onClick={handleUserUpdate} size={{width: "20rem"}}>Save Changes</Button>
                    </div>
                </div>
                <p className={classes.LoContText}><b>Note:</b> Changes may take up to 15 minutes to appear due to temporary processing delays.</p>
            </>
        )
    }

    const settingsTab = () => {
        return (
            <>
                {data?.has_api_access && 
                    <div className={classes.ContentContainer}>
                        <p className={classes.ContainerHeader}>Developer Settings</p>
                        <p className={classes.Subheader}>API Key</p>
                        <div className={classes.ApiKeyContainer}>
                            
                            <div className={classes.ApiKeyContainer}>
                                {apiKey.length ?
                                    <>
                                        <p className={classes.ApiKey}>{apiKey}</p>
                                        <CopyAlertButton 
                                            value={apiKey}
                                            alertMessage="API key copied to clipboard" 
                                            buttonClassName={classes.CopyButton}
                                        />
                                    </> : null
                                }
                            </div>                                   
                            {
                                apiKey.length ? 
                                    <Button size={{width: "20rem"}} onClick={removeApiKey}>Revoke Key</Button> : 
                                    <Button size={{width: "20rem"}} onClick={requestAPIKey}>Request Key</Button>
                            }
                        </div>
                    </div>
                }

                <div className={classes.ContentContainer}>
                    <p className={classes.ContainerHeader}>Display</p>
                    <ThemeToggle />
                </div>
            </>
        )
    }

    const privacyTab = () => {
        return (
            <>
                <div className={classes.PanelContainer}>
                    <div className={classes.ContentContainer}>
                        <p className={classes.ContainerHeader}>Cookies</p>
                        <div className={classes.SpecificCookie}>
                            <p className={classes.Subheader}>Essential Cookies</p>
                            <p className={classes.CookieDesc}>
                                {NECESSARY_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.Subheader}>Analytics Cookies</p>
                                <Toggle onClick={() => {
                                        updateCookieSettings({
                                            Analytics: !cookieSettings.Analytics,
                                        })
                                    }} active={cookieSettings.Analytics}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                {ANALYTICS_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.Subheader}>Functionality Cookies</p>
                                <Toggle onClick={()=>{
                                    updateCookieSettings({
                                        Functionality: !cookieSettings.Functionality,
                                    })
                                }} active={cookieSettings.Functionality}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                {FUNCTIONALITY_COOKIE_DESCRIPTION}
                            </p>
                        </div>
                        <div className={classes.SpecificCookie}>
                            <div className={classes.CookieHeader}>
                                <p className={classes.Subheader}>Advertising Cookies</p>
                                <Toggle onClick={()=>{
                                    updateCookieSettings({
                                        Advertising: !cookieSettings.Advertising,
                                    })
                                }} active={cookieSettings.Advertising}/>
                            </div>
                            <p className={classes.CookieDesc}>
                                {ADVERTISING_COOKIE_DESCRIPTION}
                            </p>
                        </div>

                    </div>
                </div>
            </>
        )
    }

    return (
        <div>
            <Modal
                opened={true}
                onClose={closeModalWithAnalytics}
                size={"90rem"}
                closeButtonProps={{
                    size: "2rem"
                }}
                classNames={{
                    header: classes.ModalHeader,
                    content: classes.Modal
                }}
            >
                <div onClick={(event) => {
                    event.stopPropagation();
                }}>
                    <Tabs
                        orientation={"vertical"}
                        value={selectedPanel}
                        className={classes.TabMenu}
                        variant={"userPreferences"}
                    >
                        <Tabs.List>
                            <div className={classes.SettingsTitle}>
                                <InitialsIcon width={4} firstName={user?.first_name} lastName={user?.last_name}/>
                                <p className={classes.Title}>Settings</p>
                            </div>
                            <Tabs.Tab value={"overview"} icon={<Icon path={mdiHomeAccount} size={1.6}/>} onClick={() => setSelectedPanel("overview")}>Overview</Tabs.Tab>
                            <Tabs.Tab value={"details"} icon={<Icon path={mdiAccountDetails} size={1.6}/>} onClick={() => setSelectedPanel("details")}>Details</Tabs.Tab>
                            <Tabs.Tab value={"settings"} icon={<Icon path={mdiCog} size={1.6}/>} onClick={() => setSelectedPanel("settings")}>Settings</Tabs.Tab>
                            <Tabs.Tab value={"privacy"} icon={<Icon path={mdiShieldLock} size={1.6}/>} onClick={() => setSelectedPanel("privacy")}>Privacy</Tabs.Tab>
                        </Tabs.List>

                        <Tabs.Panel value={"overview"} pl={"lg"}>
                            {overviewTab()}
                        </Tabs.Panel>

                        <Tabs.Panel value={"details"} pl={"lg"}>
                            {detailsTab()}
                        </Tabs.Panel>

                        <Tabs.Panel value={"settings"} pl={"lg"}>
                            {settingsTab()}
                        </Tabs.Panel>
                        
                        <Tabs.Panel value={"privacy"} pl={"lg"}>
                            {privacyTab()}
                        </Tabs.Panel>
                    </Tabs>

                </div>
                
            </Modal>
        </div>
    );
};

export default AccountModal;
