import React, { FC } from "react";
import { Redirect } from "react-router-dom";
import { withOktaAuth } from "@okta/okta-react";
import { IOktaContext } from "@okta/okta-react/bundles/types/OktaContext";

import LoadingScreen from "../../_Library/LoadingScreen/LoadingScreen";
import OktaSignInWidget from "./OktaSigninWidget/OktaSigninWidget";
import { UserActionTypes } from "../../../store/user/userTypes";
import { setUser, initiateUser } from "../../../store/user/userActions";
import { ThunkDispatch } from "redux-thunk";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import { GEOUserWithPortfolios, MisgisUserClaims } from "../../../types/auth";
import { useAnalytics } from "hooks/useAnalytics/useAnalytics";
import { userMeUpdate } from "crud/accessCRUD";
import { RootState } from "store/store";

interface DispatchProps {
    setUser: typeof setUser;
    initiateUser: typeof initiateUser;
}

type Extensions = IOktaContext & DispatchProps;
interface LoginPageProps extends Extensions {
    config: any;
}

const LoginPage: FC<LoginPageProps> = (props: LoginPageProps) => {
    const dispatch: ThunkDispatch<RootState, undefined, UserActionTypes> =
        useDispatch();

    let { identify, trackUserEvent } = useAnalytics();

    const onSuccess = async (tokens: any) => {
        props.oktaAuth.handleLoginRedirect(tokens);
        props.oktaAuth.getUser().then(async (user) => {
            const accessToken = tokens.accessToken.accessToken;
            await dispatch(
                initiateUser(user as MisgisUserClaims, accessToken, identify),
            ).then(async (user: GEOUserWithPortfolios | null) => {
                if (!user) {
                    return null;
                }
                trackUserEvent({
                    name: "sign_in_successful",
                });
                await userMeUpdate(accessToken, {
                    first_name: user.first_name,
                    last_name: user.last_name,
                    last_login_at: new Date().toISOString(),
                });
            });
        });
    };

    const onError = (err: any) => {
        console.log("error logging in", err);

        trackUserEvent({
            name: "sign_in_unsuccessful",
        });
    };

    if (!props.authState) {
        return <LoadingScreen />;
    }
    return props.authState.isAuthenticated ? (
        <Redirect to={{ pathname: "/" }} />
    ) : (
        <OktaSignInWidget
            config={props.config}
            onSuccess={onSuccess}
            onError={onError}
        />
    );
};

const mapDispatchTopProps = (
    dispatch: ThunkDispatch<any, any, UserActionTypes>,
) => ({
    setUser: bindActionCreators(setUser, dispatch),
    initiateUser: bindActionCreators(initiateUser, dispatch),
});

export default withOktaAuth(connect(null, mapDispatchTopProps)(LoginPage));
