import {
    DELETE_ALERT,
    DeleteAlertAction,
    SET_ALERT,
    SET_COOKIES_SET,
    SET_PREVIEW_CONFIG,
    SetAlertAction,
    SetCookiesSetAction,
    SetPreviewConfigAction,
    SystemActionTypes,
    SystemState,
    TOGGLE_MODAL,
    ToggleModalAction,
} from "./systemTypes";
import { Reducer } from "redux";
import { generateHash } from "../../utils/Hash";

const initState: SystemState = {
    alerts: {},
    cookiesSet: false,
    previewConfig: null,
    appLoading: {
        loading: false,
        message: "",
    },
    modalOpen: null,
};

const ALERT_COUNT_LIMIT = 2;

export const systemReducer: Reducer<SystemState, SystemActionTypes> = (
    state = initState,
    action,
): SystemState => {
    switch (action.type) {
        case SET_ALERT:
            return Reduce_setAlert(state, action);
        case DELETE_ALERT:
            return Reduce_deleteAlert(state, action);
        case SET_COOKIES_SET:
            return Reduce_setCookiesSet(state, action);
        case SET_PREVIEW_CONFIG:
            return Reduce_SetPreviewConfig(state, action);
        case TOGGLE_MODAL:
            return Reduce_ToggleModal(state, action);
        default:
            return state;
    }
};

const Reduce_setAlert = (
    state: SystemState,
    action: SetAlertAction,
): SystemState => {
    let alerts = { ...state.alerts };
    let { type = "Default", timeout = 2000, message } = action.payload;
    let uuid = generateHash();

    alerts[uuid] = { message, timeout, type };
    while (Object.keys(alerts).length > ALERT_COUNT_LIMIT) {
        delete alerts[Object.keys(alerts)[0]];
    }

    return { ...state, alerts };
};

const Reduce_deleteAlert = (
    state: SystemState,
    action: DeleteAlertAction,
): SystemState => {
    let alerts = { ...state.alerts };

    delete alerts[action.payload.uuid];

    return { ...state, alerts };
};

const Reduce_setCookiesSet = (
    state: SystemState,
    action: SetCookiesSetAction,
): SystemState => {
    return { ...state, cookiesSet: action.payload };
};

const Reduce_SetPreviewConfig = (
    state = initState,
    action: SetPreviewConfigAction,
): SystemState => {
    return { ...state, previewConfig: action.payload };
};

const Reduce_ToggleModal = (
    state = initState,
    action: ToggleModalAction,
): SystemState => {
    return {
        ...state,
        modalOpen: action.payload,
    };
};
