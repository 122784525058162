import {
    UserState,
    UserActionTypes,
    SetUserAction,
    SET_USER,
    SET_THEME,
} from "./userTypes";
import { Reducer } from "redux";
import { NEW_THEME_COOKIE, getCookie } from "../../utils/Cookies";
import { ThemeOption } from "store/system/systemTypes";

const initState: UserState = {
    theme: getCookie(NEW_THEME_COOKIE) as ThemeOption | null,
    user: null,
};

export const userReducer: Reducer<UserState, UserActionTypes> = (
    state = initState,
    action,
): UserState => {
    switch (action.type) {
        case SET_THEME:
            return Reducer_setTheme(state, action);
        case SET_USER:
            return Reduce_setUser(state, action);
        default:
            return state;
    }
};

const Reducer_setTheme = (
    state: UserState,
    action: { payload: ThemeOption },
): UserState => {
    return { ...state, theme: action.payload };
};

const Reduce_setUser = (
    state = initState,
    action: SetUserAction,
): UserState => {
    let htmlElem = document.getElementsByTagName("html")[0];

    htmlElem.dataset["theme"] = getCookie(NEW_THEME_COOKIE) ?? "light";
    htmlElem.setAttribute(
        "style",
        "font-size: 10px",
    );

    return { ...state, user: action.payload };
};
