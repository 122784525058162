import React from "react";
import BasePage from "../BasePage/BasePage";
import classes from "./ErrorPage.module.css";
import ErrorLogo from "/src/assets/images/error_graphic.png";
import ErrorPane from "components/_Library/Errors/ErrorPanel/ErrorPane";
import NavBar from "components/Partials/NavBar/NavBar";

const ErrorPage: React.FC = () => {
    return (
        <BasePage>
            <NavBar />
            <div className={classes.PageContainer}>
                <ErrorPane
                    title={"Oops!"}
                    textLineOne="It seems something went wrong."
                    textLineTwo="If the problem persists, please "
                    image={ErrorLogo}
                    largeImage
                />
            </div>
        </BasePage>
    );
};

export default ErrorPage;
