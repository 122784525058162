/**
 * This Component is used to display a dashboard message like an alert.
 * Multiple calls to SetAlert should stack the alerts with their own timeout.
 *
 */

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";

import { AlertConfig, SystemActionTypes } from "store/system/systemTypes";
import { deleteAlert } from "store/system/systemActions";

import classes from "./Alert.module.css";
import cx from "classnames";

interface OwnProps {
    alert: AlertConfig;
    uuid: string;
}

interface StateProps {}

interface DispatchProps {
    deleteAlert: typeof deleteAlert;
}

type AlertProps = OwnProps & StateProps & DispatchProps;

interface AlertState {}

class Alert extends Component<AlertProps, AlertState> {
    componentDidMount(): void {
        window.setTimeout(() => {
            this.props.deleteAlert({ uuid: this.props.uuid }); // Setting the message to null removes the alert.
        }, this.props.alert.timeout);
    }

    render() {
        let AlertType = this.props.alert.type
            ? classes[this.props.alert.type]
            : null;
        return (
            <div className={cx(classes.Container, AlertType)}>
                <pre>{this.props.alert.message}</pre>
                <div
                    className={classes.CloseButton}
                    onClick={() =>
                        this.props.deleteAlert({ uuid: this.props.uuid })
                    }
                >
                    <Icon path={mdiClose} size={"1.5rem"} />
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (
    dispatch: ThunkDispatch<any, any, SystemActionTypes>,
) => ({
    deleteAlert: bindActionCreators(deleteAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(Alert);
