export const insightsClusterColor = {
    exposure: "#414690",
    claims: "#82448c",
    other: "#f0f0f0",
};

export const insightsClusterStroke = {
    exposure: "#000000",
    claims: "#000000",
    other: "#000000",
};
